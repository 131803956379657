import { Button, Tooltip, Typography } from 'antd';
import { useContext } from 'react';
import { useModal } from 'hooks/ui/useModal';
import { SubscriptionContext } from './SubscriptionProvider';
import { usePauseResume } from './usePauseResume';

const { Paragraph, Text } = Typography;

export default function PauseResumeButton() {
  const { subscription, expired, canPause } = useContext(SubscriptionContext);
  const { pause, resume, loading } = usePauseResume();
  const modal = useModal();

  const remainingPauseCount = subscription
    ? subscription.pause_limit - subscription.pause_count
    : 0;

  const handleResumeClick = () => {
    modal.confirm({
      title: 'Resume Subscription',
      content: (
        <>
          <Paragraph>Are you sure you want to resume the current subscription?</Paragraph>
          {subscription?.pause_count !== subscription?.pause_limit ? (
            <Paragraph>
              It can be paused <Text strong>{subscription?.pause_limit}</Text> more times.
            </Paragraph>
          ) : (
            <Paragraph>
              You have reached the pause limit and will not be able to pause the subscription again
              once resumed!
            </Paragraph>
          )}
        </>
      ),
      okText: 'Resume',
      okButtonProps: { loading },
      onOk: async () => {
        await resume();
      },
    });
  };

  const handlePauseClick = () => {
    modal.confirm({
      title: 'Pause Subscription',
      content: (
        <>
          <Paragraph>Are you sure you want to pause the current subscription?</Paragraph>
          {subscription?.pause_count === 0 ? (
            <Paragraph>
              It can be paused a total of <Text strong>{subscription?.pause_limit}</Text> times.
            </Paragraph>
          ) : (
            <Paragraph>
              It can only be paused <Text strong>{remainingPauseCount}</Text> more time!
            </Paragraph>
          )}
        </>
      ),
      okText: 'Pause',
      okButtonProps: { loading },
      onOk: async () => {
        await pause();
      },
    });
  };

  if (expired) return null;

  return subscription?.status === 'paused' ? (
    <Button onClick={handleResumeClick} disabled={loading}>
      Resume
    </Button>
  ) : (
    <Tooltip title={canPause ? 'Pause current subscription for 90 days' : 'Pause limit reached'}>
      <Button onClick={handlePauseClick} disabled={!canPause || loading}>
        Pause
      </Button>
    </Tooltip>
  );
}
