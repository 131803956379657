import { SmtkElement } from 'services';
import { SmtkDataNode } from '../SmtkDataNode';

export function toTreeNode(element: Element, baseKey: number, parent: SmtkElement | null) {
  // base
  const smtkElement: SmtkElement = element as SmtkElement;
  smtkElement.nodeId = baseKey.toString();
  element.replaceWith(smtkElement);

  const treeNode = new SmtkDataNode(smtkElement, parent, baseKey);

  for (const childElement of smtkElement.children) {
    // ignore connections from
    if (!['connection'].includes(childElement.localName)) {
      const newNode = toTreeNode(
        childElement,
        Number(`${baseKey}${treeNode.nextChildKey}`),
        smtkElement,
      );
      treeNode.addChild(newNode);
    }
  }
  return treeNode;
}
