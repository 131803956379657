import { Card } from 'antd';
import React, { ReactNode } from 'react';

function CardWrapper(props: {
  children: ReactNode;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  hoverable?: boolean | undefined;
  selected?: boolean | undefined;
}) {
  const { children, selected, hoverable, onClick } = props;
  return (
    <Card
      className="flex h-full w-full cursor-pointer flex-col data-[selected=true]:outline data-[selected=true]:outline-4 data-[selected=true]:outline-brand-500"
      size="small"
      hoverable={hoverable}
      onClick={onClick}
      data-selected={selected}
    >
      {children}
    </Card>
  );
}

CardWrapper.defaultProps = {
  onClick: undefined,
  hoverable: undefined,
  selected: undefined,
};

export default CardWrapper;
