import { ElementSpec } from '@codemirror/lang-xml';
import { nameAttribute, publicAttribute } from '../../attributes';
import { axisElement, modeElement, valueElement } from '../shared';

const holdElement: ElementSpec = {
  name: 'hold',
  attributes: [publicAttribute],
  textContent: ['true', 'false'],
  children: [],
};

const feedbackElement: ElementSpec = {
  name: 'feedback',
  attributes: [publicAttribute],
  children: [],
};

export const jointMotorElement: ElementSpec = {
  name: 'joint_motor',
  attributes: [nameAttribute],
  children: [
    modeElement.name,
    axisElement.name,
    holdElement.name,
    valueElement.name,
    feedbackElement.name,
  ],
};

export default [jointMotorElement, holdElement, feedbackElement];
