import { Skeleton, Typography } from 'antd';
import './PlanFeature.css';

const { Text } = Typography;

function PlanFeature(props: {
  label: string;
  value: string | number | undefined;
  loading?: boolean;
}) {
  const { label, value, loading } = props;
  return (
    <article className="subscription-plan-feature">
      <Skeleton loading={loading} active paragraph={false}>
        <Text>{label}:</Text>
        <Text strong>{value}</Text>
      </Skeleton>
    </article>
  );
}

PlanFeature.defaultProps = {
  loading: false,
};

export default PlanFeature;
