import { MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps } from 'antd';

export default function CardMenu(props: {
  menu: MenuProps;
  disabled?: boolean;
  stopPropagation?: boolean;
}) {
  const { menu, disabled, stopPropagation } = props;

  return (
    <Dropdown menu={menu} trigger={['click']} disabled={disabled}>
      <Button
        icon={<MoreOutlined />}
        type="text"
        shape="circle"
        size="small"
        onClick={(e) => {
          if (stopPropagation) e.stopPropagation();
        }}
      />
    </Dropdown>
  );
}

CardMenu.defaultProps = {
  disabled: false,
  stopPropagation: false,
};
