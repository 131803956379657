import { IAuthorizeable, IMe, IServer, Plan } from 'types/api-interfaces';

/**
 * return true if the user is can publish an object (owner)
 * @param user current user
 * @param object target system or component
 * @returns boolean
 * @deprecated
 */
export const canPublish = (user: IMe, object: IAuthorizeable): boolean => {
  return Boolean(
    (user.id === object.owner_id && user.plan !== Plan.Basic && !user.organization) || // user is the owner of an object without a basic plan // user is admin og the organization that owns an object
      (user.organization && user.organization.id === object.owner_id && user.organization.is_admin),
  );
};

/**
 * return true if the user can create or modify an object
 * @alias canCreate
 * @param user current user
 * @param object target system or component
 * @returns boolean
 * @description canModify => can create, edit
 * @deprecated
 */
export const canModify = (user: IMe, object: IAuthorizeable): boolean => {
  // User is not in an organization - must be owner and have paid plan
  // User is in an organization - must be creator and have create permissions OR must be admin
  return Boolean(
    (user.id === object.owner_id && user.plan !== Plan.Basic && !user.organization) || // owner/organization without a basic plan // OR
      (user.organization && // user is an member of an organization
        user.organization.id === object.owner_id && // that own the object
        ((user.id === object.creator_id && // that was created by this user
          user.organization.can_create) || //  and the user is currently allowed to create/modify // OR
          object.share_mode === 'editor' ||
          user.organization.is_admin)), // user is organization admin
  );
};

/**
 * return true if the user can delete an object
 * @param user current user
 * @param object target system or component
 * @returns boolean
 * @description canModify => can create, edit, and delete
 * @deprecated
 */
export const canDelete = (user: IMe, object: IAuthorizeable): boolean => {
  return Boolean(
    (user.id === object.owner_id && user.plan !== Plan.Basic && !user.organization) || // owner/organization without a basic plan // OR
      (user.organization && // user is an member of an organization
        user.organization.id === object.owner_id && // that own the object
        ((user.id === object.creator_id && // that was created by this user
          user.organization.can_delete) || // and the user is currently allowed to delete // OR
          user.organization.is_admin)), // user is organization admin
  );
};

export const canShare = (user: IMe, server: IServer) => {
  return Boolean(
    user.organization && // user is on organization profile
      user.organization.id === server.owner_id &&
      user.organization.can_manage, // that own the server
    // ||
    // (
    //   !user.organization
    //   && user.id === server.creator_id                  // created by this user
    // )
  );
};

export const canClone = (user: IMe): boolean => {
  return Boolean(
    (user.organization && // user is an member of an organization
      user.organization.can_create) || //  and the user is currently allowed to create/modify                                                    // OR
      (user.plan !== Plan.Basic && !user.organization), // non basic user who is not a member of any organization
  );
};

export const canCreate = (user: IMe): boolean => {
  return Boolean(
    (user.organization && // user is an member of an organization
      user.organization.can_create) || //  and the user is currently allowed to create/modify                                                    // OR
      (user.plan !== Plan.Basic && !user.organization), // non basic user who is not a member of any organization
  );
};

/**
 * return true if the user can share the object with others
 * @param user current user
 * @param object target system or component
 * @returns boolean
 * @deprecated
 */
export const canShareObject = (user: IMe, object: IAuthorizeable) => {
  return Boolean(
    user.organization && // user is on organization profile
      user.organization.id === object.owner_id && // Object is belongs to the organization
      (user.id === object.creator_id || // user is the creator of the object
        user.organization.is_admin),
  );
};
