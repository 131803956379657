import { App, ConfigProvider, ThemeConfig } from 'antd';
import React from 'react';
import { sanitizeColorString } from 'utils/sanitizeColorString';

type AntDesignProviderProps = {
  children: React.ReactNode;
};

const colorPrimary = sanitizeColorString(import.meta.env.VITE_PRIMARY_COLOR) ?? '#74b800';

const theme: ThemeConfig = {
  token: {
    colorPrimary,
    colorLink: colorPrimary,
    borderRadius: 2,
    colorHighlight: colorPrimary,
    fontFamily:
      "-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'",
  },
};

function AntDesignProvider({ children }: AntDesignProviderProps) {
  return (
    <ConfigProvider theme={theme}>
      <App>{children}</App>
    </ConfigProvider>
  );
}

export default AntDesignProvider;
