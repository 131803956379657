import { Badge } from 'antd';
import { ServerStatus } from 'types/api-interfaces';
import './StatusBadge.css';

type BadgeStatus = 'success' | 'default' | 'error' | 'warning' | 'processing';

const statusMap = new Map<ServerStatus, BadgeStatus>([
  [ServerStatus.Running, 'success'],
  [ServerStatus.Stopped, 'default'],
  [ServerStatus.Stopping, 'error'],
  [ServerStatus.Starting, 'warning'],
  [ServerStatus.Started, 'warning'],
]);

function StatusBadge(props: { status: ServerStatus | undefined }) {
  const { status } = props;

  const getMapValue = (key: ServerStatus) => {
    return statusMap.get(key) || 'default';
  };

  return (
    <div className="status-badge">
      <Badge status={status ? getMapValue(status) : 'default'} dot>
        <div style={{ fontSize: 14 }} />
      </Badge>
    </div>
  );
}

export default StatusBadge;
