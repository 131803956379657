import usePermissions from 'hooks/usePermissions';
import React, { ReactNode, useMemo, useState } from 'react';
import { IComponent } from 'types/api-interfaces';
import { dummyComponent } from 'utils/dummyObjects';

export interface IComponentEditorContext {
  component: IComponent;
  /** Should be true when all meshes have been fully loaded in the scene instance */
  isSceneLoaded: boolean;
  setIsSceneLoaded: (value: boolean) => void;
  /** Keeps track of which tree node is selected, so it is remembered when you navigate away from the Editor tab */
  selectedKeys: React.Key[];
  setSelectedKeys: (value: React.Key[]) => void;
  /** The asset ID of the script file currently used as the component's behavior script */
  behaviorGuid: string | undefined;
  setBehaviorGuid: (guid: string) => void;
  canModify: boolean;

  /** Scene Settings */
  isCollisionVisible: boolean;
  setIsCollisionVisible: (value: boolean) => void; 
  isSensorVisible: boolean;
  setIsSensorVisible: (value: boolean) => void;
  isLightVisible: boolean;
  setIsLightVisible: (value: boolean) => void;
}

export const ComponentEditorContext = React.createContext<IComponentEditorContext>({
  component: dummyComponent,
  isSceneLoaded: false,
  setIsSceneLoaded: () => {},
  selectedKeys: [1],
  setSelectedKeys: () => {},
  behaviorGuid: '',
  setBehaviorGuid: () => {},
  canModify: false,
  
  isCollisionVisible: false,
  setIsCollisionVisible: () => {},
  isSensorVisible: false,
  setIsSensorVisible: () => {},
  isLightVisible: false,
  setIsLightVisible: () => {},
});

type ComponentEditorProviderProps = {
  children: ReactNode;
  component: IComponent;
};

function ComponentEditorProvider({ children, component }: ComponentEditorProviderProps) {
  const [isSceneLoaded, setIsSceneLoaded] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([1]);
  const [behaviorGuid, setBehaviorGuid] = useState<string>();

  const { canModify } = usePermissions(component);

  // Settings
  const [isCollisionVisible, setIsCollisionVisible] = useState(false);
  const [isSensorVisible, setIsSensorVisible] = useState(false);
  const [isLightVisible, setIsLightVisible] = useState(false);

  const providerValue = useMemo(
    () => ({
      component,
      isSceneLoaded,
      setIsSceneLoaded,
      selectedKeys,
      setSelectedKeys,
      behaviorGuid,
      setBehaviorGuid,
      canModify,

      isCollisionVisible, setIsCollisionVisible,
      isSensorVisible, setIsSensorVisible,
      isLightVisible, setIsLightVisible,
    }),
    [isSceneLoaded, selectedKeys, component, behaviorGuid, canModify, isCollisionVisible, isSensorVisible, isLightVisible],
  );
  return (
    <ComponentEditorContext.Provider value={providerValue}>
      {children}
    </ComponentEditorContext.Provider>
  );
}

export default ComponentEditorProvider;
