import { Form, InputNumber } from 'antd';
import useUpdateEffect from 'hooks/utils/useUpdateEffect';
import React, { useContext, useMemo } from 'react';
import { NodeValueInputContext } from '../NodeValueInputProvider';
import useNodeValueInput from '../hooks/useNodeValueInput';
import { InputBaseProps } from '../types';

interface Props extends InputBaseProps {
  value?: number[];
  onChange?: (value: number[]) => void;
  labels?: string[];
}

export const InputVector2: React.FC<Props> = ({ value, onChange, labels, disabled }) => {
  const { object, node } = useContext(NodeValueInputContext);
  const { handleChange } = useNodeValueInput(object?.value);

  const [form] = Form.useForm();

  const initialValues = useMemo(() => {
    // Value prop exists to make the component compatible with AntD forms
    if (value)
      return {
        x: value[0],
        y: value[1],
      };

    return {
      x: object?.value[0],
      y: object?.value[1],
    };
  }, [object, value]);

  const submit = () => {
    const values = form.getFieldsValue();
    const valuesArray = Object.values(values) as number[];
    if (onChange) onChange(valuesArray);
    else handleChange(valuesArray);
  };

  useUpdateEffect(() => {
    form.resetFields();
  }, [node]);

  return (
    <Form form={form} initialValues={initialValues} layout="vertical">
      <Form.Item name="x" noStyle>
        <InputNumber
          disabled={disabled}
          step={0.01}
          addonBefore={labels ? labels[0] : 'X'}
          onPressEnter={submit}
          onBlur={submit}
          onStep={submit}
        />
      </Form.Item>
      <Form.Item name="y" noStyle>
        <InputNumber
          disabled={disabled}
          step={0.01}
          addonBefore={labels ? labels[1] : 'Y'}
          onPressEnter={submit}
          onBlur={submit}
          onStep={submit}
        />
      </Form.Item>
    </Form>
  );
};

InputVector2.defaultProps = {
  value: undefined,
  onChange: undefined,
  labels: undefined,
};

export default InputVector2;
