import { apiClient } from 'lib/axios';
import { IComponent, ILibrary } from 'types/api-interfaces';

export async function fetchLibraries(
  scope: string,
  searchTerm?: string | undefined | null,
): Promise<ILibrary[]> {
  const params = new URLSearchParams();
  if (searchTerm && searchTerm.length > 0) params.append('name', searchTerm);

  const url = scope === 'private' ? `/users/me/libraries` : `/libraries`;
  const { data } = await apiClient.get(url, { params });
  return data;
}

export async function fetchLibraryObjects(
  libraryID: string,
  searchTerm?: string,
): Promise<IComponent[]> {
  const params = new URLSearchParams();
  if (searchTerm && searchTerm.length > 0) params.append('name', searchTerm);

  const url = `/libraries/${libraryID}/objects`;
  const { data } = await apiClient.get(url, { params });
  return data;
}

export async function deleteLibraryComponent(libraryId: string, componentId: string) {
  const { data } = await apiClient.delete(`/libraries/${libraryId}/objects/${componentId}`);
  return data;
}

export async function createLibrary(name: string) {
  const { data } = await apiClient.post<ILibrary>('/libraries', { name });
  return data;
}

export async function deleteLibrary(id: string) {
  const { data } = await apiClient.delete(`/libraries/${id}`);
  return data;
}

export async function patchLibrary(id: string, payload: Partial<ILibrary>) {
  const { data } = await apiClient.patch<ILibrary>(`/libraries/${id}`, payload);
  return data;
}

export async function addToLibrary(id: string, component: IComponent) {
  const { data } = await apiClient.put<ILibrary>(`/libraries/${id}/objects/${component.id}`);
  return data;
}
