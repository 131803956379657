import { ElementSpec } from '@codemirror/lang-xml';
import { publicAttribute } from '../../attributes';

export const sizeElement: ElementSpec = {
  name: 'size',
  attributes: [publicAttribute],
  children: [],
};

export const originElement: ElementSpec = {
  name: 'origin',
  attributes: [publicAttribute],
  children: [],
};

export const scaleElement: ElementSpec = {
  name: 'scale',
  attributes: [publicAttribute],
  children: [],
};

export const radiusElement: ElementSpec = {
  name: 'radius',
  attributes: [publicAttribute],
  children: [],
};

export const lengthElement: ElementSpec = {
  name: 'length',
  attributes: [publicAttribute],
  children: [],
};

export const heightElement: ElementSpec = {
  name: 'height',
  children: [],
};

export const widthElement: ElementSpec = {
  name: 'width',
  attributes: [publicAttribute],
  children: [],
};

export const enableElement: ElementSpec = {
  name: 'enable',
  attributes: [publicAttribute],
  textContent: ['true', 'false'],
  children: [],
};

export const positionElement: ElementSpec = {
  name: 'position',
  attributes: [publicAttribute],
  children: [],
};

export const velocityElement: ElementSpec = {
  name: 'velocity',
  attributes: [publicAttribute],
  children: [],
};

export const valueElement: ElementSpec = {
  name: 'value',
  attributes: [publicAttribute],
  children: [],
};

export const shapeElement: ElementSpec = {
  name: 'shape',
  textContent: ['matrix', 'cone', 'pyramid', 'circular'],
  children: [],
};

export default [
  sizeElement,
  originElement,
  scaleElement,
  radiusElement,
  lengthElement,
  heightElement,
  widthElement,
  enableElement,
  positionElement,
  velocityElement,
  valueElement,
  shapeElement,
];
