import { ElementSpec } from '@codemirror/lang-xml';
import { nameAttribute, publicAttribute } from '../../attributes';
import baseChildren from './base';

const txElement: ElementSpec = {
  name: 'tx',
  attributes: [publicAttribute],
};

const rxElement: ElementSpec = {
  name: 'rx',
  attributes: [publicAttribute],
};

const children = [...baseChildren, txElement.name, rxElement.name];

export const serialInputElement: ElementSpec = {
  name: 'serial_input',
  attributes: [nameAttribute],
  children,
};

export const serialOutputElement: ElementSpec = {
  name: 'serial_output',
  attributes: [nameAttribute],
  children,
};

export default [serialInputElement, serialOutputElement, txElement, rxElement];
