import { create } from 'zustand';

type State = {
  showRenameModal: boolean;
  setShowRenameModal: (show: boolean) => void;
};

const useRenameModal = create<State>((set) => ({
  showRenameModal: false,
  setShowRenameModal: (show: boolean) => set({ showRenameModal: show }),
}));

export default useRenameModal;
