import { ElementSpec } from '@codemirror/lang-xml';
import { nameAttribute, publicAttribute } from '../../attributes';
import { originElement } from '../shared';
import { geometryElement } from './visual/geometry';

const marginElement: ElementSpec = {
  name: 'margin',
  attributes: [publicAttribute],
  children: [],
};

export const collisionElement: ElementSpec = {
  name: 'collision',
  attributes: [nameAttribute],
  children: [geometryElement.name, originElement.name, marginElement.name],
};

export default [collisionElement, marginElement];
