import { PlusOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Tooltip } from 'antd';
import { ComponentLibraryBrowserContext } from 'providers/ComponentLibraryBrowserProvider';
import React, { useContext } from 'react';
import { addToLibrary } from 'services/libraries';
import { IComponent } from 'types/api-interfaces';
import sortAlphabetically from 'utils/sortAlphabetically';

interface IProps {
  component: IComponent;
}

function AddToLibrary({ component }: IProps) {
  const { selectedLibrary } = useContext(ComponentLibraryBrowserContext);
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (options: { id: string; component: IComponent }) =>
      addToLibrary(options.id, options.component),
    onSuccess: () => {
      if (!selectedLibrary) {
        return;
      }
      queryClient.setQueryData<IComponent[]>(['library', selectedLibrary.id], (prevData) => {
        if (!prevData) {
          return [component];
        }

        if (prevData.find(({ id }) => id === component.id)) {
          return;
        }

        return [...prevData, component].sort((a, b) => sortAlphabetically(a.name, b.name));
      });
    },
  });

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (!selectedLibrary) {
      return;
    }
    mutation.mutate({ id: selectedLibrary.id, component });
  };

  return (
    <Tooltip title="Add to library">
      <Button onClick={handleClick} icon={<PlusOutlined />} shape="circle" />
    </Tooltip>
  );
}

export default AddToLibrary;
