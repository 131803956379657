import { IGroupMember, IOrganizationMember } from 'types/api-interfaces';
import { apiClient } from 'lib/axios';
import { formatStringForAPICall } from 'simumatik-commons';

export interface IGroup {
  id: string;
  name: string;
}

export async function fetchGroupMembers(groupID: string) {
  const url = `/group/${groupID}`;
  const { data } = await apiClient.get<IGroupMember[]>(url);
  return data;
}

export async function fetchGroups() {
  const { data } = await apiClient.get<IGroup[]>('/groups');
  return data;
}

export async function moveUserToGroup(userID: string, groupID: string) {
  // Wtf is this API call
  const url = `/group/${groupID}/user/${userID}`;
  const { data } = await apiClient.patch<IGroupMember>(url);
  return data;
}

export async function createGroup(name: string) {
  const url = `/group/name/${formatStringForAPICall(name)}`;
  const { data } = await apiClient.post<IGroup>(url);
  return data;
}

export async function renameGroup(groupID: string, name: string) {
  const url = `/group/${groupID}/name/${formatStringForAPICall(name)}`;
  const { data } = await apiClient.patch<IGroup>(url);
  return data;
}

export async function deleteGroup(groupID: string) {
  const url = `/group/${groupID}`;
  await apiClient.delete(url);
}

export async function addMember(email: string, groupID: number, organizationID: string) {
  const url = `/organizations/${organizationID}/members`;
  const { data } = await apiClient.post<IOrganizationMember>(url, {
    user_email: email,
    group: groupID,
  });
  return data;
}

export async function removeOrganizationMember(userID: string, organizationID: string) {
  const url = `/organizations/${organizationID}/members/${userID}`;
  const { data } = await apiClient.delete(url);
  return data;
}

export async function patchMember(
  memberID: string,
  organizationID: string,
  payload: Partial<IOrganizationMember>,
) {
  const url = `/organizations/${organizationID}/members/${memberID}`;
  const { data } = await apiClient.patch<IOrganizationMember>(url, payload);
  return data;
}
