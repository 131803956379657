import Plausible from 'plausible-tracker';
import React, { useEffect } from 'react';
import { Analytics } from 'simumatik-commons';

const plausible = Plausible({
  apiHost: import.meta.env.VITE_PLAUSIBLE_API_HOST,
});

Analytics.plausible = plausible;

export const plausibleContext = React.createContext(plausible);

type PlausibleProviderProps = {
  children: React.ReactNode;
};

export function PlausibleProvider({ children }: PlausibleProviderProps) {
  useEffect(() => {
    const cleanup = plausible.enableAutoPageviews();
    return () => cleanup();
  });

  return <plausibleContext.Provider value={plausible}>{children}</plausibleContext.Provider>;
}
