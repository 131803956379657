import { ElementSpec } from '@codemirror/lang-xml';
import { publicAttribute } from '../../attributes';

export const updateRateElement: ElementSpec = {
  name: 'update_rate',
  attributes: [publicAttribute],
  children: [],
};

export default [updateRateElement];
