import { Form, FormInstance, Input } from 'antd';

export default function InviteUsers(props: { form: FormInstance; onFinish: () => void }) {
  const { form, onFinish } = props;

  const handleFinish = () => {
    onFinish();
  };

  return (
    <Form form={form} layout="vertical" colon requiredMark={false} onFinish={handleFinish}>
      <Form.Item label="Add a personal message" name="message">
        <Input.TextArea />
      </Form.Item>
      <Form.Item label="Email addresses to invite" name="emails" required>
        <Input.TextArea />
      </Form.Item>
    </Form>
  );
}
