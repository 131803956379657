import { apiClient } from 'lib/axios';

enum TransactionType {
  Use = 'use',
  Trial = 'trial',
  Renewal = 'renewal',
  Return = 'return',
  Other = 'other',
}

export enum UsageHistoryPeriod {
  LastSevenDays = 'last_seven_days',
  LastThirtyDays = 'last_thirty_days',
  LastYear = 'last_year',
}

interface ITransaction {
  user: {
    name: string;
    id: string;
  };
  timestamp: string;
  amount: number;
  comment: string;
  type: TransactionType;
}

interface ITransactionPaginationLink {
  href: string;
  rel: string;
}

interface ITransactions {
  currentPage: number;
  items: ITransaction[];
  lastPage: number;
  links: ITransactionPaginationLink[];
  pageSize: number;
  total: number;
}

interface ITransactionFilters {
  user?: string;
  timestamp?: string;
  size?: number;
}

interface IOrganizationCreditsData {
  balance: number;
  credit_usage_last_30_days: number;
  total_credit_usage: number;
}

export async function fetchOrganizationCredits(
  organizationID: string,
): Promise<IOrganizationCreditsData> {
  const url = `/organizations/${organizationID}/credits`;
  const { data } = await apiClient.get(url);
  return data;
}

export async function fetchOrganizationTransactions(
  page: number,
  organizationID: string,
  filters?: ITransactionFilters,
): Promise<ITransactions> {
  const SIZE = filters?.size ?? 10;
  const url = `/organizations/${organizationID}/credits/transactions?page=${page}&size=${SIZE}${
    filters?.user ? `&user_id=${filters.user}` : ''
  }&sort=timestamp:desc`;
  const { data } = await apiClient.get(url);
  return data;
}

interface ICreditsUsageData {
  date: string;
  amount: number;
}

export async function fetchOrganizationCreditsUsage(
  period: UsageHistoryPeriod,
  organizationID: string,
): Promise<ICreditsUsageData[]> {
  const url = `/organizations/${organizationID}/credits/usage-history?period=${period}`;
  const { data } = await apiClient.get(url);
  return data.reverse();
}

interface ICreditsData {
  balance: number;
}

export async function fetchCredits(): Promise<ICreditsData> {
  const { data } = await apiClient.get('/credits');
  return data;
}
