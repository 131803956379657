import { ElementSpec } from '@codemirror/lang-xml';
import { nameAttribute } from '../attributes';

const toElement: ElementSpec = {
  name: 'to',
  children: [],
};

export const connectionElement: ElementSpec = {
  name: 'connection',
  children: [toElement.name],
  attributes: [nameAttribute],
};

export default [connectionElement, toElement];
