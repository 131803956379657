import { ElementSpec } from '@codemirror/lang-xml';
import { publicAttribute } from '../../../attributes';

const verticalPositionElement: ElementSpec = {
  name: 'vertical_position',
  attributes: [publicAttribute],
  children: [],
};

const verticalVelocityElement: ElementSpec = {
  name: 'vertical_velocity',
  attributes: [publicAttribute],
  children: [],
};

export const altimeterElement: ElementSpec = {
  name: 'altimeter',
  children: [verticalPositionElement.name, verticalVelocityElement.name],
};

export default [altimeterElement, verticalPositionElement, verticalVelocityElement];
