import { ElementSpec } from '@codemirror/lang-xml';
import { publicAttribute } from '../../../attributes';
import {
  collisionDataElement,
  collisionGroupElement,
  collisionStatusElement,
  collisionTagElement,
  enableElement,
  lengthElement,
  shapeElement,
  horizontalElement,
  verticalElement,
} from '../../shared';

const dataElement: ElementSpec = {
  name: 'data',
  attributes: [publicAttribute],
  children: [],
};

const dataWriterElement: ElementSpec = {
  name: 'data_writer',
  children: [enableElement.name, dataElement.name],
};

export const rayElement: ElementSpec = {
  name: 'ray',
  children: [
    shapeElement.name,
    lengthElement.name,
    verticalElement.name,
    horizontalElement.name,
    collisionTagElement.name,
    collisionStatusElement.name,
    collisionDataElement.name,
    collisionGroupElement.name,
    dataWriterElement.name,
  ],
};

export default [rayElement, dataElement, dataWriterElement];
