import { toBoolean } from 'services';

export const API_URL = import.meta.env.VITE_AUTH0_AUDIENCE as string;
// Frankfurt
export const DEFAULT_REGION = 'europe-1';

export const ALLOW_SUBSCRIPTIONS = toBoolean(import.meta.env.VITE_ALLOW_SUBSCRIPTIONS);
export const ENABLE_ANALYTICS = toBoolean(import.meta.env.VITE_ENABLE_ANALYTICS);

export const SUPPORT_EMAIL = 'support@simumatik.com';
