import { apiClient } from 'lib/axios';

type SubscriptionStatus = 'active' | 'paused' | 'expired';

export interface ISubscriptionKey {
  activation_date: string;
  expiration_date: string;
  has_expired: boolean;
  id: string;
  organization_type: string;
  seats: number;
  years: number;
}

export interface ISubscription {
  credits: number;
  current_activation_date: string;
  current_expiration_date: string;
  current_subscription_key_id: string;
  keys: ISubscriptionKey[];
  organization_type: string;
  pause_count: number;
  pause_limit: number;
  seats: number;
  status: SubscriptionStatus;
}

export interface ISubscriptionPatchPayload {
  status?: 'active' | 'paused';
}

export async function fetchSubscription(organizationID: string): Promise<ISubscription> {
  const { data } = await apiClient.get(`/organizations/${organizationID}/subscription`);
  return data;
}

export async function activateSubscription(subscriptionKey: string, organizationID: string) {
  const { data } = await apiClient.post<ISubscription>(
    `/organizations/${organizationID}/subscription`,
    {
      subscription_key: subscriptionKey,
    },
  );
  return data;
}

export async function patchSubscription(
  organizationID: string,
  payload: ISubscriptionPatchPayload,
): Promise<ISubscription> {
  const url = `/organizations/${organizationID}/subscription`;
  const { data } = await apiClient.patch(url, payload);
  return data;
}
