import React, { ReactNode, useContext, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { fetchSubscription, ISubscription } from 'services/subscription';
import useIsFirstRender from '../../../hooks/utils/useIsFirstRender';
import useUpdateEffect from '../../../hooks/utils/useUpdateEffect';
import { AdminConsoleContext } from '../AdminConsoleProvider';

interface ISubscriptionContext {
  subscription: ISubscription | undefined;
  expired: boolean;
  canPause: boolean;
  loading: boolean;
}

interface ISubscriptionProviderProps {
  children: ReactNode;
}

export const SubscriptionContext = React.createContext<ISubscriptionContext>(
  {} as ISubscriptionContext,
);

export function SubscriptionProvider(props: ISubscriptionProviderProps) {
  const { children } = props;
  const { organization, updateOrganization } = useContext(AdminConsoleContext);

  const [expired] = useState<boolean>(false);
  const [canPause, setCanPause] = useState<boolean>(false);
  const [loading] = useState<boolean>(true);

  const { data: subscription } = useQuery(
    ['organization', organization?.id, 'subscription'],
    () => fetchSubscription(organization.id),
    {
      onSuccess(data) {
        setCanPause(data.pause_count < data.pause_limit);
      },
    },
  );

  const isFirst = useIsFirstRender();

  // Update organization data when subscription is no longer expired
  useUpdateEffect(() => {
    if (isFirst) return;
    updateOrganization();
  }, [expired]);

  const providerValue = useMemo(
    () => ({
      subscription,
      expired,
      canPause,
      loading,
    }),
    [canPause, expired, loading, subscription],
  );

  return (
    <SubscriptionContext.Provider value={providerValue}>{children}</SubscriptionContext.Provider>
  );
}
