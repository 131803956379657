import useUpdateEffect from 'hooks/utils/useUpdateEffect';
import { useContext, useState } from 'react';
import { useHasUnsavedXML } from 'stores/object-page';

import { NodeValueInputContext } from '../NodeValueInputProvider';
import { Value } from '../types';

const useNodeValueInput = (defaultValue: Value) => {
  const { updateValue } = useContext(NodeValueInputContext);
  const { registerUnsavedXML } = useHasUnsavedXML();
  const [value, setValue] = useState<Value>(defaultValue);

  const handleChange = (newValue: Value) => {
    // @ts-expect-error Types are a bit messy here, need to check for accidental null values
    if (Array.isArray(newValue) && newValue.includes(null)) {
      newValue.forEach((element, index, array) => {
        if (element === null) array[index] = 0;
      });
    }
    setValue(newValue);
    updateValue(newValue);
    registerUnsavedXML();
  };

  // ? Maybe use useMemo instead of state?
  useUpdateEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return { value, handleChange };
};

export default useNodeValueInput;
