import { toBoolean } from './strings';

// APPLICATION ENVIRONMENTS
export const CLIENT_ID: string | undefined = import.meta.env.VITE_AUTH0_CLIENT_ID;
export const DOMAIN: string | undefined = import.meta.env.VITE_AUTH0_DOMAIN;
export const AUDIENCE: string | undefined = import.meta.env.VITE_AUTH0_AUDIENCE;
export const BRANCH_ID: string | undefined = import.meta.env.VITE_BRANCH_ID;
export const ALLOW_INDIVIDUAL_ACCOUNT: boolean = toBoolean(
  import.meta.env.VITE_ALLOW_INDIVIDUAL_ACCOUNT,
);

// APPLICATION LINKS
/** Link to download service launcher */
export const LAUNCHER_DOWNLOAD_URL = `${AUDIENCE}/branches/${BRANCH_ID || 0}/launcher`;
export const GATEWAY_GITHUB_URL = 'https://github.com/Simumatik/simumatik-gateway';
// WEBSOCKET URLS
export const PING_INTERVAL = 10000;
export const LOCAL_SERVER_URL = '127.0.0.1';
export const LOCAL_SERVER_PORT = '4848';
export const LAUNCHER_URL = '127.0.0.1';
export const LAUNCHER_PORT = '2322';
export const GATEWAY_URL = '127.0.0.1';
export const GATEWAY_PORT = '2323';
