import { message } from 'antd';
import { rollbar } from 'lib/rollbar';

const serializeXML = (xmlDoc: Document | undefined): string => {
  try {
    if (!xmlDoc) throw new Error('XML document not initialized!');

    const serializer = new XMLSerializer();

    const xmlString = serializer.serializeToString(xmlDoc);

    return xmlString.replace(/xmlns="http:\/\/www.w3.org\/1999\/xhtml"/g, '');
  } catch (error) {
    message.error(error.message);
    rollbar.error('Error serializing XML', error);
    return '';
  }
};

export default serializeXML;
