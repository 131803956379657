import { message } from 'antd';
import { useContext } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { IOrganizationData } from 'services/organization';
import { ISubscriptionPatchPayload, patchSubscription } from 'services/subscription';
import { AdminConsoleContext } from '../AdminConsoleProvider';

export const usePauseResume = () => {
  const { organization } = useContext(AdminConsoleContext);
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (data: ISubscriptionPatchPayload) => patchSubscription(organization.id, data),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(['organization', organization?.id, 'subscription'], data);

        const organizationData = queryClient.getQueryData([
          'organization',
          organization?.id,
        ]) as IOrganizationData;
        if (organizationData)
          queryClient.setQueryData(['organization', organization?.id], {
            ...organizationData,
            is_blocked: data.status !== 'active',
          });
      },
      onError: (error: string) => {
        message.error(error);
      },
    },
  );

  const pause = async () => {
    await mutation.mutateAsync({ status: 'paused' });
  };

  const resume = async () => {
    await mutation.mutateAsync({ status: 'active' });
  };

  return { pause, resume, loading: mutation.isLoading };
};

export default usePauseResume;
