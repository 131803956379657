import { ElementSpec } from '@codemirror/lang-xml';
import { publicAttribute } from '../../attributes';

export const pressureElement: ElementSpec = {
  name: 'pressure',
  attributes: [publicAttribute],
  children: [],
};

export const flowElement: ElementSpec = {
  name: 'flow',
  attributes: [publicAttribute],
  children: [],
};

export default [pressureElement, flowElement];
