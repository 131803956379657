import { ElementSpec } from '@codemirror/lang-xml';
import { publicAttribute } from '../../attributes';

export const modeElement: ElementSpec = {
  name: 'mode',
  textContent: ['velocity', 'position'],
  children: [],
};

export const forceElement: ElementSpec = {
  name: 'force',
  attributes: [publicAttribute],
  children: [],
};

export const torqueElement: ElementSpec = {
  name: 'torque',
  attributes: [publicAttribute],
  children: [],
};

export const axisElement: ElementSpec = {
  name: 'axis',
  attributes: [publicAttribute],
};

export default [modeElement, forceElement, torqueElement, axisElement];
