import { IComponent, IObject, ISystem } from 'types/api-interfaces';

const date = new Date().toISOString();

export const dummyObject: IObject = {
  file_size: 0,
  created: date,
  modified: date,
  download_count: 0,
  name: 'Object',
  description: 'A descriptive description',
  keywords: 'Keyword, Another keyword',
  thumbnail_asset_id: '',
  thumbnail_url: '',
  id: 'f4db60cf-b51e-43f8-b525-a16025baf534',
  creator_id: 'dd24f75e-c471-45f2-9d20-1c2eb75c90cc',
  creator_name: 'Börje Lundin',
  owner_id: '42991ae0-e42a-4004-abf1-f0651ae19a47',
  owner_name: 'Börje Gustavsson',
};

export const dummyComponent: IComponent = {
  ...dummyObject,
  name: 'Component',
  manufacturer: 'Manufacturer',
  model: 'Model',
  e_class: '00-00-00-00',
};

export const dummySystem: ISystem = {
  ...dummyObject,
  name: 'System',
  components: [],
};
