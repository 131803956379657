import format from 'xml-formatter';

const formatXML = (xml: string) => {
  return format(xml, {
    collapseContent: true,
    indentation: '  ',
  });
};

export default formatXML;
