import { ElementSpec } from '@codemirror/lang-xml';
import { nameAttribute, publicAttribute } from '../../../attributes';
import { originElement } from '../../shared/generic';
import geometryElements, { geometryElement } from './geometry';
import materialElements, { materialElement } from './material';

const opacityElement: ElementSpec = {
  name: 'opacity',
  attributes: [publicAttribute],
  children: [],
};

const visibleElement: ElementSpec = {
  name: 'visible',
  attributes: [publicAttribute],
  children: [],
  textContent: ['true', 'false'],
};

const userActionElement: ElementSpec = {
  name: 'user_action',
  attributes: [publicAttribute],
  children: [],
};

export const visualElement: ElementSpec = {
  name: 'visual',
  children: [
    originElement.name,
    geometryElement.name,
    materialElement.name,
    opacityElement.name,
    visibleElement.name,
    userActionElement.name,
  ],
  attributes: [nameAttribute],
};

export default [
  visualElement,
  userActionElement,
  visibleElement,
  opacityElement,
  ...geometryElements,
  ...materialElements,
];
