import { apiClient } from 'lib/axios';
import { IComponent, IObjectTransfer } from 'types/api-interfaces';

export async function fetchComponents(searchTerm?: string, scope?: string): Promise<IComponent[]> {
  const formattedScope = scope === 'public' ? '/public' : '';
  const url = `/components${formattedScope}${
    searchTerm && searchTerm.length > 0 ? `?name=${searchTerm}` : ''
  }`;
  const { data } = await apiClient.get(url);
  return data;
}

export async function fetchComponent(componentID: string): Promise<IComponent> {
  const url = `/components/${componentID}`;
  const { data } = await apiClient.get(url);
  return data;
}

export async function createComponent(name: string): Promise<IComponent> {
  const { data } = await apiClient.post('/components', { name });
  return data;
}

export async function deleteComponent(componentID: string) {
  const url = `/components/${componentID}`;
  await apiClient.delete(url);
}

export async function transferComponent(
  componentID: string,
  recipient: string,
): Promise<IObjectTransfer> {
  const url = `/components/${componentID}/transfers`;
  const { data } = await apiClient.post(url, {
    recipient_id: recipient,
  });
  return data;
}

interface IPatchPayload {
  description?: string;
  e_class?: string;
  keywords?: string;
  manufacturer?: string;
  model?: string;
  name?: string;
  thumbnail_asset_id?: string;
}

export async function patchComponent(component: IComponent, payload: IPatchPayload) {
  const url = `/components/${component.id}`;
  const { data } = await apiClient.patch(url, payload);
  return data;
}

/**
 *
 * @param component The component to save the XML to
 * @param formData Request body
 * @returns {boolean} Returns true if response status is a 204
 */
export async function postComponentXML(
  component: IComponent | string,
  formData: FormData,
): Promise<boolean> {
  const url = `/components/${typeof component === 'string' ? component : component.id}/file`;
  const { status } = await apiClient.post(url, formData);
  return status === 204;
}

export async function fetchComponentXML(id: string, signal?: AbortSignal) {
  const { data } = await apiClient.get<string>(`/components/${id}/file`, {
    signal,
    responseType: 'text',
  });
  return data;
}
