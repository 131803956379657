import { UserAddOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Button,
  Form,
  Input,
  Modal,
  Result,
  Space,
  Steps,
  Tooltip,
  Typography,
  message,
} from 'antd';
import { useModal } from 'hooks/ui/useModal';
import useAnalytics from 'hooks/useAnalytics';
import { useUser } from 'hooks/useUser';
import { useCallback, useContext, useState } from 'react';
import { IGroup } from 'services/members';
import { addUserToOrganization, inviteUsersToOrganization } from 'services/organization';
import { AdminConsoleContext } from '../../../AdminConsoleProvider';
import EmailsForm from './EmailsForm';
import InviteUsers from './ReviewForm';
// import { groupCollapsed } from "console";
const { Step } = Steps;

enum InviteStep {
  AddEmails = 0,
  Review = 1,
  Done = 2,
}

export default function AddMembersButton() {
  const { me } = useUser();
  const { organization } = useContext(AdminConsoleContext);
  const analytics = useAnalytics();

  const [visible, setVisible] = useState(false);
  const [currentStep, setCurrentStep] = useState(InviteStep.AddEmails);

  // const [canAddMembers, setCanAddMembers] = useState(false)

  const [form] = Form.useForm();
  const [reviewForm] = Form.useForm();

  const modal = useModal();

  const queryClient = useQueryClient();
  const invitations = useMutation(
    () =>
      inviteUsersToOrganization(
        reviewForm.getFieldValue('emails'),
        form.getFieldValue('group').id,
        reviewForm.getFieldValue('message') ?? '',
        organization.id,
      ),
    {
      onSuccess: (data) => {
        setCurrentStep(InviteStep.Done);

        if (data.invitations.successful.length > 0) message.success('Invitations sent!');

        if (data.invitations.failed.length > 0) {
          modal.warning({
            title: 'Invitations Failed',
            content: (
              <Space direction="vertical">
                <Typography.Text>
                  We were unable to send invitations to the following email addresses:
                </Typography.Text>
                <Input.TextArea readOnly>{data.invitations.successful.join(', ')}</Input.TextArea>
                <Typography.Text>
                  Please make sure you have entered the correct email addresses.
                </Typography.Text>
              </Space>
            ),
          });
        }
      },
      onError: (error: string) => {
        message.error(error);
      },
    },
  );
  const members = useMutation((data: { email: string; group: IGroup }) =>
    addUserToOrganization(data.email, parseInt(data.group.id, 10), organization.id),
  );

  const createInvites = useCallback(
    async (fieldValue: string) => {
      const group = form.getFieldValue('group');
      const emails: string[] = fieldValue
        .split(/\s*,\s*/)
        .join(',')
        .replace(/\n+/g, ',')
        .split(',')
        .map((email: string) => email.trim())
        .filter((email: string) => email !== '')
        .filter((email: string) => email !== me.email); // Add this to validation instead

      // Remove duplicates
      const uniqueEmails = [...new Set(emails)];

      const organizationMembers = organization?.members ?? [];
      const existingEmails: string[] = organizationMembers.map((member) => member.email);

      const filteredEmails = uniqueEmails.filter(
        (email: string) => !existingEmails.includes(email),
      );
      const unregisteredEmails: string[] = [];

      for (const email of filteredEmails) {
        try {
          await members.mutateAsync({ email, group });
        } catch (error) {
          unregisteredEmails.push(email);
        }
      }

      return unregisteredEmails;
    },
    [form, me.email, members, organization?.members],
  );

  const modalContent = () => {
    switch (currentStep) {
      case InviteStep.AddEmails:
        return <EmailsForm form={form} onFinish={handleFinish} />;

      case InviteStep.Review:
        return <InviteUsers form={reviewForm} onFinish={handleInviteFinish} />;

      case InviteStep.Done:
        return <Result title="Invitations Sent!" status="success" />;

      default:
        return null;
    }
  };

  const getOkButtonText = () => {
    switch (currentStep) {
      case InviteStep.AddEmails:
        return 'Next';
      case InviteStep.Review:
        return 'Invite';
      default:
        return 'OK';
    }
  };

  const close = () => {
    const group = form.getFieldValue('group');
    if (group) queryClient.fetchQuery(['organization', organization?.id, 'group', group.id]);

    form.resetFields();
    reviewForm.resetFields();
    setVisible(false);
    setCurrentStep(InviteStep.AddEmails);
  };

  const handleOk = () => {
    switch (currentStep) {
      case InviteStep.AddEmails:
        form.submit();
        break;

      case InviteStep.Review:
        reviewForm.submit();
        break;

      case InviteStep.Done:
        queryClient.fetchQuery(['organization', organization?.id]);
        analytics.trackEvent('Admin: Invited Members');
        close();
        break;

      default:
        break;
    }
  };

  const handleCancel = () => {
    close();
  };

  const handleClick = () => {
    setVisible(true);
  };

  const handleFinish = async () => {
    const invites = await createInvites(form.getFieldValue('emails'));
    if (invites.length === 0) {
      setCurrentStep(InviteStep.Done);
      return;
    }
    reviewForm.setFieldValue('emails', invites);
    setCurrentStep(InviteStep.Review);
  };

  const handleInviteFinish = () => {
    invitations.mutate();
  };

  return (
    <>
      <Tooltip
        title={
          organization.members.length < organization.seats
            ? 'Invite members to your organization'
            : 'No more available seats'
        }
      >
        <Button
          type="primary"
          onClick={handleClick}
          icon={<UserAddOutlined />}
          disabled={organization.members.length === organization.seats}
        >
          Add Members
        </Button>
      </Tooltip>

      <Modal
        title="Add Members"
        open={visible}
        className="addUsersModal"
        onCancel={handleCancel}
        onOk={handleOk}
        okButtonProps={{
          loading: currentStep === InviteStep.Review && invitations.isLoading,
        }}
        okText={getOkButtonText()}
        afterClose={() => {
          form.resetFields();
          reviewForm.resetFields();
        }}
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          <Steps current={currentStep}>
            <Step title="Enter Emails" />
            <Step title="Review" />
            <Step title="Done" />
          </Steps>
          {modalContent()}
        </Space>
      </Modal>
    </>
  );
}
