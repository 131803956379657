import { ReactNode, createContext, useMemo } from 'react';
import { useLocalServicesLauncher } from './hooks/useLocalServicesLauncher';

interface ILocalServicesContext {
  serviceLauncher: ReturnType<typeof useLocalServicesLauncher>;
}
export const LocalServicesContext = createContext<ILocalServicesContext>(
  {} as ILocalServicesContext,
);

interface ILocalServicesProvider {
  children: ReactNode;
}

export function LocalServicesProvider(props: ILocalServicesProvider) {
  const { children } = props;
  const serviceLauncher = useLocalServicesLauncher();

  const providerValue = useMemo(() => {
    return { serviceLauncher };
  }, [serviceLauncher]);

  return (
    <LocalServicesContext.Provider value={providerValue}>{children}</LocalServicesContext.Provider>
  );
}
