import { AttrSpec, ElementSpec } from '@codemirror/lang-xml';
import { nameAttribute, publicAttribute } from '../attributes';

const scriptElement: ElementSpec = {
  name: 'script',
  children: [],
};

const stepTimeElement: ElementSpec = {
  name: 'step_time',
  attributes: [publicAttribute],
  children: [],
};

const dataTypeAttribute: AttrSpec = {
  name: 'data_type',
  values: [
    'smtk_bool',
    'smtk_bytes',
    'smtk_int',
    'smtk_float',
    'smtk_string',
    'smtk_vector',
    'smtk_vector2',
    'smtk_vector3',
    'smtk_rgba',
  ],
};

const typeAttribute: AttrSpec = {
  name: 'type',
  values: ['input', 'output'],
};

const variableElement: ElementSpec = {
  name: 'variable',
  attributes: [publicAttribute, nameAttribute, dataTypeAttribute, typeAttribute],
  children: [],
};

export const behaviorElement: ElementSpec = {
  name: 'behavior',
  attributes: [
    {
      name: 'name',
    },
  ],
  children: [scriptElement.name, stepTimeElement.name, variableElement.name],
};

export default [behaviorElement, variableElement, stepTimeElement, scriptElement];
