import { useModal } from 'hooks/ui/useModal';
import { useUser } from 'hooks/useUser';
import { ILibrary } from 'types/api-interfaces';
import { canDelete } from 'utils/authorizations';
import { useLibraryState } from './useLibraryState';

export function useRemoveLibrary(library: ILibrary) {
  const { me } = useUser();
  const canIDelete = canDelete(me, library);
  const { remove } = useLibraryState();
  const modal = useModal();

  const removeLibrary = () => {
    modal.confirm({
      title: 'Remove Library?',
      content: `Are you sure you want to remove the library ${library.name}? This action cannot be undone.`,
      okText: 'Yes',
      cancelText: 'No',
      okButtonProps: {
        // loading: loading
      },
      onOk: async (close) => {
        if (!canIDelete) return;
        await remove.mutateAsync(library.id);
        close();
      },
    });
  };

  return removeLibrary;
}
