import { UserRole } from 'types/api-interfaces';

export enum ObjectShareMode {
  Viewer = 'viewer',
  Editor = 'editor',
}

export type ShareType = 'user' | 'group' | 'all';

export interface IObjectShareOrgGroup {
  id: string;
  name: string;
  type: ShareType;
}

export interface IObjectShareOrgUser {
  id: string;
  name: string;
  email: string;
  type: ShareType;
}

export interface IObjectShare {
  id: number;
  object_id: string;
  share_id: string;
  share_type: ShareType;
  mode: string;
  uname?: string;
  gname?: string;
  email?: string;
}

export interface IObjectSharePatch {
  object_id: string;
  mode: string;
}

export interface IGroupMember {
  id: string;
  name: string;
  email: string;
  blocked: boolean;
  role: UserRole;
  joined: string;
}

export type UserSelection = (IObjectShareOrgUser | IObjectShareOrgGroup)[];
