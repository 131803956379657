import { ElementSpec } from '@codemirror/lang-xml';
import { publicAttribute } from '../../../attributes';
import { updateRateElement } from '../../shared';
import {
  enableElement,
  originElement,
  positionElement,
  velocityElement,
} from '../../shared/generic';
import altimeterElements, { altimeterElement } from './altimeter';
import cameraElements, { cameraElement } from './camera';
import rayElements, { rayElement } from './ray';

const triggerElement: ElementSpec = {
  name: 'trigger',
  attributes: [publicAttribute],
  textContent: ['true', 'false'],
  children: [],
};

const navsatElement: ElementSpec = {
  name: 'navsat',
  children: [positionElement.name, velocityElement.name],
};

export const sensorElement: ElementSpec = {
  name: 'sensor',
  children: [
    originElement.name,
    enableElement.name,
    updateRateElement.name,
    triggerElement.name,
    rayElement.name,
    cameraElement.name,
    altimeterElement.name,
    navsatElement.name,
  ],
};

export default [
  sensorElement,
  updateRateElement,
  triggerElement,
  navsatElement,
  ...altimeterElements,
  ...cameraElements,
  ...rayElements,
];
