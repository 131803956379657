import { DeleteOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { useUser } from 'hooks/useUser';
import React, { useContext } from 'react';
import { canDelete } from 'utils/authorizations';
import { ListItemContext } from '../ListItemProvider';
import { useRemoveLibrary } from './hooks/useRemoveLibrary';

function Delete() {
  const { me } = useUser();
  const { library } = useContext(ListItemContext);
  const canIDelete = canDelete(me, library);
  const removeLibrary = useRemoveLibrary(library);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    removeLibrary();
  };

  return (
    <Tooltip placement="top" title="Remove library">
      <Button
        icon={<DeleteOutlined />}
        type="link"
        danger
        onClick={handleClick}
        disabled={!canIDelete}
      />
    </Tooltip>
  );
}

export default Delete;
