import { ElementSpec } from '@codemirror/lang-xml';
import { nameAttribute, publicAttribute } from '../../attributes';
import baseChildren from './base';

const voltageElement: ElementSpec = {
  name: 'voltage',
  attributes: [publicAttribute],
};

const currentElement: ElementSpec = {
  name: 'current',
  attributes: [publicAttribute],
};

const children = [...baseChildren, voltageElement.name, currentElement.name];

export const electricInputElement: ElementSpec = {
  name: 'electric_input',
  attributes: [nameAttribute],
  children,
};

export const electricOutputElement: ElementSpec = {
  name: 'electric_output',
  attributes: [nameAttribute],
  children,
};

export default [electricInputElement, electricOutputElement, voltageElement, currentElement];
