export interface PlanPrice {
  value: number;
  recurring: boolean;
  credits?: number;
}

export interface PlanFeature {
  name: string;
  title: string;
  disabled?: boolean;
  highlight?: boolean;
}

export interface Plan {
  title: string;
  description: string;
  name: PlanName;
  type: 'plan';
  features: PlanFeature[];
  price: PlanPrice;
  discount: number;
  price_id?: string;
}

export interface PlanGroup {
  plans: Plan[];
  type: 'group';
  name: string;
}

export enum PlanName {
  Free = 'basic',
  Standard = 'standard',
  Premium6Months = 'premium_6mo',
  Premium12Months = 'premium_12mo',
}
