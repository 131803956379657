import { ElementSpec } from '@codemirror/lang-xml';
import { nameAttribute } from '../../attributes';
import { forceElement, torqueElement } from '../shared';
import { originElement } from '../shared/generic';

export const applyForceElement: ElementSpec = {
  name: 'apply_force',
  attributes: [nameAttribute],
  children: [originElement.name, forceElement.name, torqueElement.name],
};

export default [applyForceElement];
