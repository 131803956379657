import { WarningOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { rollbar } from 'lib/rollbar';
import React, { ReactNode } from 'react';

interface ITryProps {
  location: string;
  notify?: string;
  children: ReactNode;
}
interface ITryState {
  hasError: boolean;
}

/**
 * @deprecated use ErrorBoundaryWrapper instead
 */
export default class Try extends React.PureComponent<ITryProps, ITryState> {
  constructor(props: ITryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: unknown) {
    try {
      // You can also log the error to an error reporting service
      const stack = String(JSON.stringify(errorInfo)).match(/in ([A-Z])\w+/);
      const msg = `${
        stack
          ? stack
              .map((component: string) => component.replace('in ', ''))
              .slice(0, 1)
              .toString()
          : ''
      } : ${error.message}`;

      rollbar.error(msg, error);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('App-Recovery-Error:', error.message);
    }
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      // You can render any custom fallback UI
      return (
        <Typography.Text type="danger">
          {' '}
          <WarningOutlined />
          Error!
        </Typography.Text>
      );
    }

    return children;
  }
}
