import { DeleteOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Popconfirm } from 'antd';
import { ComponentLibraryBrowserContext } from 'providers/ComponentLibraryBrowserProvider';
import React, { useContext } from 'react';
import { deleteLibraryComponent } from 'services/libraries';
import { IComponent } from 'types/api-interfaces';

interface Props {
  component: IComponent;
}

export const RemoveButton: React.FC<Props> = ({ component }) => {
  const { selectedLibrary } = useContext(ComponentLibraryBrowserContext);
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (id: string) => deleteLibraryComponent(id, component.id),
    onSuccess: () => {
      queryClient.setQueryData<IComponent[]>(['library', selectedLibrary?.id], (prevData) => {
        if (prevData === undefined) {
          return;
        }

        return [...prevData].filter(({ id }) => id !== component.id);
      });
    },
  });

  const handleConfirm: React.ComponentProps<typeof Popconfirm>['onConfirm'] = (e) => {
    e?.stopPropagation();

    if (!selectedLibrary) {
      return;
    }

    mutation.mutate(selectedLibrary.id);
  };

  return (
    <Popconfirm
      title="Remove component from this library?"
      onConfirm={handleConfirm}
      okText="Yes"
      cancelText="No"
    >
      <Button type="link" danger icon={<DeleteOutlined />} onClick={(e) => e.stopPropagation()} />
    </Popconfirm>
  );
};

export default RemoveButton;
