import { DollarCircleOutlined } from '@ant-design/icons';
import { Skeleton, Space, Tag } from 'antd';
import { ISubscription } from 'services/subscription';
import PageHeader from 'components/PageHeader';
import PauseResumeButton from './PauseResumeButton';
import RenewButton from './RenewButton';

export default function ActiveSubscription(props: {
  subscription: ISubscription | undefined;
  loading?: boolean;
}) {
  const { subscription, loading } = props;

  const renderTag = () => {
    switch (subscription?.status) {
      case 'active':
        return <Tag color="green">Active</Tag>;

      case 'paused':
        return <Tag color="gold">Paused</Tag>;

      case 'expired':
        return <Tag color="red">Expired</Tag>;

      default:
        return undefined;
    }
  };

  return (
    <PageHeader
      title={
        <Space>
          <DollarCircleOutlined />
          <span>Subscription</span>
        </Space>
      }
      className="site-page-header"
      subTitle={
        <Skeleton loading={loading} active paragraph={false}>
          <span style={{ textTransform: 'capitalize' }}>
            {subscription?.organization_type || 'None'}
          </span>
        </Skeleton>
      }
      tags={renderTag()}
      extra={[<PauseResumeButton key="pause-resume" />, <RenewButton key="renew" />]}
    />
  );
}

ActiveSubscription.defaultProps = {
  loading: false,
};
