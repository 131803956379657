import useInterval from 'hooks/utils/useInterval';
import { OEPClientInst } from 'oep-web-client';
import { useState } from 'react';
import formatFileSize from 'utils/formatFileSize';
import { BytesPerSecondChart, BytesPerSecondChartProps } from './components/BytesPerSecondChart';

type Stats = {
  value: number;
  label: string;
  formatter?: (value: number) => string;
}[];

type StatGroup = {
  label: string;
  stats: Stats;
};

export const Statistics = () => {
  const DATA_LENGTH = 10;
  const [data, setData] = useState<{
    averageBytesPerSecond: BytesPerSecondChartProps['data'];
    averageRequestsPerSecond: Array<number>;
  }>({
    averageBytesPerSecond: new Array(DATA_LENGTH).fill({
      sent: 0,
      received: 0,
    }),
    averageRequestsPerSecond: new Array(DATA_LENGTH).fill(0),
  });

  const [stats, setStats] = useState<Stats>([]);
  const [statGroups, setStatGroups] = useState<StatGroup[]>([]);

  useInterval(() => {
    const { statistics } = OEPClientInst.Instance;

    const newData = { ...data };

    newData.averageBytesPerSecond.shift();
    newData.averageBytesPerSecond.push({
      sent: statistics.averageSentBytesPerSecond,
      received: statistics.averageReceivedBytesPerSecond,
    });

    newData.averageRequestsPerSecond.shift();
    newData.averageRequestsPerSecond.push(statistics.averageRequestsPerSecond);
    setData(newData);

    const newStats: Stats = [
      {
        value: statistics.averageResponseTime,
        label: 'Average Response Time',
        formatter: (value) => `${value.toFixed(2)} ms`,
      },
    ];

    setStats(newStats);

    const newStatGroups: StatGroup[] = [
      {
        label: 'Messages',
        stats: [
          {
            value: statistics.totalMessageCount,
            label: 'Total',
          },
          {
            value: statistics.largestMessageReceived,
            label: 'Largest',
            formatter: formatFileSize,
          },
        ],
      },
      {
        label: 'Events',
        stats: [
          {
            value: statistics.totalEventCount,
            label: 'Total',
          },
          {
            value: statistics.largestEventReceived,
            label: 'Largest',
            formatter: formatFileSize,
          },
        ],
      },
      {
        label: 'Datachanges',
        stats: [
          {
            value: statistics.totalDatachangeCount,
            label: 'Total',
          },
          {
            value: statistics.largestDatachangeReceived,
            label: 'Largest',
            formatter: formatFileSize,
          },
        ],
      },
      {
        label: 'Responses',
        stats: [
          {
            value: statistics.totalResponseCount,
            label: 'Total',
          },
          {
            value: statistics.largestResponseReceived,
            label: 'Largest',
            formatter: formatFileSize,
          },
        ],
      },
      {
        label: 'Problems',
        stats: [
          {
            value: statistics.invalidMessageCount,
            label: 'Invalid Messages',
          },
          {
            value: statistics.lateResponseCount,
            label: 'Late Responses',
          },
        ],
      },
    ];
    setStatGroups(newStatGroups);
  }, 1000);

  return (
    <div className="flex h-full w-full flex-col">
      <div className="p-8">
        <h2>Stats</h2>
        <div className="mb-8 grid w-full grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-5">
          {statGroups.map((group) => (
            <div
              className="rounded-md border border-solid border-neutral-600 p-4 shadow-2xl shadow-neutral-800"
              key={group.label}
            >
              <div className="mb-4 text-xl font-medium">{group.label}</div>
              <div className="flex flex-col gap-2">
                {group.stats.map((stat) => (
                  <div className="flex flex-col" key={stat.label}>
                    <div className="text-xs text-white/75">{stat.label}</div>
                    <div className="text-xl font-medium">
                      {stat.formatter ? stat.formatter(stat.value) : stat.value}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className="grid w-full grid-cols-5 gap-4">
          {stats.map((stat) => (
            <div className="flex flex-col" key={stat.label}>
              <div className="text-xs text-white/75">{stat.label}</div>
              <div className="text-xl font-medium">
                {stat.formatter ? stat.formatter(stat.value) : stat.value}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="box-content grid flex-1 gap-8 p-8 lg:grid-cols-2">
        {/* <div className="h-80">
          <RequestsPerSecondChart data={data.averageRequestsPerSecond} />
        </div> */}
        <div className="h-80 lg:col-span-2">
          <BytesPerSecondChart data={data.averageBytesPerSecond} />
        </div>
      </div>
    </div>
  );
};
