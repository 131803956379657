import { Tag } from 'antd';
import { IObject } from '../common';

type PublishStatusTagProps = {
  object: IObject;
};

const COLOR_REVIEW = '#2db7f5';
const COLOR_PUBLISHED = '#52c41a';

export default function PublishStatusTag({ object }: PublishStatusTagProps) {
  if (!object) return null;

  if (object.has_review_version) return <Tag color={COLOR_REVIEW}>Under Review</Tag>;

  if (object.has_public_version) return <Tag color={COLOR_PUBLISHED}>Published</Tag>;

  return null;
}
