import { ElementSpec } from '@codemirror/lang-xml';
import { nameAttribute } from '../../attributes';
import {
  horizontalElement,
  verticalElement,
  collisionTagElement,
  originElement,
  enableElement,
  valueElement,
  shapeElement,
  lengthElement,
} from '../shared';

const modeElement: ElementSpec = {
  name: 'mode',
  children: [],
};

export const forceSourceElement: ElementSpec = {
  name: 'force_source',
  attributes: [nameAttribute],
  children: [
    originElement.name,
    enableElement.name,
    valueElement.name,
    modeElement.name,
    shapeElement.name,
    lengthElement.name,
    verticalElement.name,
    horizontalElement.name,
    collisionTagElement.name,
  ],
};

export default [forceSourceElement, modeElement];
