import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';

function useSignup() {
  const { loginWithRedirect } = useAuth0();
  useEffect(() => {
    loginWithRedirect({
      authorizationParams: {
        screen_hint: 'signup',
        redirectUri: window.location.origin,
      },
    });
  }, [loginWithRedirect]);
}

export function Signup() {
  useSignup();
  return null;
}
