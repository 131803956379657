import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import { nanoid } from 'nanoid';
import { ReactNode, useEffect } from 'react';
import { FeatureFlags } from 'simumatik-commons';

let visitorId = localStorage.getItem('visitor_id');
if (!visitorId) {
  visitorId = nanoid();
  localStorage.setItem('visitor_id', visitorId);
}

const growthbook = new GrowthBook({
  apiHost: import.meta.env.VITE_GROWTHBOOK_API_HOST,
  clientKey: import.meta.env.VITE_GROWTHBOOK_CLIENT_KEY,
  enableDevMode: import.meta.env.DEV,
  attributes: {
    id: visitorId,
  },
});

FeatureFlags.growthbook = growthbook;

type FeatureFlagProviderProps = {
  children: ReactNode;
};

function FeatureFlagProvider({ children }: FeatureFlagProviderProps) {
  useEffect(() => {
    growthbook.loadFeatures({
      autoRefresh: true,
    });
  }, []);

  return <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>;
}

export default FeatureFlagProvider;
