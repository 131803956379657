import { DeleteOutlined, EditOutlined, EllipsisOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import { MenuItemType } from 'antd/es/menu/hooks/useItems';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useOrganization } from 'hooks/useOrganization';
import React, { useContext, useMemo, useState } from 'react';
import { ListItemContext } from '../ListItemProvider';
import RenameModal from './RenameModal';
import { useLibraryState } from './hooks/useLibraryState';
import { useRemoveLibrary } from './hooks/useRemoveLibrary';

function ActionsMenu() {
  const { organization } = useOrganization();
  const { library } = useContext(ListItemContext);
  const { patch } = useLibraryState();
  const [showRenameModal, setShowRenameModal] = useState(false);
  const removeLibrary = useRemoveLibrary(library);

  const handleRenameClick: MenuItemType['onClick'] = (e) => {
    e.domEvent.stopPropagation();
    setShowRenameModal(true);
  };

  const handleRenameCancel = () => {
    setShowRenameModal(false);
  };

  const handleRenameFinish = () => {
    setShowRenameModal(false);
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
  };

  const organizationOption: ItemType[] = useMemo(() => {
    if (organization) {
      return [
        {
          onClick: (e) => {
            e.domEvent.stopPropagation();
            patch.mutate({ id: library.id, payload: { public: !library.public } });
          },
          label: library.public ? 'Make Private' : 'Make Public',
          key: 'public',
        },
        {
          onClick: (e) => {
            e.domEvent.stopPropagation();
            patch.mutate({ id: library.id, payload: { visible: !library.visible } });
          },
          label: library.visible ? 'Make Hidden' : 'Make Visible',
          key: 'visibility',
        },
        {
          type: 'divider',
        },
      ];
    }

    return [];
  }, [library.public, library.visible, library.id, organization, patch]);

  const items: ItemType[] = [
    ...organizationOption,
    {
      onClick: handleRenameClick,
      icon: <EditOutlined />,
      label: 'Rename',
      key: 'rename',
    },
    {
      type: 'divider',
    },
    {
      onClick: (e) => {
        e.domEvent.stopPropagation();
        removeLibrary();
      },
      danger: true,
      icon: <DeleteOutlined />,
      label: 'Remove',
      key: 'remove',
    },
  ];

  return (
    <>
      <Dropdown menu={{ items }} trigger={['click']}>
        <Button onClick={handleClick} icon={<EllipsisOutlined />} type="text" />
      </Dropdown>
      <RenameModal
        library={library}
        open={showRenameModal}
        onCancel={handleRenameCancel}
        onFinish={handleRenameFinish}
      />
    </>
  );
}

export default ActionsMenu;
