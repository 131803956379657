import { ElementSpec } from '@codemirror/lang-xml';
import { nameAttribute } from '../../attributes';
import { flowElement, pressureElement } from '../shared';
import baseChildren from './base';

const children = [...baseChildren, pressureElement.name, flowElement.name];

export const hydraulicInputElement: ElementSpec = {
  name: 'hydraulic_input',
  attributes: [nameAttribute],
  children,
};

export const hydraulicOutputElement: ElementSpec = {
  name: 'hydraulic_output',
  attributes: [nameAttribute],
  children,
};

export default [hydraulicInputElement, hydraulicOutputElement];
