import { List, Typography } from 'antd';
import { useOrganization } from 'hooks/useOrganization';
import { ComponentLibraryBrowserContext } from 'providers/ComponentLibraryBrowserProvider';
import React, { useContext, useMemo, useRef, useState } from 'react';
import { ILibrary } from 'types/api-interfaces';
import { useResizeObserver } from 'usehooks-ts';
import { LibraryBrowserContext } from '../LibraryBrowserProvider';
import ListItemProvider from './ListItemProvider';
import ActionsMenu from './actions/ActionsMenu';
import Delete from './actions/Delete';
import Rename from './actions/Rename';
import TogglePublic from './actions/TogglePublic';
import ToggleVisibility from './actions/ToggleVisibility';

const { Meta } = List.Item;
const { Text } = Typography;

const COLLAPSE_BREAKPOINT = 500;

interface Props {
  library: ILibrary;
}

export const ListItem: React.FC<Props> = ({ library }) => {
  const { organization } = useOrganization();
  const { scope } = useContext(LibraryBrowserContext);
  const { setSelectedLibrary } = useContext(ComponentLibraryBrowserContext);
  const listItemRef = useRef<HTMLElement>(null);
  const [collapseActions, setCollapseActions] = useState(false);

  useResizeObserver({
    ref: listItemRef,
    onResize: (size) => {
      if (size.width && size.width < COLLAPSE_BREAKPOINT) {
        setCollapseActions(true);
      } else if (collapseActions) {
        setCollapseActions(false);
      }
    },
  });

  const actions = useMemo(() => {
    if (collapseActions) return [<ActionsMenu key="collapseActions" />];
    if (scope === 'private' && organization)
      return [
        <TogglePublic key="togglePublic" />,
        <ToggleVisibility key="toggleVisibility" />,
        <Rename key="rename" library={library} />,
        <Delete key="delete" />,
      ];
    if (scope === 'private')
      return [<Rename key="rename" library={library} />, <Delete key="delete" />];
    return [];
  }, [collapseActions, organization, library, scope]);

  const handleClick = () => {
    setSelectedLibrary(library);
  };

  return (
    <ListItemProvider library={library}>
      <List.Item
        className="hover:cursor-pointer hover:bg-black/5"
        onClick={handleClick}
        actions={actions}
        ref={listItemRef}
      >
        <Meta title={<Text ellipsis>{library.name}</Text>} description={library.owner_name} />
      </List.Item>
    </ListItemProvider>
  );
};

export default ListItem;
