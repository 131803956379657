import { useQuery } from '@tanstack/react-query';
import { useOrganization } from 'hooks/useOrganization';
import React, { ReactNode, useMemo } from 'react';
import { fetchOrganization } from 'services/organization';
import { IOrganizationMember } from 'types/api-interfaces';

interface IOrganizationData {
  created: string;
  id: string;
  is_blocked: boolean;
  logo_url: string;
  members: IOrganizationMember[];
  modified: string;
  name: string;
  organization_type: string | null;
  seats: number;
  site_url: string;
}

interface IAdminConsoleContext {
  organization: IOrganizationData;
  updateOrganization: () => void;
  adminPermissions: boolean | undefined;
  managerPermissions: boolean | undefined;
  loading: boolean;
}
interface IAdminConsoleProviderProps {
  children: ReactNode;
}

export const AdminConsoleContext = React.createContext({} as IAdminConsoleContext);

export function AdminConsoleProvider(props: IAdminConsoleProviderProps) {
  const { children } = props;
  const { organization } = useOrganization();
  const { data, isLoading } = useQuery(['organization', organization?.id], () =>
    organization ? fetchOrganization(organization?.id) : undefined,
  );

  const providerValue = useMemo(() => {
    return {
      organization: data ?? ({} as IOrganizationData),
      updateOrganization: () => {},
      adminPermissions: organization?.is_admin,
      managerPermissions: organization?.can_manage,
      loading: isLoading,
    };
  }, [organization, data, isLoading]);

  return (
    <AdminConsoleContext.Provider value={providerValue}>{children}</AdminConsoleContext.Provider>
  );
}
