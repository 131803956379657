import { ElementSpec } from '@codemirror/lang-xml';
import { publicAttribute } from '../../attributes';
import { originElement } from '../shared';

const massElement: ElementSpec = {
  name: 'mass',
  attributes: [publicAttribute],
  children: [],
};

const inertiaElement: ElementSpec = {
  name: 'inertia',
  attributes: [publicAttribute],
  children: [],
};

export const inertialElement: ElementSpec = {
  name: 'inertial',
  children: [originElement.name, massElement.name, inertiaElement.name],
};

export default [inertialElement, massElement, inertiaElement];
