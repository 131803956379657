import React from 'react';
import { XMLNodes } from 'utils/xml-nodes';
import PortDot from './PortDot';

interface IPortsState {}
interface IPortsProps {
  xmlDoc: Document;
  onUpdate: () => void;
  parentSize: { x: number; y: number };
  editable?: boolean;
}
export default class Ports extends React.PureComponent<IPortsProps, IPortsState> {
  draw = (element: Element, index: number) => {
    const { editable, onUpdate, parentSize } = this.props;
    const position = element.querySelector('position');
    if (!position) return null;
    let coordinate =
      position && position.textContent
        ? position.textContent.trim().split(' ').map(Number)
        : [0, 0];

    if (coordinate.length !== 2) coordinate = [0, 0];

    const canEdit = editable !== undefined ? editable : true;

    return (
      <PortDot
        key={index}
        index={index}
        coordinate={coordinate}
        element={position}
        name={String(element.getAttribute('name'))}
        onUpdate={onUpdate}
        editable={canEdit}
        parentSize={parentSize}
      />
    );
  };

  render() {
    const { xmlDoc } = this.props;

    const portElements = XMLNodes.ports.flatMap((portName: string) => [
      ...xmlDoc.querySelectorAll(`component > ${portName}`),
    ]);

    return portElements.map(this.draw);
  }
}
