import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { XMLError } from '../utils/validateXML';

type State = {
  errors: XMLError[];
};

type Actions = {
  updateErrors: (errors: XMLError[]) => void;
};

const initialState: State = {
  errors: [],
};

export const useXMLErrorsStore = create(
  devtools<State & Actions>(
    (set) => ({
      ...initialState,
      updateErrors: (errors) => {
        set({
          errors,
        });
      },
    }),
    { name: 'XMLErrors', enabled: import.meta.env.DEV },
  ),
);

export default useXMLErrorsStore;
