import { useQuery } from '@tanstack/react-query';
import { Button, Col, Image, Modal, Row, Skeleton, Typography } from 'antd';
import React from 'react';
import { fetchComponent } from 'services/components';
import { fetchSystem } from 'services/systems';
import { IComponent, ISystem } from 'types/api-interfaces';
import capitalizeString from 'utils/capitalizeString';
import { thumbnailPlaceholderUrl } from 'utils/environmentVariables';

const { Title, Paragraph } = Typography;

type ObjectInfoModalProps = {
  id: string;
  type: string;
  open: boolean;
  onClose: () => void;
  onClickOpen?: () => void;
  onClickLoad?: () => void;
  afterClose?: () => void;
};

export function ObjectInfoModal({
  id,
  open,
  type,
  onClose,
  onClickOpen,
  onClickLoad,
  afterClose,
}: ObjectInfoModalProps) {
  const query = useQuery({
    queryKey: [type, id],
    queryFn: () => fetchObject(id, type),
  });

  return (
    <Modal
      title={`${capitalizeString(type)} Information`}
      open={open}
      onCancel={onClose}
      width="50%"
      destroyOnClose
      footer={
        query.data ? (
          <ModalFooter
            object={query.data}
            onClickLoad={onClickLoad}
            onClickOpen={onClickOpen}
            onClose={onClose}
          />
        ) : null
      }
      afterClose={afterClose}
    >
      <Row gutter={24}>
        <Col span={14}>
          <Skeleton title paragraph={{ rows: 3 }} loading={query.isLoading}>
            <Title level={5}>{query.data?.name}</Title>
            {query.data?.description
              .split('\n')
              .map((paragraph) => <Paragraph key={paragraph}>{paragraph}</Paragraph>)}
          </Skeleton>
        </Col>
        <Col span={10}>
          <Image src={query.data?.thumbnail_url || 'error'} fallback={thumbnailPlaceholderUrl} />
        </Col>
      </Row>
    </Modal>
  );
}

interface ModalFooterProps {
  object: IComponent | ISystem;
  onClickOpen?: () => void;
  onClickLoad?: () => void;
  onClose?: () => void;
}

const ModalFooter: React.FC<ModalFooterProps> = ({ object, onClickOpen, onClickLoad, onClose }) => {
  const modifiedDate = new Date(object.modified);
  const isBlocked = object.cloning_blocked;

  return (
    <div className="flex w-full items-center justify-between">
      <span>Last Modified: {modifiedDate.toLocaleString()}</span>
      <Button.Group>
        <Button onClick={onClose}>Cancel</Button>
        {onClickOpen ? (
          <Button
            onClick={onClickOpen}
            disabled={object.type === 'component' && isBlocked}
            type={onClickLoad ? 'default' : 'primary'}
            title={isBlocked ? `The ${object.type} page is blocked` : undefined}
          >
            Open
          </Button>
        ) : null}
        {onClickLoad ? (
          <Button type="primary" onClick={onClickLoad}>
            Load
          </Button>
        ) : null}
      </Button.Group>
    </div>
  );
};

function fetchObject(id: string, type: string): Promise<IComponent | ISystem | null> {
  switch (type) {
    case 'component':
      return fetchComponent(id);

    case 'system':
      return fetchSystem(id);

    default:
      return new Promise((resolve) => resolve(null));
  }
}
