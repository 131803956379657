import { message } from 'antd';
import { GLOBALS } from 'simumatik-commons';

import LoadingPage from 'components/LoadingPage';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { fetchComponentXSD } from 'services/datamodel';
import { fetchMe } from 'services/user';

import { isAxiosError } from 'axios';
import { IMe, IMeOptions, IObject } from 'types/api-interfaces';

/** UI context interface: controls the sate of the UI */
interface IUi {
  /** The schema definition of a selected component in the application */
  xsdDoc?: Document;

  savedObject?: IObject;
}

type IUiOptions = Partial<IUi>;

const defaultUI: IUi = {
  xsdDoc: undefined,
  savedObject: undefined,
};

export interface IAppContext {
  me: IMe;
  ui: IUi;
  updateMe: (me: IMeOptions) => void;
  updateUI: (ui: IUiOptions, callback?: () => void) => void;
  initXSD: () => void;
}

export const AppContext = React.createContext({} as IAppContext);

interface IAppContextProviderProps {
  children: ReactNode;
}

interface IAppContextProviderState {
  shouldRender: boolean;
  me: IMe;
  ui: IUi;
}

function AppContextProviderView(props: IAppContextProviderProps) {
  const { children } = props;
  const [state, setState] = useState<IAppContextProviderState>({
    shouldRender: false,
    me: {} as IMe,
    ui: defaultUI,
  });

  const updateUI = useCallback((ui: IUiOptions, callback?: () => void) => {
    setState(
      (prevState) => ({
        ...prevState,
        ui: { ...prevState.ui, ...ui },
      }),
      // callback
    );
    if (callback) callback();
  }, []);

  // TODO: Rewrite this to use React Query
  const initXSD = useCallback(async () => {
    const xsdDoc = await fetchComponentXSD();
    updateUI({ xsdDoc });
  }, [updateUI]);

  // TODO: Rewrite this to use React Query
  useEffect(() => {
    const componentDidMount = async () => {
      try {
        const me = await fetchMe();
        setState((prevState) => ({
          ...prevState,
          shouldRender: true,
          me: { ...me, organization: undefined },
        }));
        initXSD();

        // set globals
        GLOBALS.userId = me.id;
        GLOBALS.region = me.preferred_region_city;
      } catch (error: unknown) {
        if (isAxiosError(error)) {
          message.error(error.message);
        }
      }
    };

    componentDidMount();
  }, [initXSD]);

  const updateMe = useCallback((me: IMeOptions) => {
    setState((prevState) => ({
      ...prevState,
      me: { ...prevState.me, ...me },
    }));
  }, []);

  const providerValue = useMemo(() => {
    return {
      me: state.me,
      updateMe: (me: IMeOptions) => updateMe(me),
      ui: state.ui,
      updateUI: (ui: IUiOptions) => updateUI(ui),
      initXSD: () => initXSD(),
    };
  }, [initXSD, state.me, state.ui, updateMe, updateUI]);

  return state.shouldRender ? (
    <AppContext.Provider value={providerValue}>{children}</AppContext.Provider>
  ) : (
    <LoadingPage />
  );
}

export function AppContextProvider(props: { children: ReactNode }) {
  const { children } = props;
  return <AppContextProviderView>{children}</AppContextProviderView>;
}
