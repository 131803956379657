import { ILibrary } from 'types/api-interfaces';
import React, { ReactNode, useMemo } from 'react';

interface IListItemContext {
  library: ILibrary;
}

interface IListItemProviderProps {
  children: ReactNode;
  library: ILibrary;
}

export const ListItemContext = React.createContext<IListItemContext>({} as IListItemContext);

function ListItemProvider(props: IListItemProviderProps) {
  const { library, children } = props;

  const providerValue = useMemo(() => {
    return {
      library,
    };
  }, [library]);

  return <ListItemContext.Provider value={providerValue}>{children}</ListItemContext.Provider>;
}

export default ListItemProvider;
