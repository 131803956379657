import { ElementSpec } from '@codemirror/lang-xml';
import { nameAttribute, publicAttribute } from '../../attributes';
import { originElement } from '../shared';
import applyForceElements, { applyForceElement } from './apply_force';
import audioElements, { audioElement } from './audio';
import collisionElements, { collisionElement } from './collision';
import forceSourceElements, { forceSourceElement } from './force_source';
import inertialElements, { inertialElement } from './inertial';
import lockerElements, { lockerElement } from './locker';
import sensorElements, { sensorElement } from './sensor';
import surfaceElements, {
  dataSurfaceElement,
  motionSurfaceElement,
  surfaceElement,
} from './surface';
import visualElements, { visualElement } from './visual';

const isKinematicElement: ElementSpec = {
  name: 'is_kinematic',
  attributes: [publicAttribute],
  textContent: ['true', 'false'],
};

export const linkElement: ElementSpec = {
  name: 'link',
  attributes: [nameAttribute],
  children: [
    originElement.name,
    inertialElement.name,
    visualElement.name,
    sensorElement.name,
    forceSourceElement.name,
    lockerElement.name,
    collisionElement.name,
    surfaceElement.name,
    motionSurfaceElement.name,
    dataSurfaceElement.name,
    audioElement.name,
    applyForceElement.name,
    isKinematicElement.name,
  ],
};

export const baseLinkElement: ElementSpec = {
  name: 'base_link',
  attributes: [nameAttribute],
  children: linkElement.children,
};

export default [
  baseLinkElement,
  linkElement,
  isKinematicElement,
  ...inertialElements,
  ...visualElements,
  ...sensorElements,
  ...forceSourceElements,
  ...lockerElements,
  ...collisionElements,
  ...surfaceElements,
  ...audioElements,
  ...applyForceElements,
];
