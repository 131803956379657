import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface BehaviorData {
  guid: string;
  script: string;
}

interface State {
  behaviorUnsaved: BehaviorData | undefined;
  hasUnsavedMetaData: boolean;
  hasUnsavedXML: boolean;
  hasUnsavedBehavior: boolean;
}

interface Actions {
  setBehaviorUnsaved: (value: BehaviorData | undefined) => void;
  setHasUnsavedMetaData: (value: boolean) => void;
  setHasUnsavedXML: (value: boolean) => void;
  setHasUnsavedBehavior: (value: boolean) => void;
  resetStore: () => void;
}

const initialState: State = {
  behaviorUnsaved: undefined,
  hasUnsavedBehavior: false,
  hasUnsavedMetaData: false,
  hasUnsavedXML: false,
};

export const useObjectPageStore = create(
  devtools<State & Actions>(
    (set) => ({
      ...initialState,
      setBehaviorUnsaved: (behaviorUnsaved) => set({ behaviorUnsaved }),
      setHasUnsavedBehavior: (hasUnsavedBehavior) => set({ hasUnsavedBehavior }),
      setHasUnsavedMetaData: (hasUnsavedMetaData) => set({ hasUnsavedMetaData }),
      setHasUnsavedXML: (hasUnsavedXML) => set({ hasUnsavedXML }),
      resetStore: () => set({ ...initialState }),
    }),
    {
      name: 'Object Page',
      enabled: import.meta.env.DEV,
    },
  ),
);

export const useBehaviorUnsaved = () => {
  const behaviorUnsaved = useObjectPageStore((state) => state.behaviorUnsaved);
  const setBehaviorUnsaved = useObjectPageStore((state) => state.setBehaviorUnsaved);

  return { behaviorUnsaved, setBehaviorUnsaved };
};

export const useHasUnsavedXML = () => {
  const hasUnsavedXML = useObjectPageStore((state) => state.hasUnsavedXML);
  const setHasUnsavedXML = useObjectPageStore((state) => state.setHasUnsavedXML);

  const registerUnsavedXML = () => {
    if (!hasUnsavedXML) setHasUnsavedXML(true);
  };

  return { hasUnsavedXML, setHasUnsavedXML, registerUnsavedXML };
};

export const useHasUnsavedBehavior = () => {
  const hasUnsavedBehavior = useObjectPageStore((state) => state.hasUnsavedBehavior);
  const setHasUnsavedBehavior = useObjectPageStore((state) => state.setHasUnsavedBehavior);

  const registerUnsavedBehavior = () => {
    if (!hasUnsavedBehavior) setHasUnsavedBehavior(true);
  };

  return { hasUnsavedBehavior, setHasUnsavedBehavior, registerUnsavedBehavior };
};

export const useHasUnsavedMetaData = () => {
  const hasUnsavedMetaData = useObjectPageStore((state) => state.hasUnsavedMetaData);
  const setHasUnsavedMetaData = useObjectPageStore((state) => state.setHasUnsavedMetaData);

  const registerUnsavedMetaData = () => {
    if (!hasUnsavedMetaData) setHasUnsavedMetaData(true);
  };

  return { hasUnsavedMetaData, setHasUnsavedMetaData, registerUnsavedMetaData };
};

export const useUnsavedChanges = () => {
  const hasUnsavedBehavior = useObjectPageStore((state) => state.hasUnsavedBehavior);
  const hasUnsavedMetaData = useObjectPageStore((state) => state.hasUnsavedMetaData);
  const hasUnsavedXML = useObjectPageStore((state) => state.hasUnsavedXML);
  const setHasUnsavedBehavior = useObjectPageStore((state) => state.setHasUnsavedBehavior);
  const setHasUnsavedMetaData = useObjectPageStore((state) => state.setHasUnsavedMetaData);
  const setHasUnsavedXML = useObjectPageStore((state) => state.setHasUnsavedXML);

  const hasUnsavedChanges = hasUnsavedBehavior || hasUnsavedMetaData || hasUnsavedXML;

  const clearUnsavedChanges = () => {
    setHasUnsavedBehavior(false);
    setHasUnsavedMetaData(false);
    setHasUnsavedXML(false);
  };

  return {
    hasUnsavedChanges,
    hasUnsavedBehavior,
    hasUnsavedMetaData,
    hasUnsavedXML,
    clearUnsavedChanges,
  };
};
