import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

function LoadingSpinner(props: { size?: number }) {
  const { size } = props;
  return <Spin indicator={<LoadingOutlined style={{ fontSize: size }} />} />;
}

LoadingSpinner.defaultProps = {
  size: 24,
};

export default LoadingSpinner;
