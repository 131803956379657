import { ElementSpec } from '@codemirror/lang-xml';
import { nameAttribute, publicAttribute } from '../attributes';
import { enableElement } from './shared';

const typeElement: ElementSpec = {
  name: 'type',
  textContent: [
    'allenbradley_logix',
    'abb_driver',
    'cprog_cri',
    'development',
    'fanuc_roboguide',
    'hokuyo_uam',
    'micro800_http',
    'modbustcp_master',
    'mqtt_client',
    'opcda_client',
    'opcua_client',
    'plcsim',
    'robodk_driver',
    'rosbridge',
    's7protocol',
    'simit',
    'simulink_udp',
    'sqlite3_conn',
    'twincat_ads',
    'udp_driver',
    'ur_driver',
  ],
  children: [],
};

const setupDataElement: ElementSpec = {
  name: 'setup_data',
  attributes: [publicAttribute],
  children: [],
};

const updateInputElement: ElementSpec = {
  name: 'update_input',
  attributes: [publicAttribute],
  children: [],
};

const statusElement: ElementSpec = {
  name: 'status',
  attributes: [publicAttribute],
  children: [],
};

const infoElement: ElementSpec = {
  name: 'info',
  attributes: [publicAttribute],
  children: [],
};

const updateOutputElement: ElementSpec = {
  name: 'update_output',
  attributes: [publicAttribute],
  children: [],
};

export const communicationDriverElement: ElementSpec = {
  name: 'communication_driver',
  attributes: [nameAttribute],
  children: [
    typeElement.name,
    enableElement.name,
    setupDataElement.name,
    updateInputElement.name,
    statusElement.name,
    infoElement.name,
    updateOutputElement.name,
  ],
};

export default [
  communicationDriverElement,
  typeElement,
  setupDataElement,
  updateInputElement,
  statusElement,
  infoElement,
  updateOutputElement,
];
