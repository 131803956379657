import { ElementSpec } from '@codemirror/lang-xml';
import { nameAttribute } from '../../attributes';
import { modeElement, torqueElement, valueElement } from '../shared';
import baseChildren from './base';

const children = [...baseChildren, modeElement.name, valueElement.name, torqueElement.name];

export const mechanicalRotationInputElement: ElementSpec = {
  name: 'mechanical_rotation_input',
  attributes: [nameAttribute],
  children,
};

export const mechanicalRotationOutputElement: ElementSpec = {
  name: 'mechanical_rotation_output',
  attributes: [nameAttribute],
  children,
};

export default [mechanicalRotationInputElement, mechanicalRotationOutputElement];
