import { ISystem } from 'types/api-interfaces';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { SystemScope } from '../types';

type State = {
  selectedSystems: ISystem[];
  systemSort: string;
  sortDirection: string;
  scope: SystemScope;
};

type Actions = {
  setSelectedSystems: (systems: ISystem[]) => void;
  reset: () => void;
  addSystemSelection: (system: ISystem) => void;
  removeSystemSelection: (system: ISystem) => void;
  setSystemSort: (sort: string) => void;
  setSortDirection: (sort: string) => void;
  setScope: (scope: SystemScope) => void;
};

const initialState: State = {
  selectedSystems: [],
  systemSort: 'name',
  sortDirection: 'ascending',
  scope: 'private',
};

const useSystemBrowserStore = create(
  devtools<State & Actions>(
    (set, get) => ({
      ...initialState,
      setSelectedSystems: (selectedSystems) => set({ selectedSystems }),
      reset: () => set({ selectedSystems: [] }),
      addSystemSelection: (system) => set({ selectedSystems: [...get().selectedSystems, system] }),
      removeSystemSelection: (system) =>
        set({ selectedSystems: [...get().selectedSystems.filter(({ id }) => id !== system.id)] }),
      setSystemSort: (systemSort) => {
        set({ selectedSystems: [], systemSort });
      },
      setSortDirection: (sortDirection) => {
        set({ selectedSystems: [], sortDirection });
      },
      setScope: (scope) => set({ scope }),
    }),
    { name: 'Systems Browser', enabled: import.meta.env.DEV },
  ),
);

export default useSystemBrowserStore;
