import { MenuProps, Radio, RadioChangeEvent } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useCallback, useEffect, useState } from 'react';
import { ComplexType, ComplexTypeContent } from 'services';
import { NodeName } from 'simumatik-commons';

interface MenuItemsProps {
  filter: string[];
  existingNodes: string[];
  updateDataNode: (value: ComplexTypeContent, choices?: string[]) => void;
}
type MenuItems = (props: MenuItemsProps) => ItemType[];

export function useTreeMenu(types: Map<string, ComplexType>) {
  const [menus] = useState<Map<string, MenuItems>>(new Map<string, MenuItems>());

  const getTreeMenu = useCallback(
    (
      typeName: string,
      filter: string[],
      existingNodes: string[],
      updateDataNode: (value: ComplexTypeContent, choices?: string[]) => void,
    ): MenuProps => {
      const menuItems = menus.get(typeName);

      if (!menuItems)
        return {
          items: [],
        };

      const filteredMenuItems = menuItems({
        filter,
        existingNodes,
        updateDataNode,
      });

      const outProps: MenuProps = {
        items: filteredMenuItems,
      };

      return outProps;
    },
    [menus],
  );

  useEffect(() => {
    const filterNodeToBeHidden = (content: ComplexTypeContent) => {
      return content.name !== NodeName.Connection;
    };

    const createMenuItemCollection = (complexType: ComplexType, key: string) => {
      menus.set(key, (props: MenuItemsProps) => {
        return complexType.contents
          .filter(filterNodeToBeHidden)
          .map((content: ComplexTypeContent) => {
            if (content.choices) {
              return {
                key: content.name,
                label: (
                  <ChoiceItemsGroup
                    choices={content.choices}
                    selectedOption={content.choices.find((choice) =>
                      props.existingNodes.includes(choice.name),
                    )}
                    update={props.updateDataNode}
                  />
                ),
              };
            }
            return {
              key: content.name,
              disabled: props.filter.includes(content.name),
              onClick: () => props.updateDataNode(content),
              label: content.name,
            };
          });
      });
    };
    types.forEach(createMenuItemCollection);
  }, [types, menus]);

  return {
    getTreeMenu,
  };
}

interface ChoiceItemsGroupProps {
  choices: ComplexTypeContent[];
  selectedOption?: ComplexTypeContent;
  update: (value: ComplexTypeContent, choices: string[]) => void;
}

function useChoiceItemsGroup(
  choices: ComplexTypeContent[],
  update: (value: ComplexTypeContent, choices: string[]) => void,
) {
  const onChange = useCallback(
    (event: RadioChangeEvent) => {
      // call add function
      update(
        event.target.value,
        choices.map((choice) => choice.name),
      );
    },
    [choices, update],
  );

  return {
    onChange,
  };
}

function ChoiceItemsGroup({ choices, update, selectedOption }: ChoiceItemsGroupProps) {
  const { onChange } = useChoiceItemsGroup(choices, update);
  return (
    <Radio.Group onChange={onChange} value={selectedOption}>
      {[...choices].map((choice: ComplexTypeContent) => {
        return (
          <Radio key={choice.name} style={{ display: 'block' }} value={choice}>
            {choice.name}
          </Radio>
        );
      })}
    </Radio.Group>
  );
}

ChoiceItemsGroup.defaultProps = {
  selectedOption: undefined,
};

export default useTreeMenu;
