import { Image, List, Typography } from 'antd';
import { ObjectInfoModal } from 'components/ObjectInfoModal';
import PublishStatusTag from 'components/PublishStatusTag';
import { ComponentLibraryBrowserContext } from 'providers/ComponentLibraryBrowserProvider';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IComponent } from 'types/api-interfaces';
import { thumbnailPlaceholderUrl } from 'utils/environmentVariables';
import AddToLibrary from './actions/AddToLibrary';

const { Meta } = List.Item;
const { Text } = Typography;

interface IListItemProps {
  component: IComponent;
}

function ListItem(props: IListItemProps) {
  const { component } = props;
  const { mode } = useContext(ComponentLibraryBrowserContext);
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);

  const handleClick = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleModalClickOpen = () => {
    history.push(`/component/${component.id}`, { objInfocus: component });
  };

  return (
    <>
      <List.Item
        className="hover:cursor-pointer hover:bg-black/5"
        actions={mode === 'add' ? [<AddToLibrary component={component} key="addToLibrary" />] : []}
        onClick={handleClick}
      >
        <Meta
          avatar={
            <Image
              width={90}
              height="auto"
              src={component.thumbnail_url || 'error'}
              fallback={thumbnailPlaceholderUrl}
              preview={false}
            />
          }
          title={
            <div className="flex w-full flex-row justify-between">
              <Text ellipsis className="w-[10vw]">
                {component.name}
              </Text>
              <PublishStatusTag object={component} />
            </div>
          }
          description={
            <div className="flex w-full flex-col justify-between">
              <span>Owner: {component.owner_name}</span>
              <span>Last modified: {new Date(component.modified).toLocaleDateString()}</span>
            </div>
          }
        />
      </List.Item>
      <ObjectInfoModal
        id={component.id}
        type="component"
        open={showModal}
        onClose={handleModalClose}
        onClickOpen={handleModalClickOpen}
      />
    </>
  );
}

export default ListItem;
