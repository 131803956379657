import { Form, Input, Modal } from 'antd';
import React from 'react';
import { ILibrary } from 'types/api-interfaces';
import { useLibraryState } from './hooks/useLibraryState';

interface IForm {
  name: string;
}

type RenameModalProps = {
  library: ILibrary;
  open: boolean;
  onCancel: () => void;
  onFinish: () => void;
};

function RenameModal({ library, open, onFinish, onCancel }: RenameModalProps) {
  const [form] = Form.useForm<IForm>();
  const { patch } = useLibraryState();

  const handleOk = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    form.submit();
  };

  const handleCancel = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    onCancel();
  };

  const handleFinish = async ({ name }: IForm) => {
    await patch.mutateAsync({
      id: library.id,
      payload: {
        name,
      },
    });
    onFinish();
  };

  const handleClickOutside = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div onClick={handleClickOutside}>
      <Modal
        title="Rename Library"
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose
      >
        <Form form={form} preserve={false} onFinish={handleFinish}>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                min: 3,
                whitespace: true,
                message: 'Library name must contain at least 3 characters',
              },
            ]}
            initialValue={library.name}
          >
            <Input onClick={(e) => e.stopPropagation()} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default RenameModal;
