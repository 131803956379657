export enum FeatureFlags {
  OptimizeAssets = 'optimize-assets',
  ConvertAssets = 'convert-assets',
  ExternalGateway = 'external-gateway',
  NewWorkspace = 'new-workspace',
  MinWorkspaceVersion = 'min-workspace-version',
  SelectWorkspaceRegion = 'select-workspace-region',
  MonacoEditor = 'monaco-editor',
  FlipSymbols = 'flip-symbols',
  ProductExitDebug = 'product-exit-debug',
  VR = 'vr-enabled',
  WorkspaceSaveAs = 'workspace-save-as',
  WorkspaceAutosave = 'workspace-autosave',
  WorkspaceUndoRedo = 'workspace-undo-redo',
  WorkspaceImportExport = 'workspace-import-export',
  WebsocketRefactor = 'websocket-new-response-handler',
  DevTools = 'devtools',
  NewVariablesPanel = 'new-variables-panel',
}
