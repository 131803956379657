import { ElementSpec } from '@codemirror/lang-xml';
import { publicAttribute } from '../../attributes';

const playElement: ElementSpec = {
  name: 'play',
  attributes: [publicAttribute],
  textContent: ['true', 'false'],
  children: [],
};

const fileElement: ElementSpec = {
  name: 'file',
  children: [],
};

const loopElement: ElementSpec = {
  name: 'loop',
  attributes: [publicAttribute],
  textContent: ['true', 'false'],
  children: [],
};

const refDistanceElement: ElementSpec = {
  name: 'ref_distance',
  attributes: [publicAttribute],
  children: [],
};

const maxDistanceElement: ElementSpec = {
  name: 'max_distance',
  attributes: [publicAttribute],
  children: [],
};

export const audioElement: ElementSpec = {
  name: 'audio',
  children: [
    playElement.name,
    fileElement.name,
    loopElement.name,
    refDistanceElement.name,
    maxDistanceElement.name,
  ],
};

export default [
  audioElement,
  playElement,
  fileElement,
  loopElement,
  refDistanceElement,
  maxDistanceElement,
];
