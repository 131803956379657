import { useContext, useEffect, useState } from 'react';
import { LocalServicesContext } from '../../../../providers/LocalServicesProvider';

export enum LauncherStatuses {
  Installed,
  UpdateAvailable,
  NotInstalled,
  Loading,
}

export default function useLauncherStatus() {
  const { serviceLauncher } = useContext(LocalServicesContext);
  const { isLauncherAvailable, launcherVersion, latestVersion, updateLauncher } = serviceLauncher;
  const [launcherStatus, setLauncherStatus] = useState<LauncherStatuses>(LauncherStatuses.Loading);

  // update message
  useEffect(() => {
    if (!isLauncherAvailable) {
      setLauncherStatus(LauncherStatuses.NotInstalled);
      return;
    }

    if (!launcherVersion) {
      setLauncherStatus(LauncherStatuses.Loading);
      return;
    }

    if (latestVersion && launcherVersion !== latestVersion) {
      setLauncherStatus(LauncherStatuses.UpdateAvailable);
      return;
    }

    setLauncherStatus(LauncherStatuses.Installed);
  }, [isLauncherAvailable, launcherVersion, latestVersion]);

  return {
    launcherVersion,
    launcherStatus,
    update: updateLauncher,
    latestVersion,
  };
}
