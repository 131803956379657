import { Switch, Tooltip } from 'antd';
import { useOrganization } from 'hooks/useOrganization';
import { useContext } from 'react';
import { ListItemContext } from '../ListItemProvider';
import { useLibraryState } from './hooks/useLibraryState';

function TogglePublic() {
  const { organization } = useOrganization();
  const { patch } = useLibraryState();
  const { library } = useContext(ListItemContext);

  const handleChange = async (checked: boolean) => {
    patch.mutate({ id: library.id, payload: { public: checked } });
  };

  return (
    <Tooltip
      title={
        !organization?.is_admin
          ? ''
          : 'Toggle library visibility to users outside this organization'
      }
    >
      <Switch
        checked={library.public}
        checkedChildren="Public"
        unCheckedChildren="Private"
        onChange={handleChange}
        disabled={!organization?.is_admin}
        onClick={(_, e) => e.stopPropagation()}
        loading={patch.isLoading}
      />
    </Tooltip>
  );
}

export default TogglePublic;
