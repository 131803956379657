import { useState } from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import tailwindColors from 'tailwindcss/colors';

export interface BytesPerSecondChartProps {
  data: { sent: number; received: number }[];
}

export function BytesPerSecondChart({ data }: BytesPerSecondChartProps) {
  const [maxValue] = useState<number>(0);

  const chartData = data.map(({ sent, received }, index) => ({
    name: String(index + 1),
    sent,
    received,
  }));

  return (
    <div>
      <h2>Average Bytes/Second</h2>
      <ResponsiveContainer>
        <AreaChart data={chartData}>
          <defs>
            <linearGradient id="sent" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={tailwindColors.red[500]} stopOpacity={0.8} />
              <stop offset="95%" stopColor={tailwindColors.red[500]} stopOpacity={0} />
            </linearGradient>
            <linearGradient id="received" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={tailwindColors.green[500]} stopOpacity={0.8} />
              <stop offset="95%" stopColor={tailwindColors.green[500]} stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis tickLine={false} />
          <YAxis
            tickLine={false}
            orientation="right"
            label={{ value: 'Bytes/Second', angle: -90, position: 'insideLeft' }}
          />
          <Area
            type="monotone"
            dataKey="sent"
            stroke={tailwindColors.red[500]}
            fillOpacity={1}
            fill="url(#sent)"
            name="Sent"
          />
          <Area
            type="monotone"
            dataKey="received"
            stroke={tailwindColors.green[500]}
            fillOpacity={1}
            fill="url(#received)"
            name="Received"
          />
          <CartesianGrid strokeWidth="1" opacity={0.5} />
          <Legend />
          {maxValue > 0 ? (
            <ReferenceLine label="Max" y={maxValue} stroke="red" strokeDasharray="3 3" />
          ) : null}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}
