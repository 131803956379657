import { useMemo } from 'react';
import { IAuthorizeable, Plan } from 'types/api-interfaces';
import { useOrganization } from './useOrganization';
import { useUser } from './useUser';

/**
 * Hook for checking the current user's permissions for a specific component/system
 */
function usePermissions(object: IAuthorizeable | undefined) {
  const { me } = useUser();
  const { organization } = useOrganization();

  const canModify = useMemo(() => {
    // Check if the user's organization is the owner of the object
    if (organization) {
      // This is someone other organization's object
      if (object?.owner_id !== organization.id) return false;
      // Admin = godmode
      if (organization.is_admin) return true;
      // I am the creator and I have at least Creator role in my organization
      if (object?.creator_id === me.id && organization.can_create) return true;
      // I am not the creator, but the object has been shared with me in Editor mode
      if (object?.share_mode === 'editor') return true;
      // None of the above
      return false;
    }

    // I am not in an organization

    // Basic plan = can't do shit
    if (me.plan === Plan.Basic) return false;
    // I am the owner of this object
    if (object?.owner_id === me.id) return true;
    // None of the above - no permission
    return false;
  }, [me, object, organization]);

  const canPublish = useMemo(() => {
    // Check if the user's organization is the owner of the object
    if (organization) {
      // This is someone other organization's object
      if (object?.owner_id !== organization.id) return false;
      // Only admins can publish
      if (organization.is_admin) return true;
      // None of the above
      return false;
    }

    // I am not in an organization

    // Basic plan = can't do shit
    if (me.plan === Plan.Basic) return false;
    // I am the owner of this object
    if (object?.owner_id === me.id) return true;
    // None of the above - no permission
    return false;
  }, [me, object, organization]);

  /**
   * Returns true if the current user can share the specified object.
   * Will return false if the user is not in an organization.
   */
  const canShare = useMemo(() => {
    // Check if the user's organization is the owner of the object
    if (organization) {
      // This is someone other organization's object
      if (object?.owner_id !== organization.id) return false;
      // Admin = godmode
      if (organization.is_admin) return true;
      // I am the creator
      if (object?.creator_id === me.id) return true;
    }

    return false;
  }, [me, object, organization]);

  return { canModify, canPublish, canShare };
}

export default usePermissions;
