import { useContext } from 'react';
import { ComponentEditorContext } from '../providers/ComponentEditorProvider';

const useComponentEditor = () => {
  const { component, isSceneLoaded, setIsSceneLoaded, behaviorGuid, setBehaviorGuid, canModify } =
    useContext(ComponentEditorContext);

  const updateBehaviorId = (value: string) => {
    setBehaviorGuid(value);
  };

  return {
    component,
    isSceneLoaded,
    behaviorGuid,
    setIsSceneLoaded,
    updateBehaviorId,
    canModify,
  };
};

export default useComponentEditor;
