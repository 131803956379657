import { ElementSpec } from '@codemirror/lang-xml';
import linkElements, { baseLinkElement, linkElement } from './link';
import symbolElements, { symbolElement } from './symbol';
import sharedElements from './shared';
import behaviorElements, { behaviorElement } from './behavior';
import communicationDriverElements, { communicationDriverElement } from './communication_driver';
import portElements, {
  electricInputElement,
  electricOutputElement,
  hydraulicInputElement,
  hydraulicOutputElement,
  mechanicalRotationInputElement,
  mechanicalRotationOutputElement,
  mechanicalTranslationInputElement,
  mechanicalTranslationOutputElement,
  pneumaticInputElement,
  pneumaticOutputElement,
  serialInputElement,
  serialOutputElement,
} from './ports';
import jointElements, { jointElement } from './joint';
import connectionElements, { connectionElement } from './connection';

const componentElement: ElementSpec = {
  name: 'component',
  children: [
    symbolElement.name,
    baseLinkElement.name,
    linkElement.name,
    jointElement.name,
    behaviorElement.name,
    electricInputElement.name,
    electricOutputElement.name,
    pneumaticInputElement.name,
    pneumaticOutputElement.name,
    hydraulicInputElement.name,
    hydraulicOutputElement.name,
    mechanicalRotationInputElement.name,
    mechanicalRotationOutputElement.name,
    mechanicalTranslationInputElement.name,
    mechanicalTranslationOutputElement.name,
    serialInputElement.name,
    serialOutputElement.name,
    communicationDriverElement.name,
    connectionElement.name,
  ],
};

export default [
  componentElement,
  ...sharedElements,
  ...symbolElements,
  ...linkElements,
  ...jointElements,
  ...behaviorElements,
  ...portElements,
  ...communicationDriverElements,
  ...connectionElements,
];
