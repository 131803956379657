import { ElementSpec } from '@codemirror/lang-xml';
import { publicAttribute } from '../../../attributes';
import { heightElement, widthElement } from '../../shared/generic';

const verticalFovElement: ElementSpec = {
  name: 'vertical_fov',
  attributes: [publicAttribute],
  children: [],
};

const nearElement: ElementSpec = {
  name: 'near',
  attributes: [publicAttribute],
  children: [],
};

const farElement: ElementSpec = {
  name: 'far',
  attributes: [publicAttribute],
  children: [],
};

const formatElement: ElementSpec = {
  name: 'format',
  textContent: ['RGB', 'L', 'D', 'RGBD'],
  children: [],
};

const renderModeElement: ElementSpec = {
  name: 'render_mode',
  textContent: ['collision', 'visual'],
  children: [],
};

const outputModeElement: ElementSpec = {
  name: 'output_mode',
  textContent: ['stream', 'data'],
  children: [],
};

const outputDataElement: ElementSpec = {
  name: 'output_data',
  attributes: [publicAttribute],
  children: [],
};

export const cameraElement: ElementSpec = {
  name: 'camera',
  children: [
    widthElement.name,
    heightElement.name,
    verticalFovElement.name,
    nearElement.name,
    farElement.name,
    formatElement.name,
    renderModeElement.name,
    outputModeElement.name,
    outputDataElement.name,
  ],
};

export default [
  cameraElement,
  verticalFovElement,
  nearElement,
  farElement,
  formatElement,
  renderModeElement,
  outputDataElement,
  outputModeElement,
];
