import useUpdateEffect from 'hooks/utils/useUpdateEffect';
import React, { ReactNode, useMemo, useState } from 'react';
import { ILibrary } from 'types/api-interfaces';
import { useLibraries } from './hooks/useLibraries';
import { LibraryScope } from './types';

interface ILibraryBrowserContext {
  libraries: ILibrary[];
  scope: LibraryScope;
  setScope: (value: LibraryScope) => void;
  searchTerm: string | null;
  setSearchTerm: (value: string | null) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}

interface ILibraryBrowserProviderProps {
  children: ReactNode;
}

export const LibraryBrowserContext = React.createContext<ILibraryBrowserContext>(
  {} as ILibraryBrowserContext,
);

export function LibraryBrowserProvider({ children }: ILibraryBrowserProviderProps) {
  const [scope, setScope] = useState<LibraryScope>('private');
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const { libraries } = useLibraries(scope);

  // Reset page number on scope change
  useUpdateEffect(() => {
    setCurrentPage(1);
  }, [scope]);

  const providerValue = useMemo(
    () => ({
      libraries,
      scope,
      setScope,
      searchTerm,
      setSearchTerm,
      currentPage,
      setCurrentPage,
    }),
    [currentPage, libraries, scope, searchTerm],
  );

  return (
    <LibraryBrowserContext.Provider value={providerValue}>
      {children}
    </LibraryBrowserContext.Provider>
  );
}
