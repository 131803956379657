import { ElementSpec } from '@codemirror/lang-xml';
import { nameAttribute } from '../../attributes';
import { flowElement, pressureElement } from '../shared';
import baseChildren from './base';

const children = [...baseChildren, pressureElement.name, flowElement.name];

export const pneumaticInputElement: ElementSpec = {
  name: 'pneumatic_input',
  attributes: [nameAttribute],
  children,
};

export const pneumaticOutputElement: ElementSpec = {
  name: 'pneumatic_output',
  attributes: [nameAttribute],
  children,
};

export default [pneumaticInputElement, pneumaticOutputElement];
