import { ElementSpec } from '@codemirror/lang-xml';
import { nameAttribute, publicAttribute } from '../../attributes';
import { axisElement, originElement } from '../shared';
import dynamicsElements, { dynamicsElement } from './dynamics';
import jointMotorElements, { jointMotorElement } from './joint_motor';
import jointSensorElements, { jointSensorElement } from './joint_sensor';
import limitsElements, { limitsElement } from './limits';

const parentElement: ElementSpec = {
  name: 'parent',
  attributes: [publicAttribute],
  children: [],
};

const childElement: ElementSpec = {
  name: 'child',
  attributes: [publicAttribute],
  children: [],
};

const initialPositionElement: ElementSpec = {
  name: 'initial_position',
  attributes: [publicAttribute],
  children: [],
};

const overrideIterationsElement: ElementSpec = {
  name: 'override_iterations',
  attributes: [publicAttribute],
  children: [],
};

export const jointElement: ElementSpec = {
  name: 'joint',
  attributes: [nameAttribute],
  children: [
    originElement.name,
    parentElement.name,
    childElement.name,
    axisElement.name,
    initialPositionElement.name,
    dynamicsElement.name,
    limitsElement.name,
    jointSensorElement.name,
    jointMotorElement.name,
    overrideIterationsElement.name,
  ],
};

export default [
  jointElement,
  parentElement,
  childElement,
  initialPositionElement,
  overrideIterationsElement,
  ...dynamicsElements,
  ...jointSensorElements,
  ...jointMotorElements,
  ...limitsElements,
];
