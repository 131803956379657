import { SmtkDataNode } from 'features/ComponentPage/ComponentEditor/SmtkDataNode';
import { EditorScene, Object3dFactory, ShapeName, SmtkGroup, TDName } from 'simumatik-3d';
import { Node, NodeClass, NodeName, Ports, toNodeId } from 'simumatik-commons';
import { nodeInfo } from 'utils/xsd';

export async function addTo3DScene(smtkObject3D: SmtkGroup | null) {
  // Add 3D objects to scene
  if (EditorScene.isDefined && smtkObject3D) {
    EditorScene.instance.clean();
    EditorScene.instance.remove3DObject(smtkObject3D.name);
    await EditorScene.instance.add3DObject(smtkObject3D, TDName.Workspace);
  }
}

export function appendTo3DScene(smtkObject3D: SmtkGroup | null, dataNode: SmtkDataNode) {
  if (EditorScene.isDefined && smtkObject3D) {
    smtkObject3D.name = dataNode.key.toString();
    EditorScene.instance.remove3DObject(smtkObject3D.name);
    EditorScene.instance.add3DObject(smtkObject3D, `${dataNode.parentNode?.key}`);
  }
}

export function refresh3D() {
  EditorScene.instance.onWindowResize();
}

export const convertToObject3D = async (
  dataNode: SmtkDataNode,
  xsdDoc: Document,
  showCollisions: boolean = false,
) => {
  if (['color', 'texture'].includes(dataNode.localName) && dataNode.parentNode) {
    dataNode = dataNode.parentNode;
  }

  // do this only to visual elements
  if (
    [
      'base_link',
      NodeName.Link.toString(),
      NodeName.Visual.toString(),
      NodeName.Light.toString(),
      NodeName.Collision.toString(),
      NodeName.Material.toString(),
      ShapeName.BOX.toString(),
      ShapeName.BOX2D.toString(),
      ShapeName.CAPSULE.toString(),
      ShapeName.CYLINDER.toString(),
      ShapeName.SPHERE.toString(),
      ShapeName.TEXT.toString(),
      NodeName.Mesh.toString(),
      NodeName.Geometry.toString(),
      NodeName.Sensor.toString(),
      NodeName.Locker.toString(),
      ...Ports,
    ].includes(dataNode.localName)
  ) {
    // find the link/visual/collision parent element
    while (
      dataNode &&
      ![
        'base_link',
        `${NodeName.Link}`,
        `${NodeName.Visual}`,
        `${NodeName.Light}`,
        `${NodeName.Collision}`,
        `${NodeName.Sensor}`,
        `${NodeName.Locker}`,
        ...Ports,
      ].includes(dataNode.localName)
    ) {
      if (dataNode.parentNode) {
        dataNode = dataNode.parentNode;
      } else {
        break;
      }
    }
    if (!dataNode || !xsdDoc) {
      return null;
    }
    return dataNodeTo3d(dataNode, xsdDoc, showCollisions);
  }
  return null;
};

export function removeFrom3DScene(name: string) {
  EditorScene.instance.remove3DObject(name);
}

export async function dataNodeTo3d(dataNode: SmtkDataNode, xsdDoc: Document, collision?: boolean) {
  const nodes: Node[] = [];

  const traverseXML = (dataNode: SmtkDataNode, xsdDoc: Document) => {
    const info = nodeInfo(dataNode.element, xsdDoc);
    const node: Node = {
      class: NodeClass.Object,
      nodeId: toNodeId(dataNode.key),
      parent: toNodeId(Number(dataNode.parentNode?.key)),
      browseName: dataNode.localName,
      displayName: dataNode.localName,
      description: '',
      value: info.smtkObject ? info.smtkObject.value : null,
      typeDefinition: dataNode.localName === 'base_link' ? 'link' : dataNode.localName,
    };
    nodes.push(node);
    [...dataNode.children].forEach((childDataNode) => {
      traverseXML(childDataNode, xsdDoc);
    });
  };
  traverseXML(dataNode, xsdDoc);

  const objects = nodes.filter((node) => node.value === null);
  const variables = nodes.filter((node) => node.value !== null);

  return Object3dFactory.createComponent(nodes[0], objects, variables, true, undefined, collision);
}
