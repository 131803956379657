import { ElementSpec } from '@codemirror/lang-xml';
import { nameAttribute } from '../attributes';
import { sizeElement } from './shared/generic';

export const imageElement: ElementSpec = {
  name: 'image',
  children: [],
};

export const symbolElement: ElementSpec = {
  name: 'symbol',
  attributes: [nameAttribute],
  children: [imageElement.name, sizeElement.name],
};

export default [symbolElement, imageElement];
