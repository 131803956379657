import { create } from 'zustand';

interface State {
  showVariablePanels: boolean;
}

interface Actions {
  toggleShowVariablePanels: () => void;
}

export const useComponentEditorStore = create<State & Actions>((set) => ({
  showVariablePanels: true,
  toggleShowVariablePanels: () =>
    set((state) => ({ showVariablePanels: !state.showVariablePanels })),
}));
