import { useRollbar } from '@rollbar/react';
import { Form, FormInstance, Input } from 'antd';
import { RuleObject } from 'antd/es/form';
import { useContext } from 'react';
import { AdminConsoleContext } from 'routes/Admin/AdminConsoleProvider';
import AddOrSelectGroup from './AddOrSelectGroup';

const { TextArea } = Input;

const emailRegex =
  /[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?/;

function EmailsForm(props: { form: FormInstance; onFinish: () => void }) {
  const { form, onFinish } = props;
  const { organization } = useContext(AdminConsoleContext);
  const rollbar = useRollbar();

  const validateEmails = (rule: RuleObject, value: string) => {
    if (value === undefined || value.length < 1)
      return Promise.reject(new Error('Please enter email addresses to send invites to'));

    const emailsArray = value
      .split(/\s*,\s*/)
      .join(',')
      .replace(/\n+/g, ',')
      .split(',')
      .map((email: string) => email.trim())
      .filter((email: string) => email !== '');

    const uniqueEmails = [...new Set(emailsArray)];
    const availableSeats = organization.seats - organization.members.length;

    // Check if there are enough seats available
    if (uniqueEmails.length > availableSeats)
      return Promise.reject(
        new Error(
          `Not enough seats. Please remove (${
            uniqueEmails.length - availableSeats
          }) email addresses.`,
        ),
      );

    // Check if all entered emails pass the regex
    if (
      emailsArray.every((email: string) => {
        try {
          const result = email.match(emailRegex);
          if (!result) return false;
          return result[0].trim() === result.input?.trim();
        } catch (error) {
          rollbar.error('Error validating emails when creating organization invites', error);
          return false;
        }
      })
    )
      return Promise.resolve();

    return Promise.reject(
      new Error(
        'Please only enter comma- or row-separated email addresses in the format example@mail.com',
      ),
    );
  };

  const handleFinish = () => {
    onFinish();
  };
  return (
    <Form
      form={form}
      layout="vertical"
      scrollToFirstError
      requiredMark={false}
      onFinish={handleFinish}
    >
      <Form.Item
        label="Select a group to add members to"
        name="group"
        validateFirst={false}
        rules={[
          {
            required: true,
            message: 'Please select a group',
          },
        ]}
      >
        <AddOrSelectGroup key="select-group" autoFocus />
      </Form.Item>

      <Form.Item
        label="Enter email addresses separated by comma (,) or one per row"
        name="emails"
        validateFirst={false}
        rules={[
          {
            required: true,
            type: 'string',
            min: 1,
            // message: 'Please enter email addresses to send invites to',
            validator: validateEmails,
          },
        ]}
      >
        <TextArea
          autoSize={{ minRows: 6, maxRows: 12 }}
          placeholder={'user1@example.com\nuser2@example.com\n...'}
        />
      </Form.Item>
    </Form>
  );
}

export default EmailsForm;
