const canvas = document.createElement('canvas');
const gl = canvas.getContext('webgl2', { stencil: true });
canvas.remove();

function getMaxColorBuffers(context: WebGL2RenderingContext) {
  let maxColorBuffers = 1;
  const ext = context.getExtension('WEBGL_draw_buffers');
  if (ext) {
    maxColorBuffers = context.getParameter(ext.MAX_DRAW_BUFFERS_WEBGL);
  }

  return maxColorBuffers;
}

export const webGLReport = gl
  ? {
      version: gl.getParameter(gl.VERSION),
      shadingLanguageVersion: gl.getParameter(gl.SHADING_LANGUAGE_VERSION),
      vendor: gl.getParameter(gl.VENDOR),
      renderer: gl.getParameter(gl.RENDERER),
      antialias: gl.getContextAttributes()?.antialias ? 'Available' : 'Not available',
      maxColorBuffers: getMaxColorBuffers(gl),
      maxRenderBufferSize: gl.getParameter(gl.MAX_RENDERBUFFER_SIZE),
      maxCombinedTextureImageUnits: gl.getParameter(gl.MAX_COMBINED_TEXTURE_IMAGE_UNITS),
      maxCubeMapTextureSize: gl.getParameter(gl.MAX_CUBE_MAP_TEXTURE_SIZE),
      maxFragmentUniformVectors: gl.getParameter(gl.MAX_FRAGMENT_UNIFORM_VECTORS),
      maxTextureImageUnits: gl.getParameter(gl.MAX_TEXTURE_IMAGE_UNITS),
      maxTextureSize: gl.getParameter(gl.MAX_TEXTURE_SIZE),
      maxDrawBuffers: gl.getParameter(gl.MAX_DRAW_BUFFERS),
      extensions: gl.getSupportedExtensions(),
    }
  : null;
