import { Fetch } from 'simumatik-commons';
import { IOrganization } from 'types/api-interfaces';
import { useUser } from './useUser';

export const useOrganization = () => {
  const { me, updateMe } = useUser();

  const updateOrganization = (organization: IOrganization | undefined) => {
    updateMe({ organization });
    if (organization) {
      Fetch.headers.set('X-Simumatik-Organization-Id', organization.id);
    } else {
      Fetch.headers.delete('X-Simumatik-Organization-Id');
    }
  };

  return { organization: me.organization, updateOrganization };
};
