import { ErrorBoundary, ErrorBoundaryProps } from '@rollbar/react';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import { Button, Result } from 'antd';

function Fallback(props: { error: unknown; resetError: () => void }) {
  const { error, resetError } = props;
  return (
    <Result
      status="error"
      title={(error as Error).message}
      extra={[
        <Button key="reset-error" onClick={() => resetError()}>
          Reload
        </Button>,
      ]}
    />
  );
}

function ErrorBoundaryWrapper(props: ErrorBoundaryProps) {
  const { children, fallbackUI, errorMessage, extra, level, callback } = props;
  return (
    <QueryErrorResetBoundary>
      {() => (
        <ErrorBoundary
          fallbackUI={fallbackUI}
          errorMessage={errorMessage}
          extra={extra}
          level={level}
          callback={callback}
        >
          {children}
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}

ErrorBoundaryWrapper.defaultProps = {
  fallbackUI: Fallback,
  callback: () => {},
};

export default ErrorBoundaryWrapper;
