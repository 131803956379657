import { Switch, Tooltip } from 'antd';
import { useOrganization } from 'hooks/useOrganization';
import { useUser } from 'hooks/useUser';
import { useContext } from 'react';
import { canModify } from 'utils/authorizations';
import { ListItemContext } from '../ListItemProvider';
import { useLibraryState } from './hooks/useLibraryState';

function ToggleVisibility() {
  const { me } = useUser();
  const { organization } = useOrganization();
  const { library } = useContext(ListItemContext);
  const canIModify = canModify(me, library);
  const { patch } = useLibraryState();

  // Update visibility in API and state
  const handleChange = async (checked: boolean) => {
    patch.mutate({ id: library.id, payload: { visible: checked } });
  };

  const getTooltipTitle = () => {
    if (!organization) return `Individual users cannot share libraries with other users`;
    if (organization) return `Toggle library visibility to other users in ${organization?.name}`;
    return null;
  };

  return (
    <Tooltip title={getTooltipTitle()}>
      <Switch
        checked={library.visible}
        checkedChildren={!organization ? 'Private' : 'Visible'}
        unCheckedChildren={!organization ? 'Private' : 'Hidden'}
        onChange={handleChange}
        disabled={!canIModify || !organization}
        onClick={(_, e) => e.stopPropagation()}
        loading={patch.isLoading}
      />
    </Tooltip>
  );
}

export default ToggleVisibility;
