import React, { useEffect, useState } from 'react';

type Rewardful = NonNullable<typeof window.Rewardful>;
type Referral = Rewardful['referral'];
type Affiliate = Rewardful['affiliate'];
type Campaign = Rewardful['campaign'];
type Coupon = Rewardful['coupon'];

interface RewardfulContextType {
  referral: Referral;
  affiliate: Affiliate;
  campaign: Campaign;
  coupon: Coupon;
  isReady: boolean;
}

export const RewardfulContext = React.createContext<RewardfulContextType>({
  referral: undefined,
  affiliate: undefined,
  campaign: undefined,
  coupon: undefined,
  isReady: false,
});

interface RewardfulProviderProps {
  children: React.ReactNode;
}

export function RewardfulProvider({ children }: RewardfulProviderProps) {
  const [referral, setReferral] = useState<Referral>();
  const [affiliate, setAffiliate] = useState<Affiliate>();
  const [campaign, setCampaign] = useState<Campaign>();
  const [coupon, setCoupon] = useState<Coupon>();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    window.rewardful('ready', () => {
      setReferral(window.Rewardful?.referral);
      setAffiliate(window.Rewardful?.affiliate);
      setCampaign(window.Rewardful?.campaign);
      setCoupon(window.Rewardful?.coupon);
      setIsReady(true);
    });
  }, []);

  return (
    <RewardfulContext.Provider value={{ referral, affiliate, campaign, coupon, isReady }}>
      {children}
    </RewardfulContext.Provider>
  );
}
