import { ArrowLeftOutlined } from '@ant-design/icons';
import { Breadcrumb, BreadcrumbProps, Button, TagType, theme } from 'antd';
import classNames from 'classnames';
import React from 'react';
import styles from './PageHeader.module.css';

export interface PageHeaderProps {
  backIcon?: React.ReactNode;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  style?: React.CSSProperties;
  breadcrumb?: BreadcrumbProps | React.ReactElement<typeof Breadcrumb>;
  breadcrumbRender?: (props: PageHeaderProps, defaultDom: React.ReactNode) => React.ReactNode;
  tags?: React.ReactElement<TagType> | React.ReactElement<TagType>[];
  footer?: React.ReactNode;
  extra?: React.ReactNode;
  onBack?: (e?: React.MouseEvent<HTMLElement>) => void;
  ghost?: boolean;
  children?: React.ReactNode;
  className?: string;
}

/**
 *
 * @deprecated
 */
function PageHeader(props: PageHeaderProps) {
  const {
    breadcrumb,
    breadcrumbRender,
    title,
    subTitle,
    tags,
    extra,
    footer,
    onBack,
    style,
    children,
    backIcon,
    ghost = false,
  } = props;
  const { token } = theme.useToken();

  const hasHeading = title || subTitle || tags || extra;

  const getDefaultBreadcrumbDom = () => {
    if ((breadcrumb as BreadcrumbProps)?.routes) {
      return renderBreadcrumb(breadcrumb as BreadcrumbProps);
    }
    return null;
  };

  const defaultBreadcrumbDom = getDefaultBreadcrumbDom();

  const isBreadcrumbComponent = breadcrumb && 'props' in breadcrumb;
  // support breadcrumbRender function
  const breadcrumbRenderDomFromProps =
    breadcrumbRender?.(props, defaultBreadcrumbDom) ?? defaultBreadcrumbDom;

  const breadcrumbDom = isBreadcrumbComponent ? breadcrumb : breadcrumbRenderDomFromProps;

  const headerClasses = classNames(styles.header, {
    [`${styles['header--with-footer']}`]: Boolean(footer),
    [`${styles['header--with-breadcrumbs']}`]: Boolean(breadcrumb),
  });

  return (
    <div
      className={headerClasses}
      style={{
        ...style,
        backgroundColor: ghost ? 'none' : token.colorBgBase,
        border: ghost ? 'none' : '1px solid',
        borderColor: token.colorBorderSecondary,
      }}
    >
      {breadcrumb ? <div className={styles.breadcrumbs}>{breadcrumbDom}</div> : null}
      {hasHeading ? (
        <div className={styles.heading}>
          <div className={styles.heading__left}>
            {onBack ? (
              <span className={styles.heading__left_back}>
                <BackButton onBack={onBack} icon={backIcon} />
              </span>
            ) : null}
            <span
              className={styles.heading__left_title}
              title={typeof title === 'string' ? title : undefined}
            >
              {title}
            </span>
            {subTitle ? (
              <span
                className={styles.heading__left_subtitle}
                style={{ color: token.colorTextSecondary }}
                title={typeof subTitle === 'string' ? subTitle : undefined}
              >
                {subTitle}
              </span>
            ) : null}
            {tags ? <span className={styles.heading__left_tags}>{tags}</span> : null}
          </div>
          <div className={styles.heading__extra}>
            {extra ? (
              <div className={styles.extra__items}>
                {Array.isArray(extra) ? (
                  extra.map((extra) => <div key={extra}>{extra}</div>)
                ) : (
                  <div>{extra}</div>
                )}
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
      {children}
      {footer ? <div className={styles.footer}>{footer}</div> : null}
    </div>
  );
}

type BackButtonProps = {
  onBack: Exclude<PageHeaderProps['onBack'], undefined>;
  icon?: React.ReactNode;
};

function BackButton({ onBack, icon }: BackButtonProps) {
  return (
    <Button
      icon={icon ?? <ArrowLeftOutlined />}
      type="text"
      onClick={onBack}
      style={{ alignItems: 'center', justifyContent: 'center' }}
    />
  );
}

// eslint-disable-next-line react/jsx-props-no-spreading
const renderBreadcrumb = (breadcrumb: BreadcrumbProps) => <Breadcrumb {...breadcrumb} />;

export default PageHeader;
