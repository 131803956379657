import { MailTwoTone, ReloadOutlined } from '@ant-design/icons';
import { useRollbar } from '@rollbar/react';
import { useMutation } from '@tanstack/react-query';
import { Button, Result, theme } from 'antd';
import { AxiosError } from 'axios';
import AppLogo from 'components/AppLogo';
import { SUPPORT_EMAIL } from 'config';
import { useMessage } from 'hooks/ui/useMessage';
import useQueryParams from 'hooks/utils/useQueryParams';
import { backendClient } from 'lib/axios';

export function VerifyEmail() {
  const params = useQueryParams();
  const message = useMessage();
  const rollbar = useRollbar();
  const { token } = theme.useToken();
  const { isLoading, mutate } = useMutation({
    mutationFn: verifyEmail,
    onError: (error: AxiosError) => {
      rollbar.error('Error sending verifaction email', error);
      message.error('Error sending verification email');
    },
    onSuccess: () => {
      message.success('Verification email sent');
    },
  });

  const handleClick = () => {
    const id = params.get('id');
    const clientId = params.get('clientId');
    if (!id || !clientId) return;

    mutate({ user: id, client_id: clientId });
  };

  return (
    <div className="flex h-screen w-screen items-center justify-center bg-neutral-100">
      <div className="bg-white p-8 text-center">
        <AppLogo size={64} />

        <Result
          title="Please verify your email"
          subTitle={`Check your inbox for a verification email and click the verification link`}
          icon={<MailTwoTone twoToneColor={token.colorPrimary} />}
          extra={
            <div className="flex justify-center gap-2">
              <Button
                type="primary"
                onClick={handleClick}
                loading={isLoading}
                icon={<ReloadOutlined />}
                disabled={!params.has('id') || !params.has('clientId')}
              >
                Resend Email
              </Button>
              <Button href={`mailto:${SUPPORT_EMAIL}`}>Contact Support</Button>
            </div>
          }
        ></Result>
      </div>
    </div>
  );
}

async function verifyEmail(payload: { user: string; client_id: string }) {
  const { data } = await backendClient.post('/verify-email', payload);
  return data;
}
