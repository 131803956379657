import { ElementSpec } from '@codemirror/lang-xml';
import { nameAttribute, publicAttribute } from '../../attributes';
import {
  collisionGroupElement,
  collisionTagElement,
  enableElement,
  lengthElement,
  originElement,
} from '../shared';

export const lockedElement: ElementSpec = {
  name: 'locked',
  attributes: [publicAttribute],
  textContent: ['true', 'false'],
  children: [],
};

export const lockerElement: ElementSpec = {
  name: 'locker',
  attributes: [nameAttribute],
  children: [
    originElement.name,
    enableElement.name,
    collisionTagElement.name,
    lengthElement.name,
    lockedElement.name,
    collisionGroupElement.name,
  ],
};

export default [lockerElement, lockedElement];
