import { ResponseType } from 'axios';
import { IAsset, IObject } from 'types/api-interfaces';
import { apiClient } from 'lib/axios';

export async function fetchAssets(object: IObject) {
  const url = `/${object.type}s/${object.id}/assets`;
  const { data } = await apiClient.get(url);
  return data;
}

export async function fetchAsset(id: string): Promise<IAsset> {
  const url = `/assets/${id}`;
  const { data } = await apiClient.get(url);
  return data;
}

export async function fetchAssetFile(
  id: string,
  responseType?: ResponseType,
  onProgress?: (progress: number | undefined) => void,
) {
  const url = `/assets/${id}/file`;
  const { data } = await apiClient.get(url, {
    responseType,
    onDownloadProgress: (progressEvent) => {
      if (onProgress) onProgress(progressEvent.progress);
    },
  });
  return data;
}

export async function patchAsset(id: string, payload: { name?: string }) {
  const url = `/assets/${id}`;
  const { data } = await apiClient.patch(url, payload);
  return data;
}

export async function createAsset(
  object: IObject,
  formData: FormData,
  onProgress?: (progress: number | undefined) => void,
): Promise<IAsset> {
  const url = `/${object.type}s/${object.id}/assets`;
  const { data } = await apiClient.post(url, formData, {
    onUploadProgress: (progressEvent) => {
      if (onProgress) onProgress(progressEvent.progress);
    },
  });
  return data;
}

export async function updateAssetFile(
  id: string,
  formData: FormData,
  onProgress?: (progress: number | undefined) => void,
) {
  const url = `/assets/${id}/file`;
  const { data } = await apiClient.post<IAsset>(url, formData, {
    onUploadProgress: (progressEvent) => {
      if (onProgress) onProgress(progressEvent.progress);
    },
  });
  return data;
}

export async function deleteAsset(id: string) {
  const url = `/assets/${id}`;
  const { data } = await apiClient.delete(url);
  return data;
}
