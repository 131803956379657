import { IAsset } from 'types/api-interfaces';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

type State = {
  selectedAssets: IAsset[];
};

type Actions = {
  setSelectedAssets: (assets: IAsset[]) => void;
  reset: () => void;
  addAssetSelection: (asset: IAsset) => void;
  removeAssetSelection: (asset: IAsset) => void;
};

const initialState: State = {
  selectedAssets: [],
};

const useAssetBrowserStore = create(
  devtools<State & Actions>(
    (set, get) => ({
      ...initialState,
      setSelectedAssets: (selectedAssets) => set({ selectedAssets }),
      reset: () => set(initialState),
      addAssetSelection: (asset) => set({ selectedAssets: [...get().selectedAssets, asset] }),
      removeAssetSelection: (asset) =>
        set({ selectedAssets: [...get().selectedAssets.filter(({ id }) => id !== asset.id)] }),
    }),
    { name: 'Asset Browser' },
  ),
);

export default useAssetBrowserStore;
