export const Logo = ({ className }: { className?: string }) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 225 225"
      version="1.1"
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      className={className}
    >
      <g>
        <path
          d="M224.415,112.205C224.415,50.236 174.182,0 112.207,0C50.238,0 0,50.236 0,112.205C0,174.176 50.238,224.41 112.207,224.41C174.182,224.41 224.415,174.176 224.415,112.205Z"
          style={{ fill: 'rgb(116,184,0)', fillRule: 'nonzero' }}
        />
        <g>
          <path
            d="M154.263,138.329L144.527,111.707L94.871,88.552L88.528,70.884C88.199,65.326 92.946,55.53 101.171,49.405C107.079,45.001 116.498,40.159 130.271,45.176L135.617,59.866L158.337,51.597L150.068,28.875L135.421,34.208C120.545,28.785 105.516,30.437 93.642,39.282C82.748,47.384 75.303,60.989 75.923,71.63L85.231,97.394L134.88,120.548L141.674,139.075C142.067,145.744 136.37,163.565 124.099,173.876C115.852,180.802 109.526,184.591 98.797,182.046C98.776,180.508 98.505,178.941 97.953,177.41C95.28,170.065 87.155,166.284 79.815,168.954C72.475,171.632 68.694,179.749 71.362,187.089C74.035,194.434 82.149,198.218 89.489,195.545C91.106,194.959 92.549,194.1 93.795,193.05C97.635,193.959 100.186,194.113 103.871,194.113C114.219,194.113 123.865,190.544 132.207,183.538C147.575,170.639 154.899,148.997 154.263,138.329ZM86.735,187.971C83.58,189.118 80.08,187.483 78.928,184.329C77.782,181.18 79.41,177.679 82.572,176.528C85.727,175.38 89.222,177.015 90.368,180.169C91.519,183.323 89.892,186.825 86.735,187.971Z"
            style={{ fill: 'white', fillRule: 'nonzero' }}
          />
          <path
            d="M165.898,121.674L150.396,114.445L159.088,138.312C158.504,150.987 150.337,168.721 146.636,174.245L157.666,181.648C162.042,175.109 171.402,155.145 172.155,138.882L165.898,121.674Z"
            style={{ fill: 'white', fillRule: 'nonzero' }}
          />
          <path
            d="M109.169,162.706L114.498,174.649C122.252,170.25 135.95,155.09 136.698,138.831L130.445,121.618L114.954,114.392L123.631,138.26C123.048,150.932 113.607,158.834 109.169,162.706Z"
            style={{ fill: 'white', fillRule: 'nonzero' }}
          />
          <path
            d="M120.692,59.116L120.708,47.682C110.802,47.928 94.388,55.526 93.641,71.783L99.172,86.985L114.668,94.211L106.713,72.354C105.912,64.254 113.666,59.84 120.692,59.116Z"
            style={{ fill: 'white', fillRule: 'nonzero' }}
          />
          <path
            d="M72.385,71.592C71.828,58.5 84.307,42.374 90.517,37.582L80.308,29.144C74.008,34.805 59.312,54.37 59.312,71.025L65.576,88.234L81.072,95.463L72.385,71.592Z"
            style={{ fill: 'white', fillRule: 'nonzero' }}
          />
        </g>
      </g>
    </svg>
  );
};

export default Logo;
