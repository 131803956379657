import { ElementSpec } from '@codemirror/lang-xml';
import { publicAttribute } from '../../attributes';
import { collisionDataElement, collisionGroupElement, collisionTagElement } from '../shared';

const bouncinessElement: ElementSpec = {
  name: 'bounciness',
  attributes: [publicAttribute],
  children: [],
};

const frictionElement: ElementSpec = {
  name: 'friction',
  attributes: [publicAttribute],
  children: [],
};

const motionDirectionElement: ElementSpec = {
  name: 'motion_direction',
  attributes: [publicAttribute],
  children: [],
};

const motionSpeedElement: ElementSpec = {
  name: 'motion_speed',
  attributes: [publicAttribute],
  children: [],
};

const isCollidingElement: ElementSpec = {
  name: 'is_colliding',
  attributes: [publicAttribute],
  textContent: ['true', 'false'],
  children: [],
};

export const surfaceElement: ElementSpec = {
  name: 'surface',
  children: [
    bouncinessElement.name,
    frictionElement.name,
    collisionTagElement.name,
    collisionGroupElement.name,
  ],
};

export const motionSurfaceElement: ElementSpec = {
  name: 'motion_surface',
  children: surfaceElement.children?.concat([
    motionDirectionElement.name,
    motionSpeedElement.name,
    isCollidingElement.name,
  ]),
};

export const dataSurfaceElement: ElementSpec = {
  name: 'data_surface',
  children: surfaceElement.children?.concat([collisionDataElement.name]),
};

export default [
  surfaceElement,
  motionSurfaceElement,
  dataSurfaceElement,
  bouncinessElement,
  frictionElement,
  motionDirectionElement,
  motionSpeedElement,
  isCollidingElement,
];
