import emptyImage from 'assets/images/empty-doc.png';
import Konva from 'konva';
import { EventObject, EventTarget, Item } from './types';

// #endregion TYPES

// #region CONSTANTS
export enum StageSize {
  WIDTH = 1448,
  HEIGHT = 1024,
  SIDEBAR_WIDTH = 300,
}

export enum Display {
  NONE = 'none',
  INITIAL = 'initial',
}

export const COLUMNS = 6;
export const MARGIN = 6;
export const STAGE_MARGIN = 6;
export const PAGE_PADDING = 6;
export const LINE_STROKE_WIDTH = 2;
export const FONT_SIZE = 12;
export const PORT_RADIUS = 4;
export const NOTE_WIDTH = 200;
export const ESCAPE_WIDTH = 20;
export const FONT_FAMILY = 'Arial';
export const FRAME_GAPE = 20 + STAGE_MARGIN;
export const LINE_DASH = [10, 5];
export const PARTIAL_CONNECTION_LENGTH = ESCAPE_WIDTH * 2;

export enum Color {
  WHITE = 'white',
  BLACK = 'black',
  BLUE = 'blue',
  GREY = 'grey',
  RED = 'red',
  GREEN = 'green',
  EMPTY = '#ebebeb',
}

export enum Name {
  Stage = 'stage',
  StageBackground = 'stageBackground',
  PageBackground = 'pageBackground',
  Document = 'document',
  SelectGroup = 'selectGroup',
  PageGroup = 'pageGroup',
  Page = 'page',
  PageNo = 'pageNumber',
  SymbolGroup = 'symbolGroup',
  Symbol = 'symbol',
  PortGroup = 'portGroup',
  Port = 'port',
  PortName = 'portName',
  Pivot = 'pivot',
  Arrow = 'arrow',
  ConnectionFull = 'fullConnection',
  ConnectionPartial = 'partialConnection',
  ConnectionArrow = 'connectionArrow',
  ConnectionText = 'connectionText',
  Note = 'note',
  NoteGroup = 'noteGroup',
  SymbolLabel = 'symbolLabel',
  ConnectionLine = 'connectionLine',
  PortLabel = 'portLabel',
}

export enum EventLabel {
  DRAG_MOVE = 'dragmove',
  DRAG_START = 'dragstart',
  DRAGEND = 'dragend',
  CLICK = 'click',
  KEYDOWN = 'keydown',
  DBLCLICK = 'dblclick dbltap',
  CONTEXTMENU = 'contextmenu',
  TEXT_CHANGE = 'text-change',
  ROTATION_CHANGE = 'rotation-change',
  SCALE_CHANGE = 'scale-change',
  HORIZONTAL_FLIP = 'horizontal-flip',
  VERTICAL_FLIP = 'vertical-flip',
  MOUSEDOWN = 'mousedown',
  MOUSEUP = 'mouseup',
  MOUSEMOVE = 'mousemove',
  MOUSEOVER = 'mouseover',
  MOUSELEAVE = 'mouseleave',
  MOUSEOUT = 'mouseout',
  FONT_CHANGE = 'fontChange',
  MOUSEENTER = 'mouseenter',
  PARENT_ROTATED = 'parentRotated',
}

export enum Attr {
  ID = 'id',
  NUMBER = 'number',
  HEIGHT = 'height',
  WIDTH = 'width',
  X = 'x',
  Y = 'y',
  CURRENT_PAGE = 'current_page',
  IDENTIFIER = 'Identifier',
  CONNECTION_REF = 'connectionRef',
  TO_VALUE = 'toValue',
  TO_VARIABLE_ID = 'toVariableId',
  DIRECTION = 'direction',
  TYPE = 'portType',
  PATH = 'path',
  FONT_SIZE = 'fontSize',
  REF_COMPONENT = 'refComponent',
  CONNECTIONS = 'connections',
  COMPONENT_ID = 'componentId',
  ROTATE_FUNC = 'rotate',
  PARENT_ROTATED_FUNC = 'parentRotated',
  FLIP_FUNC = 'flip',
  PARENT_FLIP_FUNC = 'parentFlip',
  ON_REMOVE = 'onRemove',
  CALL_ON_REMOVE = 'callOnRemove',
  SUBSCRIPTIONS = 'subscriptions',
}

export enum Side {
  TOP,
  RIGHT,
  BOTTOM,
  LEFT,
}

export enum Rotation {
  DEG0 = 0,
  DEG90 = 90,
  DEG180 = 180,
  DEG270 = 270,
  DEG360 = 360,
}

export enum PortDirection {
  INPUT = 'input',
  OUTPUT = 'output',
}

export enum MouseButton {
  LEFT,
  MIDDLE,
  RIGHT,
}

/** Predefined attributes states */
export const ATTRS = {
  CONNECTION_DEFAULT: { stroke: Color.BLACK, fill: Color.BLACK },
  CONNECTION_HOVER: { stroke: Color.RED, fill: Color.RED },
  CONNECTION_TEXT_DEFAULT: { fill: Color.BLACK },
  CONNECTION_TEXT_HOVER: { fill: Color.BLUE },
  PORT_DEFAULT: { stroke: Color.BLACK, radius: PORT_RADIUS },
  PORT_HOVER: { stroke: Color.BLACK, radius: PORT_RADIUS * 1.5 },
  PORT_HOVER_ALLOWED: { stroke: Color.GREEN, radius: PORT_RADIUS * 1.5 },
  PORT_HOVER_DENIED: { stroke: Color.RED, radius: PORT_RADIUS },
};

// #endregion CONSTANTS

// #region FEATURES FUNCTIONS
/**
 * Creates new stage and add to the supplied container element
 * @param container
 * @returns
 */
export function createStage(container: HTMLDivElement) {
  const stage = new Konva.Stage({
    name: Name.Stage,
    container,
    height: StageSize.HEIGHT,
    width: StageSize.WIDTH,
  });
  stage.on('mousedown', (e) => {
    if (e.evt.button === 1) {
      stage.startDrag();
    }
  });
  stage.draw();
  return stage;
}

/** Add background layer to the stage */
export function addBackgroundLayer() {
  const BORDER_INTERVAL_X = 0.167;
  const BORDER_INTERVAL_Y = 0.251;
  const BORDER_LABELS_X = ['6', '5', '4', '3', '2', '1'];
  const BORDER_LABELS_Y = ['D', 'C', 'B', 'A'];
  const background = new Konva.Layer({ name: Name.StageBackground });
  const outerBorder = new Konva.Rect({
    x: STAGE_MARGIN,
    y: STAGE_MARGIN,
    width: StageSize.WIDTH - STAGE_MARGIN * 2,
    height: StageSize.HEIGHT - STAGE_MARGIN * 2,
    stroke: Color.BLACK,
    strokeWidth: LINE_STROKE_WIDTH,
    fill: Color.WHITE,
  });
  background.add(outerBorder);

  const innerBorder = new Konva.Rect({
    x: FRAME_GAPE,
    y: FRAME_GAPE,
    width: StageSize.WIDTH - FRAME_GAPE * 2,
    height: StageSize.HEIGHT - FRAME_GAPE * 2,
    stroke: Color.BLACK,
    strokeWidth: LINE_STROKE_WIDTH,
    fill: Color.EMPTY,
  });
  background.add(innerBorder);
  // empty document icon
  Konva.Image.fromURL(emptyImage, (image: Konva.Image) => {
    image.setAttrs({
      x: StageSize.WIDTH / 2 - 50,
      y: StageSize.HEIGHT / 2 - 180,
      scaleX: 0.5,
      scaleY: 0.5,
    });
    background.add(image);
  });
  // empty document page
  const emptyText = new Konva.Text({
    x: StageSize.WIDTH / 2 - 50,
    y: StageSize.HEIGHT / 2 - 100,
    text: `Empty document`,
    fill: Color.GREY,
  });
  background.add(emptyText);

  const betaText = new Konva.Text({
    x: StageSize.WIDTH / 2 - 50,
    y: StageSize.HEIGHT - FRAME_GAPE * 2,
    text: `2D drawings (beta)`,
    fill: Color.GREY,
    fontSize: FONT_SIZE * 0.8,
  });
  background.add(betaText);

  // fill upper and lower sides
  for (let ratio = BORDER_INTERVAL_X; ratio < 1.1; ratio += BORDER_INTERVAL_X) {
    const upperBorderLine = new Konva.Line({
      points: [StageSize.WIDTH * ratio, STAGE_MARGIN, StageSize.WIDTH * ratio, FRAME_GAPE],
      strokeWidth: LINE_STROKE_WIDTH,
      stroke: Color.BLACK,
    });
    background.add(upperBorderLine);

    const lowerBorderLine = new Konva.Line({
      points: [
        StageSize.WIDTH * ratio,
        StageSize.HEIGHT - FRAME_GAPE,
        StageSize.WIDTH * ratio,
        StageSize.HEIGHT - STAGE_MARGIN,
      ],
      strokeWidth: LINE_STROKE_WIDTH,
      stroke: Color.BLACK,
    });
    background.add(lowerBorderLine);

    const text = BORDER_LABELS_X.pop();
    const upperBorderLabel = new Konva.Text({
      x: StageSize.WIDTH * ratio - (StageSize.WIDTH * BORDER_INTERVAL_X) / 2,
      y: 5 + STAGE_MARGIN,
      text,
      fontSize: FONT_SIZE,
      fontFamily: FONT_FAMILY,
      fill: Color.BLACK,
    });
    background.add(upperBorderLabel);

    const lowerBorderLabel = new Konva.Text({
      x: StageSize.WIDTH * ratio - (StageSize.WIDTH * BORDER_INTERVAL_X) / 2,
      y: StageSize.HEIGHT - FRAME_GAPE + 5,
      text,
      fontSize: FONT_SIZE,
      fontFamily: FONT_FAMILY,
      fill: Color.BLACK,
    });
    background.add(lowerBorderLabel);
  }
  // fill left and right sides
  for (let ratio = BORDER_INTERVAL_Y; ratio < 1.1; ratio += BORDER_INTERVAL_Y) {
    const leftLine = new Konva.Line({
      points: [STAGE_MARGIN, StageSize.HEIGHT * ratio, FRAME_GAPE, StageSize.HEIGHT * ratio],
      strokeWidth: LINE_STROKE_WIDTH,
      stroke: Color.BLACK,
    });
    background.add(leftLine);

    const rightBorderLine = new Konva.Line({
      points: [
        StageSize.WIDTH - FRAME_GAPE,
        StageSize.HEIGHT * ratio,
        StageSize.WIDTH - STAGE_MARGIN,
        StageSize.HEIGHT * ratio,
      ],
      strokeWidth: LINE_STROKE_WIDTH,
      stroke: Color.BLACK,
    });
    background.add(rightBorderLine);

    const text = BORDER_LABELS_Y.pop();
    const leftBorderLabel = new Konva.Text({
      x: 5 + STAGE_MARGIN,
      y: StageSize.HEIGHT * ratio - (StageSize.HEIGHT * BORDER_INTERVAL_Y) / 2,
      text,
      fontSize: FONT_SIZE,
      fontFamily: FONT_FAMILY,
      fill: Color.BLACK,
    });
    background.add(leftBorderLabel);

    const rightBorderLabel = new Konva.Text({
      x: StageSize.WIDTH - FRAME_GAPE + 5,
      y: StageSize.HEIGHT * ratio - (StageSize.HEIGHT * BORDER_INTERVAL_Y) / 2,
      text,
      fontSize: FONT_SIZE,
      fontFamily: FONT_FAMILY,
      fill: Color.BLACK,
    });
    background.add(rightBorderLabel);
  }
  return background;
}

/** Add document to a stage */
export function addDocument(stage: Konva.Stage) {
  const document = new Konva.Layer({ name: Name.Document });
  stage.add(document);
  return document;
}

/** Creates and add a node to a page */
export function addNote(
  id: string,
  text: string,
  x: number,
  y: number,
  rotation: number,
  scale: number,
  page: Konva.Group,
) {
  const textNode = new Konva.Text({
    id,
    name: Name.Note,
    text,
    fontSize: 20,
    width: NOTE_WIDTH,
    padding: 3,
  });
  const note = new Konva.Group({
    id,
    name: Name.NoteGroup,
    x,
    y,
    rotation,
    draggable: true,
    scaleX: scale,
    scaleY: scale,
  });
  const selectionRect = new Konva.Rect({
    stroke: Color.GREY,
    fill: Color.WHITE,
    strokeWidth: 1,
    visible: true,
    listening: false,
    width: textNode.width(),
    height: textNode.height(),
  });

  note.add(selectionRect);
  note.add(textNode);
  page.add(note);

  // clean all note subscriptions (value and position)
  const cleanNoteSubscriptions = () => {
    note.getAttr(Attr.SUBSCRIPTIONS)?.forEach((unsubscribe: () => void) => unsubscribe());
  };
  note.setAttr(Attr.SUBSCRIPTIONS, []);
  // clean subscription when note is removed
  note.setAttr(Attr.ON_REMOVE, cleanNoteSubscriptions);
  return note;
}

/** Enable a dashed highlight around the node */
export function highlightOn(node: Konva.Shape) {
  node.strokeEnabled(true);
}

/** Removes a dashed highlight around the node */
export function highlightOff(node: Konva.Shape) {
  node.strokeEnabled(false);
}

/**
 * checks if a point is within stage boundaries
 * @param point
 * @returns
 */
export function isWithinStage(point: { x: number; y: number }) {
  if (
    point.x > 0 &&
    point.x < StageSize.WIDTH - FRAME_GAPE * 2 - STAGE_MARGIN &&
    point.y > 0 &&
    point.y < StageSize.HEIGHT - FRAME_GAPE * 2 - STAGE_MARGIN
  ) {
    return true;
  }
  return false;
}

/**
 * Computes the angular offset of a given item totaled in snaps of 90 degrees
 * @param item
 * @returns[Number] 0-3
 */
export function getAngularOffset(item: Item) {
  return Math.abs(
    ((Rotation.DEG360 + Math.round(item.getAbsoluteRotation())) % Rotation.DEG360) / Rotation.DEG90,
  );
}

export function isSymbolRotatedNinetyDegrees(symbolRotation: number) {
  return Math.abs((Math.round(symbolRotation) % Rotation.DEG360) / Rotation.DEG90) % 2;
}

/**
 * Binds items dragging movement with in its parent.
 * @param event Dragging Event
 */
export function boundWithinParent(event: EventObject | EventTarget) {
  const target = event?.target;
  switch (target.name()) {
    case Name.SymbolGroup: {
      const page = target.findAncestor(`.${Name.PageGroup}`);
      const { x, y } = target.position();
      // set the offset based on item rotation
      const [offsetX, offsetY] =
        getAngularOffset(target) % 2
          ? [target.offsetY(), target.offsetX()]
          : [target.offsetX(), target.offsetY()];
      if (x <= offsetX) {
        target.x(offsetX);
      } else if (page !== undefined && x + offsetX >= Number(page.width())) {
        target.x(Number(page.width()) - offsetX);
      }

      if (y <= offsetY) {
        target.y(offsetY);
      } else if (page !== undefined && y + offsetY >= Number(page.height())) {
        target.y(Number(page.height()) - offsetY);
      }
      break;
    }

    case Name.SymbolLabel: {
      const stage = target.getStage();
      if (stage) {
        const { x, y } = target.getAbsolutePosition();
        // set the offset based on item rotation
        const [offsetX, offsetY] =
          getAngularOffset(target) % 2
            ? [target.offsetY(), target.offsetX()]
            : [target.offsetX(), target.offsetY()];

        if (x <= FRAME_GAPE + offsetX + LINE_STROKE_WIDTH) {
          target.setAbsolutePosition({
            x: FRAME_GAPE + offsetX + LINE_STROKE_WIDTH,
            y,
          });
        } else if (x >= stage.width() - FRAME_GAPE - LINE_STROKE_WIDTH - offsetX) {
          target.setAbsolutePosition({
            x: stage.width() - FRAME_GAPE - LINE_STROKE_WIDTH - offsetX,
            y,
          });
        }

        if (y <= FRAME_GAPE + offsetY + LINE_STROKE_WIDTH) {
          target.setAbsolutePosition({
            x,
            y: FRAME_GAPE + offsetY + LINE_STROKE_WIDTH,
          });
        } else if (y >= stage.height() - FRAME_GAPE - LINE_STROKE_WIDTH - offsetY) {
          target.setAbsolutePosition({
            x,
            y: stage.height() - FRAME_GAPE - LINE_STROKE_WIDTH - offsetY,
          });
        }
      }
      break;
    }

    // Slightly different from default case, ignores padding.
    case Name.PortGroup: {
      const parentWidth = target.parent?.getAttr(Attr.WIDTH);
      const parentHeight = target.parent?.getAttr(Attr.HEIGHT);

      const { x, y } = target.getPosition();
      // SET HORIZONTAL LIMITS
      const maxBoundingWidth = parentWidth - target.getAttr(Attr.WIDTH);
      if (x < 0) {
        target.setAttr(Attr.X, 0);
      } else if (x + target.getAttr(Attr.WIDTH) > parentWidth) {
        target.setAttr(Attr.X, maxBoundingWidth);
      }
      // SET VERTICAL LIMITS
      const maxBoundingHeight = parentHeight - target.getAttr(Attr.HEIGHT);
      if (y < 0) {
        target.setAttr(Attr.Y, 0);
      } else if (y + target.getAttr(Attr.HEIGHT) > parentHeight) {
        target.setAttr(Attr.Y, maxBoundingHeight);
      }
      break;
    }

    default: {
      const parentWidth = target.parent?.getAttr(Attr.WIDTH);
      const parentHeight = target.parent?.getAttr(Attr.HEIGHT);

      const { x, y } = target.getPosition();
      // SET HORIZONTAL LIMITS
      const maxBoundingWidth = parentWidth - target.getAttr(Attr.WIDTH) - PAGE_PADDING;
      if (x < PAGE_PADDING) {
        target.setAttr(Attr.X, PAGE_PADDING);
      } else if (x + target.getAttr(Attr.WIDTH) + PAGE_PADDING > parentWidth) {
        target.setAttr(Attr.X, maxBoundingWidth);
      }
      // SET VERTICAL LIMITS
      const maxBoundingHeight = parentHeight - target.getAttr(Attr.HEIGHT) - PAGE_PADDING;
      if (y < PAGE_PADDING) {
        target.setAttr(Attr.Y, PAGE_PADDING);
      } else if (y + target.getAttr(Attr.HEIGHT) + PAGE_PADDING > parentHeight) {
        target.setAttr(Attr.Y, maxBoundingHeight);
      }
    }
  }
}

// #endregion HELPER FUNCTIONS
