import { PlusOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Button, Select } from 'antd';
import { useContext, useRef, useState } from 'react';
import { IGroup, fetchGroups } from 'services/members';
import useUpdateEffect from '../../../../../hooks/utils/useUpdateEffect';
import { AdminConsoleContext } from '../../../AdminConsoleProvider';
import CreateGroupModal from '../../actions/CreateGroupModal';

const { Option } = Select;

export default function AddOrSelectGroup(props: {
  onChange?: (group: IGroup) => void;
  autoFocus?: boolean;
}) {
  const { onChange, autoFocus } = props;
  const { organization } = useContext(AdminConsoleContext);
  const { data: groups, refetch } = useQuery(['organization', organization.id, 'groups'], () =>
    fetchGroups(),
  );

  const [modalVisible, setModalVisible] = useState(false);
  const [selected, setSelected] = useState<string | undefined>();

  const refSelect = useRef(null);

  useUpdateEffect(() => {
    const selectedGroup = groups?.find((group: IGroup) => group.id === selected);
    if (!selectedGroup) return;
    onChange?.(selectedGroup);
  }, [selected]);

  return (
    <>
      <Select
        ref={refSelect}
        style={{ width: '100%' }}
        placeholder="Select a group"
        dropdownRender={(menu) => (
          <div>
            {menu}
            <Button
              type="link"
              onClick={() => {
                // @ts-expect-error Ant Design does not export ref type for Select component
                refSelect.current?.blur();
                setModalVisible(true);
              }}
            >
              <PlusOutlined /> Add Group
            </Button>
          </div>
        )}
        notFoundContent={<Button onClick={() => refetch()}>Retry</Button>}
        value={selected}
        onSelect={(value) => {
          setSelected(value);
        }}
        onChange={(e) => {
          const selectedGroup = groups?.find((group: IGroup) => group.id === e);
          if (!selectedGroup) return;
          onChange?.(selectedGroup);
        }}
        autoFocus={autoFocus}
      >
        {groups?.map((group: IGroup) => (
          <Option value={group.id} key={group.id}>
            {group.name}
          </Option>
        ))}
      </Select>
      <CreateGroupModal
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        onSuccess={(response) => {
          setModalVisible(false);
          if (!response) return;
          setSelected(response.id);
        }}
      />
    </>
  );
}
