import { useCallback, useEffect, useState } from 'react';
import { ComplexType, readComplexTypes } from 'services';
import { fetchComponentXSD } from 'services/datamodel';

export default function useXSD() {
  const [xsdDoc, setXsdDoc] = useState(new Document());
  const [types, setTypes] = useState<Map<string, ComplexType>>(new Map<string, ComplexType>());

  const loadXSD = useCallback(async () => {
    const controller = new AbortController();
    const { signal } = controller;
    const xsdDoc = await fetchComponentXSD(signal);
    setXsdDoc(xsdDoc);
    return () => {
      controller.abort();
    };
  }, []);

  const getContentNode = useCallback(
    (parentType: string, childType: string) => {
      /** Find choice node in a choice type */
      const getChoice = (parentType: string, childType: string) => {
        const contents = types.get(parentType)?.contents[0];
        if (!contents) return undefined;

        const { choices } = contents;
        if (!choices) return undefined;

        const choice = choices.find((item) => item.name === childType);
        return choice;
      };

      // Get the node
      const contentNode = types
        .get(parentType)
        ?.contents.find((content) => content.name === childType);
      if (contentNode) return contentNode;

      // If no node is found, it might be a choice type
      const choiceNode = getChoice(parentType, childType);
      if (choiceNode) return choiceNode;

      return undefined;
    },
    [types],
  );

  useEffect(() => {
    loadXSD();
  }, [loadXSD]);

  useEffect(() => {
    setTypes(readComplexTypes(xsdDoc));
  }, [xsdDoc]);

  return {
    xsdDoc,
    types,
    getContentNode,
  };
}
