import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { LibraryBrowserContext } from 'features/LibraryBrowser/LibraryBrowserProvider';
import { useContext } from 'react';
import { deleteLibrary, fetchLibraries, patchLibrary } from 'services/libraries';
import { ILibrary } from 'types/api-interfaces';
import sortAlphabetically from 'utils/sortAlphabetically';

export function useLibraryState() {
  const { scope, searchTerm } = useContext(LibraryBrowserContext);
  const queryClient = useQueryClient();
  const libraries = useQuery({
    queryKey: ['libraries', scope, searchTerm],
    queryFn: () => fetchLibraries(scope, searchTerm),
  });

  const remove = useMutation({
    mutationFn: (id: string) => deleteLibrary(id),
    onSuccess(data, id) {
      queryClient.setQueryData<ILibrary[]>(['libraries', scope, searchTerm], (prevData) => {
        if (!prevData) {
          return;
        }

        return prevData.filter((library) => library.id !== id);
      });
    },
  });

  const patch = useMutation({
    mutationFn: (options: { id: string; payload: Partial<ILibrary> }) =>
      patchLibrary(options.id, options.payload),
    onSuccess: (data, { id, payload }) => {
      queryClient.setQueryData<ILibrary[]>(['libraries', scope, searchTerm], (prevData) => {
        if (prevData === undefined) {
          return;
        }

        const libraryIndex = prevData.findIndex((library) => library.id === id);
        if (libraryIndex === -1) {
          return prevData;
        }

        const patchedLibrary: ILibrary = { ...prevData[libraryIndex], ...payload };
        const newData = [...prevData];
        newData.splice(libraryIndex, 1, patchedLibrary);
        return newData.sort((a, b) => sortAlphabetically(a.name, b.name));
      });
    },
  });

  return { libraries, remove, patch };
}
