import { ElementSpec } from '@codemirror/lang-xml';
import { publicAttribute } from '../../attributes';
import { velocityElement } from '../shared';

const lowerElement: ElementSpec = {
  name: 'lower',
  attributes: [publicAttribute],
  children: [],
};

const upperElement: ElementSpec = {
  name: 'upper',
  attributes: [publicAttribute],
  children: [],
};

const effortElement: ElementSpec = {
  name: 'effort',
  attributes: [publicAttribute],
  children: [],
};

export const limitsElement: ElementSpec = {
  name: 'limits',
  children: [lowerElement.name, upperElement.name, effortElement.name, velocityElement.name],
};

export default [limitsElement, lowerElement, upperElement, effortElement];
