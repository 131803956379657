import { ElementSpec } from '@codemirror/lang-xml';
import { publicAttribute } from '../../attributes';

export const collisionTagElement: ElementSpec = {
  name: 'collision_tag',
  children: [],
};

export const collisionStatusElement: ElementSpec = {
  name: 'collision_status',
  attributes: [publicAttribute],
  textContent: ['true', 'false'],
  children: [],
};

export const collisionDataElement: ElementSpec = {
  name: 'collision_data',
  attributes: [publicAttribute],
  children: [],
};

export const collisionGroupElement: ElementSpec = {
  name: 'collision_group',
  children: [],
};

export default [
  collisionTagElement,
  collisionStatusElement,
  collisionDataElement,
  collisionGroupElement,
];
