import { ElementSpec } from '@codemirror/lang-xml';
import { publicAttribute } from '../../../attributes';

const colorElement: ElementSpec = {
  name: 'color',
  attributes: [publicAttribute],
  children: [],
};

const textureElement: ElementSpec = {
  name: 'texture',
  attributes: [publicAttribute],
  children: [],
};

export const materialElement: ElementSpec = {
  name: 'material',
  children: [colorElement.name, textureElement.name],
};

export default [materialElement, textureElement, colorElement];
