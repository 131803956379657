import { ElementSpec } from '@codemirror/lang-xml';
import { nameAttribute } from '../../attributes';
import { forceElement, modeElement, valueElement } from '../shared';
import baseChildren from './base';

const children = [...baseChildren, modeElement.name, valueElement.name, forceElement.name];

export const mechanicalTranslationInputElement: ElementSpec = {
  name: 'mechanical_translation_input',
  attributes: [nameAttribute],
  children,
};

export const mechanicalTranslationOutputElement: ElementSpec = {
  name: 'mechanical_translation_output',
  attributes: [nameAttribute],
  children,
};

export default [mechanicalTranslationInputElement, mechanicalTranslationOutputElement];
