import { MenuProps } from 'antd';
import { useTreeMenu } from 'providers/XMLDocContext/useTreeMenu';
import { createContext, ReactNode, useMemo } from 'react';
import { ComplexTypeContent, XsdTypes } from 'services';
import useXSD from './useXSD';

interface IXSDDocContext {
  xsdDoc: Document;
  types: XsdTypes;
  getTreeMenu: (
    typeName: string,
    filter: string[],
    existingNodes: string[],
    updateDataNode: (value: ComplexTypeContent, choices?: string[]) => void,
  ) => MenuProps;
  getContentNode: (parentType: string, childType: string) => ComplexTypeContent | undefined;
}

export const XSDDocContext = createContext({} as IXSDDocContext);

export function XSDDocContextProvider(props: { children: ReactNode }) {
  const { children } = props;
  const { xsdDoc, types, getContentNode } = useXSD();
  const { getTreeMenu } = useTreeMenu(types);

  const providerValue = useMemo(() => {
    return {
      xsdDoc,
      types,
      getTreeMenu,
      getContentNode,
    };
  }, [getContentNode, getTreeMenu, types, xsdDoc]);

  return <XSDDocContext.Provider value={providerValue}>{children}</XSDDocContext.Provider>;
}
