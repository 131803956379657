export const XMLNodes = {
  ports: [
    'electric_input',
    'electric_output',
    'electric_3phase_input',
    'electric_3phase_output',
    'hydraulic_input',
    'hydraulic_output',
    'mechanical_rotation_input',
    'mechanical_rotation_output',
    'mechanical_transition_input',
    'mechanical_transition_output',
    'pneumatic_input',
    'pneumatic_output',
    'serial_input',
    'serial_output',
  ],
};
