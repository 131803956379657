import { Provider } from '@rollbar/react';
import { getGPUTier } from 'detect-gpu';
import { useEffectOnce } from 'lib/usehooks-ts';
import { ReactNode } from 'react';
import Rollbar from 'rollbar';
import { ErrorReporting } from 'simumatik-commons';
import { webGLReport } from 'utils/webglReport';

const rollbarConfig: Rollbar.Configuration = {
  accessToken: import.meta.env.VITE_ROLLBAR_ACCESS_TOKEN,
  autoInstrument: {
    contentSecurityPolicy: true,
    errorOnContentSecurityPolicy: true,
  },
  captureUncaught: true,
  captureUnhandledRejections: true,
  captureIp: 'anonymize',
  environment: import.meta.env.VITE_ROLLBAR_ENVIRONMENT,
  payload: {
    client: {
      javascript: {
        code_version: import.meta.env.VITE_VERSION,
        source_map_enabled: true,
      },
      webgl: webGLReport ?? 'WebGL2 not supported',
    },
  },
  maxItems: 5,
};

export const rollbar = new Rollbar(rollbarConfig);

ErrorReporting.rollbar = rollbar;

function RollbarProvider(props: { children: ReactNode }) {
  const { children } = props;

  useEffectOnce(() => {
    getGPUTier().then((gpu) => {
      rollbar.configure({ payload: { client: { gpu } } });
    });
  });

  return <Provider instance={rollbar}>{children}</Provider>;
}

export default RollbarProvider;
