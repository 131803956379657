import React, { ReactNode, useMemo, useState } from 'react';
import { ILibrary } from 'types/api-interfaces';

type Mode = 'browse' | 'add';

interface IComponentLibraryBrowserContext {
  mode: Mode;
  setMode: (value: Mode) => void;
  selectedLibrary: ILibrary | undefined;
  setSelectedLibrary: (value: ILibrary | undefined) => void;
}

interface IComponentLibraryBrowserProviderProps {
  children: ReactNode;
}

export const ComponentLibraryBrowserContext = React.createContext<IComponentLibraryBrowserContext>(
  {} as IComponentLibraryBrowserContext,
);

export function ComponentLibraryBrowserProvider({
  children,
}: IComponentLibraryBrowserProviderProps) {
  const [mode, setMode] = useState<Mode>('browse');
  const [selectedLibrary, setSelectedLibrary] = useState<ILibrary | undefined>(undefined);

  const providerValue = useMemo(
    () => ({
      mode,
      setMode,
      selectedLibrary,
      setSelectedLibrary,
    }),
    [mode, selectedLibrary],
  );

  return (
    <ComponentLibraryBrowserContext.Provider value={providerValue}>
      {children}
    </ComponentLibraryBrowserContext.Provider>
  );
}
