import { useCallback, useState } from 'react';
import {
  GATEWAY_PORT,
  GATEWAY_URL,
  LAUNCHER_PORT,
  LAUNCHER_URL,
  LOCAL_SERVER_PORT,
  LOCAL_SERVER_URL,
  toWsURL,
} from '../services';

export function useSettings(): IUseSettings {
  const [gatewayUrl, setGatewayUrl] = useState(toWsURL(GATEWAY_URL, GATEWAY_PORT)); // am am not sure if we can separate this yet
  const [localServerURL] = useState(LOCAL_SERVER_URL);
  const [localServerPort] = useState(LOCAL_SERVER_PORT);
  const [launcherURL] = useState(LAUNCHER_URL);
  const [launcherPort] = useState(LAUNCHER_PORT);
  const [debugInterval, setDebugInterval] = useState<number>(1);
  const [serverIP, setServerIP] = useState(GATEWAY_URL);

  const changeGatewayURL = useCallback((url: string) => {
    setGatewayUrl(url);
  }, []);

  const changeServerIP = useCallback((ip: string) => {
    setServerIP(ip);
  }, []);

  return {
    gatewayUrl,
    changeGatewayURL,
    serverIP,
    changeServerIP,
    localServerURL,
    localServerPort,
    launcherURL,
    launcherPort,
    debugInterval,
    setDebugInterval,
  };
}

export interface IUseSettings {
  gatewayUrl: string;
  changeGatewayURL: (url: string) => void;
  serverIP: string;
  changeServerIP: (url: string) => void;
  localServerURL: string;
  localServerPort: string;
  launcherURL: string;
  launcherPort: string;
  debugInterval: number;
  setDebugInterval: (value: number) => void;
}
