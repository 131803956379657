import { ElementSpec } from '@codemirror/lang-xml';
import { publicAttribute } from '../../attributes';

const springDampingElement: ElementSpec = {
  name: 'spring_damping',
  attributes: [publicAttribute],
  children: [],
};

const springReferenceElement: ElementSpec = {
  name: 'spring_reference',
  attributes: [publicAttribute],
  children: [],
};

const springStiffnessElement: ElementSpec = {
  name: 'spring_stiffness',
  attributes: [publicAttribute],
  children: [],
};

export const dynamicsElement: ElementSpec = {
  name: 'dynamics',
  children: [springDampingElement.name, springReferenceElement.name, springStiffnessElement.name],
};

export default [
  dynamicsElement,
  springDampingElement,
  springReferenceElement,
  springStiffnessElement,
];
