import { Table, Tag, Typography } from 'antd';
import { ISubscription, ISubscriptionKey } from 'services/subscription';

const { Text } = Typography;

export default function SubscriptionsTable(props: {
  subscription: ISubscription | undefined;
  loading?: boolean;
}) {
  const { subscription, loading } = props;

  const getStatus = (text: string, record: ISubscriptionKey) => {
    if (record.has_expired) return <Tag color="red">Expired</Tag>;
    if (new Date(record.activation_date) > new Date()) return <Tag color="blue">Planned</Tag>;
    if (record.id === subscription?.current_subscription_key_id) {
      return subscription.status === 'paused' ? (
        <Tag color="gold">Paused</Tag>
      ) : (
        <Tag color="green">Active</Tag>
      );
    }
    return null;
  };

  const columns = [
    {
      title: 'Key',
      dataIndex: 'id',
      key: 'key',
      render: (text: string) => <Text copyable>{text}</Text>,
    },
    {
      title: 'Type',
      dataIndex: 'organization_type',
      key: 'type',
      render: (text: string) => <Text style={{ textTransform: 'capitalize' }}>{text}</Text>,
    },
    {
      title: 'Start Date',
      dataIndex: 'activation_date',
      key: 'start',
    },
    {
      title: 'Expiration Date',
      dataIndex: 'expiration_date',
      key: 'end',
    },
    {
      title: 'Status',
      dataIndex: 'has_expired',
      key: 'status',
      render: getStatus,
    },
  ];

  return (
    <Table
      dataSource={subscription?.keys}
      columns={columns}
      style={{ minWidth: '100%' }}
      pagination={false}
      loading={loading}
    />
  );
}

SubscriptionsTable.defaultProps = {
  loading: false,
};
