/* eslint-disable no-underscore-dangle */
import { DataNode } from 'antd/lib/tree';
import { SmtkElement } from '../../../services';

type VariableType = 'input' | 'output' | 'constant' | 'parameter' | '';

export class SmtkDataNode implements DataNode {
  public readonly element: SmtkElement;

  public readonly children: SmtkDataNode[];

  public key: number;

  public parentNode: SmtkDataNode | undefined;

  /** Preserve a copy of on the this instance incase it is casted to DataNode by antd */
  public readonly self: SmtkDataNode;

  private _parentElement: SmtkElement | null;

  private _nextChildKey: number;

  constructor(element: SmtkElement, parent: SmtkElement | null, key: number) {
    this.element = element;
    this._parentElement = parent;
    this.children = [];
    this._nextChildKey = 10;
    this.key = key;
    this.self = this;
  }

  /** Get the name of the element node */
  get name(): string {
    return this.element.getAttribute('name') || '';
  }

  set name(name: string) {
    this.element.setAttribute('name', name);
  }

  /** Get the simumatik type */
  get typeName(): string {
    return this.element.getAttribute('data_type') || this.element.localName;
  }

  /** Get the xsd element's localName */
  get localName(): string {
    return this.element.localName;
  }

  /** Get the parent SmtkElement type */
  get parentElement(): SmtkElement | null {
    return this._parentElement;
  }

  set parentElement(element: SmtkElement | null) {
    this._parentElement = element;
  }

  get variableType(): VariableType {
    return this.element.localName === 'variable'
      ? (this.element.getAttribute('type') as VariableType)
      : '';
  }

  get nextChildKey(): number {
    return this._nextChildKey;
  }

  addChild(dataNode: SmtkDataNode, nextSiblingsIndex?: number) {
    if (nextSiblingsIndex !== -1 && nextSiblingsIndex !== undefined) {
      this.children.splice(nextSiblingsIndex, 0, dataNode);
    } else {
      this.children.push(dataNode);
    }
    dataNode.parentNode = this;
    this._nextChildKey++;
  }

  addChildren(dataNode: SmtkDataNode, nextSiblingsIndex?: number) {
    if (nextSiblingsIndex !== -1 && nextSiblingsIndex !== undefined) {
      this.children.splice(nextSiblingsIndex, 0, ...dataNode.children);
    } else {
      this.children.push(...dataNode.children);
    }
    dataNode.parentNode = this;
    this._nextChildKey++;
  }

  replaceChild(dataNode: SmtkDataNode, nextSiblingsIndex: number) {
    this.children.splice(nextSiblingsIndex, 1, dataNode);
    dataNode.parentNode = this;
    this._nextChildKey++;
  }
}
