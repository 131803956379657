import { apiClient } from 'lib/axios';

const params = new URLSearchParams();
// Load beta XSD files if we are on beta
if (window.location.hostname === 'beta.simumatik.com') params.append('version', 'beta');

export async function fetchComponentXSD(signal?: AbortSignal): Promise<Document> {
  const { data } = await apiClient.get<string>(`datamodel/component`, {
    responseType: 'text',
    signal,
    params,
  });

  const parser = new DOMParser();
  const xsd = parser.parseFromString(data, 'text/xml');

  return xsd;
}

export async function fetchSystemXSD(signal?: AbortSignal): Promise<Document> {
  const { data } = await apiClient.get<string>(`datamodel/system`, {
    responseType: 'text',
    signal,
    params,
  });

  const parser = new DOMParser();
  const xsd = parser.parseFromString(data, 'text/xml');

  return xsd;
}

export async function validateComponentXML(payload: FormData) {
  const { data } = await apiClient.post(`datamodel/component/validation`, payload, { params });
  return data;
}

export async function validateSystemXML(payload: FormData) {
  const { data } = await apiClient.post(`datamodel/system/validation`, payload, {
    params,
  });
  return data;
}
