import { useRollbar } from '@rollbar/react';
import { useSettings } from 'hooks/useSettings';
import useWebSocketServerStatus from 'hooks/useWebSocketServerStatus';
import { WebSocketCloseCode, WebSocketReadyState } from 'oep-web-client';
import { useCallback, useEffect, useState } from 'react';
import { toWsURL } from 'services';
import { LauncherElement, ServiceLauncherClient } from 'services/service-launcher-client';
import { ServiceStatus } from '../utils';

type LauncherStatus = {
  version: string;
  gateway: ServiceStatus;
  latest_version: string;
  local_workspace: ServiceStatus;
  gateway_version: string;
  local_workspace_version: string;
};

/**
 *
 * @returns
 */
export function useLocalServicesLauncher() {
  const rollbar = useRollbar();
  const [client] = useState<ServiceLauncherClient>(
    new ServiceLauncherClient(
      () => {},
      (readyState, code) => {
        if (readyState !== WebSocketReadyState.Closed) return;
        if (code === WebSocketCloseCode.Abnormal)
          rollbar.error('Service Launcher WebSocket client closed abnormally');
        if (code === WebSocketCloseCode.BadGateway)
          rollbar.error('Service Launcher WebSocket client bad gateway');
        if (code === WebSocketCloseCode.InternalError)
          rollbar.error('Service Launcher WebSocket client closed because of an internal error');
      },
    ),
  );
  const [gatewayStatus, setGatewayStatus] = useState<ServiceStatus>(ServiceStatus.STOPPED);
  const [localServerStatus, setLocalServerStatus] = useState<ServiceStatus>(ServiceStatus.STOPPED);
  const [launcherVersion, setLauncherVersion] = useState<string>();
  const [latestVersion, setLatestVersion] = useState<string>();
  const [isLauncherAvailable, setIsLauncherAvailable] = useState<boolean>(false);
  const { launcherURL, launcherPort } = useSettings();
  const { status } = useWebSocketServerStatus({
    client,
    url: toWsURL(launcherURL, launcherPort),
  });

  const updateLauncher = async () => {
    if (isLauncherAvailable) {
      return client.updateLauncher();
    }
  };

  const startWorkspace = async () => {
    if (isLauncherAvailable) {
      return client.start(LauncherElement.WORKSPACE);
    }
  };

  const startGateway = async () => {
    if (isLauncherAvailable) {
      return client.start(LauncherElement.GATEWAY);
    }
  };

  const stopWorkspace = async () => {
    if (isLauncherAvailable) {
      return client.stop(LauncherElement.WORKSPACE);
    }
  };

  const stopGateway = async () => {
    if (isLauncherAvailable) {
      return client.stop(LauncherElement.GATEWAY);
    }
  };
  // #TODO add call backs
  const restartWorkspace = async () => {
    if (isLauncherAvailable) {
      return client.restart(LauncherElement.WORKSPACE);
    }
  };

  const restartGateway = useCallback(async () => {
    if (isLauncherAvailable) {
      return client.restart(LauncherElement.GATEWAY);
    }
  }, [isLauncherAvailable, client]);

  useEffect(() => {
    return () => {
      client?.close();
    };
  }, [client]);

  useEffect(() => {
    (async () => {
      if (status === ServiceStatus.RUNNING) {
        const opened = await client.open(toWsURL(launcherURL, launcherPort));
        if (opened) {
          const response: LauncherStatus = await client.getStatus();
          if (response) {
            setGatewayStatus(response.gateway);
            setLocalServerStatus(response.local_workspace);
            setLauncherVersion(response.version);
            setLatestVersion(response.latest_version);
          }
          setIsLauncherAvailable(true);
        }
      } else {
        setIsLauncherAvailable(false);
      }
    })();
  }, [status, client, launcherPort, launcherURL]);

  /** */
  const updateAvailable = useCallback((): string | undefined => {
    return launcherVersion !== latestVersion ? latestVersion : undefined;
  }, [launcherVersion, latestVersion]);

  return {
    updateLauncher,
    startWorkspace,
    startGateway,
    stopWorkspace,
    stopGateway,
    restartWorkspace,
    restartGateway,
    updateAvailable: updateAvailable(),
    isLauncherAvailable,
    gatewayStatus,
    localServerStatus,
    launcherVersion,
    latestVersion,
  };
}
