import { postComponentXML } from 'services/components';
import formatXML from 'utils/xml-formatter';
import serializeXML from '../../utils/serializeXML';

export async function uploadXMLFile(xmlDoc: Document, guid: string) {
  const formData = new FormData();
  formData.append(
    'file',
    new Blob([formatXML(serializeXML(xmlDoc))], {
      type: 'application/xml',
    }),
    'file',
  );
  await postComponentXML(guid, formData);
}
