interface IAuthConfig {
  clientId: string;
  domain: string;
  audience: string;
}

export const authConfig: IAuthConfig = {
  clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
  domain: import.meta.env.VITE_AUTH0_DOMAIN,
  audience: import.meta.env.VITE_AUTH0_AUDIENCE,
};
