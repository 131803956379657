import electricPortElements from './electric';
import hydraulicPortElements from './hydraulic';
import mechanicalRotationPortElements from './mechanical_rotation';
import mechanicalTranslationPortElements from './mechanical_translation';
import pneumaticPortElements from './pneumatic';
import serialPortElements from './serial';

export * from './electric';
export * from './hydraulic';
export * from './mechanical_rotation';
export * from './mechanical_translation';
export * from './pneumatic';
export * from './serial';

export default [
  ...electricPortElements,
  ...hydraulicPortElements,
  ...mechanicalRotationPortElements,
  ...mechanicalTranslationPortElements,
  ...pneumaticPortElements,
  ...serialPortElements,
];
