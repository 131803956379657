import { Button, Tooltip } from 'antd';
import { useContext, useState } from 'react';
import { AdminConsoleContext } from '../AdminConsoleProvider';
import RenewSubscription from './RenewSubscription';

export default function RenewButton() {
  const { organization } = useContext(AdminConsoleContext);
  const [visible, setVisible] = useState(false);

  const handleClick = () => {
    setVisible(true);
  };

  return (
    <>
      <Tooltip title="Activate a new subscription key" placement="topLeft">
        <Button type="primary" onClick={handleClick}>
          Renew
        </Button>
      </Tooltip>
      <RenewSubscription
        organization={organization}
        open={visible}
        onClose={() => setVisible(false)}
      />
    </>
  );
}
