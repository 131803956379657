import { ElementSpec } from '@codemirror/lang-xml';
import { axisElement, enableElement, updateRateElement, valueElement } from '../shared';

const typeElement: ElementSpec = {
  name: 'type',
  textContent: ['position', 'velocity'],
  children: [],
};

export const jointSensorElement: ElementSpec = {
  name: 'joint_sensor',
  children: [
    typeElement.name,
    axisElement.name,
    updateRateElement.name,
    enableElement.name,
    valueElement.name,
  ],
};

export default [jointSensorElement, typeElement];
