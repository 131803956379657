import { ElementSpec } from '@codemirror/lang-xml';
import { publicAttribute } from '../../../attributes';
import {
  sizeElement,
  scaleElement,
  radiusElement,
  lengthElement,
  valueElement,
  heightElement,
} from '../../shared/generic';

const boxElement: ElementSpec = {
  name: 'box',
  children: [sizeElement.name, scaleElement.name],
};

const box2dElement: ElementSpec = {
  name: 'box2d',
  children: [sizeElement.name, scaleElement.name],
};

const cylinderElement: ElementSpec = {
  name: 'cylinder',
  children: [radiusElement.name, lengthElement.name, scaleElement.name],
};

const sphereElement: ElementSpec = {
  name: 'sphere',
  children: [radiusElement.name, scaleElement.name],
};

const capsuleElement: ElementSpec = {
  name: 'capsule',
  children: [radiusElement.name, lengthElement.name, scaleElement.name],
};

const modelElement: ElementSpec = {
  name: 'model',
  children: [],
};

const asConvexHullElement: ElementSpec = {
  name: 'as_convex_hull',
  children: [],
};

const asTriangleMeshElement: ElementSpec = {
  name: 'as_triangle_mesh',
  children: [],
};

const simplifyElement: ElementSpec = {
  name: 'simplify',
  children: [],
};

const meshElement: ElementSpec = {
  name: 'mesh',
  children: [
    modelElement.name,
    scaleElement.name,
    asConvexHullElement.name,
    asTriangleMeshElement.name,
    simplifyElement.name,
  ],
};

const fontElement: ElementSpec = {
  name: 'font',
  children: [],
};

const thicknessElement: ElementSpec = {
  name: 'thickness',
  attributes: [publicAttribute],
  children: [],
};

const boldElement: ElementSpec = {
  name: 'bold',
  attributes: [publicAttribute],
  children: [],
};

const textElement: ElementSpec = {
  name: 'text',
  children: [
    valueElement.name,
    fontElement.name,
    heightElement.name,
    thicknessElement.name,
    boldElement.name,
  ],
};

export const geometryElement: ElementSpec = {
  name: 'geometry',
  children: [
    meshElement.name,
    boxElement.name,
    box2dElement.name,
    cylinderElement.name,
    sphereElement.name,
    capsuleElement.name,
    textElement.name,
  ],
};

export default [
  meshElement,
  boxElement,
  box2dElement,
  cylinderElement,
  sphereElement,
  capsuleElement,
  textElement,
  fontElement,
  thicknessElement,
  boldElement,
  modelElement,
  asConvexHullElement,
  asTriangleMeshElement,
  simplifyElement,
];
