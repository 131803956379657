import { SmtkDataNode } from 'features/ComponentPage/ComponentEditor/SmtkDataNode';
import { createContext, ReactNode, useMemo } from 'react';
import { IComponent } from 'types/api-interfaces';
import { useXmlDoc } from './useXmlDoc';

interface IXMLDocContext {
  xmlDoc: Document;
  dataNodes: SmtkDataNode[];
  updateDataNodes: () => void;
  updateXMLDoc: (xmlDoc: Document) => void;
  updateEditorScene: () => void;
}

export const XMLDocContext = createContext<IXMLDocContext>({
  xmlDoc: new Document(),
  dataNodes: [],
  updateDataNodes: () => {},
  updateXMLDoc: () => {},
  updateEditorScene: () => {},
});

export function XMLDocProvider(props: { children: ReactNode; component: IComponent }) {
  const { children, component } = props;
  const { xmlDoc, dataNodes, updateDataNodes, updateXMLDoc, updateEditorScene } = useXmlDoc({
    component,
  });

  const providerValue = useMemo(
    () => ({
      xmlDoc,
      dataNodes,
      updateDataNodes,
      updateXMLDoc,
      updateEditorScene,
    }),
    [dataNodes, updateDataNodes, updateEditorScene, updateXMLDoc, xmlDoc],
  );

  return <XMLDocContext.Provider value={providerValue}>{children}</XMLDocContext.Provider>;
}
