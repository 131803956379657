import { useAuth0 } from '@auth0/auth0-react';
import { EventOptions } from 'plausible-tracker';
import usePlausible from './usePlausible';

const EVENT_NAMES = [
  'Feature: Convert',
  'Feature: Optimize',
  'Feature: Model Viewer',
  'Feature: Export Scene',
  'Download Launcher',
  'Started Online Workspace',
  'Feature: Drawings',
  'Emulation Started',
  'Workspace: Load System',
  'Workspace: Connect Gateway',
  'Workspace: Export system',
  'Workspace: Import system',
  'Workspace: Reload System',
  'Workspace: Duplicate Component',
  'Workspace: Debug Physics',
  'Workspace: Save System',
  'Workspace: Save System As',
  'Help: Opened Menu',
  'Help: Visited Manual',
  'Help: Visited Academy',
  'Help: Visited Forum',
  'Help: Visited Support Forum',
  'Component: Published',
  'Component: Cloned',
  'Component: Saved',
  'Component: Deleted',
  'Component: Uploaded Asset',
  'Component: Validate XML',
  'System: Published',
  'System: Cloned',
  'System: Saved',
  'System: Deleted',
  'System: Uploaded Asset',
  'System: Created Thumbnail',
  'Admin: Added Group',
  'Admin: Invited Members',
  'Admin: Removed Member',
  'Admin: Blocked Member',
  'Admin: Changed Role',
  'Joined Collaborative Workspace',
  'Clicked Upgrade Button',
  'Feature: VR',
  'Dev Tools',
  'Opened App Settings',
  'Clicked Upgrade Button',
] as const;

type EventName = (typeof EVENT_NAMES)[number];

export default function useAnalytics() {
  const plausible = usePlausible();
  const { user } = useAuth0();
  const isSimumatikEmployee = user?.email?.includes('@simumatik.com');

  const trackEvent = (eventName: EventName, options?: EventOptions) => {
    if (!import.meta.env.VITE_ENABLE_ANALYTICS) return;
    // Don't track events for employees, that's not interesting and muddies the results.
    if (isSimumatikEmployee && eventName !== 'Dev Tools') return;
    plausible.trackEvent(eventName, options);
  };

  return { trackEvent };
}
