import { toTreeNode } from 'features/ComponentPage/ComponentEditor/utils/toTreeNode';
import { useUser } from 'hooks/useUser';
import { AppContext } from 'providers/application-context';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { fetchComponentXML } from 'services/components';
import { getComponentElement } from 'services/dom-parser';
import { UIEventBus } from 'simumatik-commons';
import { IComponent } from 'types/api-interfaces';
import { canModify } from 'utils/authorizations';
import { SmtkDataNode } from '../../features/ComponentPage/ComponentEditor/SmtkDataNode';
import {
  addTo3DScene,
  dataNodeTo3d,
} from '../../features/ComponentPage/ComponentEditor/utils/three-d';
import { XSDDocContext } from '../XSDContext';
import { uploadXMLFile } from './script';

interface IUseXmlDocProps {
  component: IComponent;
}

interface IUseXmlDoc {
  dataNodes: SmtkDataNode[];
  loading: boolean;
  xmlDoc: Document;
  updateXMLDoc: (xmlDoc: Document) => void;
  loadXML: (id: string) => void;
  updateDataNodes: () => void;
  updateEditorScene: () => void;
}

// TODO: This needs to be refactored. It's too reliant on weird useEffect effects
// Seriously wtf is going on with this hook. There is so much weird shit happening here...
export const useXmlDoc = (props: IUseXmlDocProps): IUseXmlDoc => {
  const { xsdDoc } = useContext(XSDDocContext);
  const [xmlDoc, setXmlDoc] = useState<Document>(new Document());
  const [saveOnExit, setSaveOnExit] = useState(false);
  const [loading] = useState(false);
  const [dataNodes, setDataNodes] = useState<SmtkDataNode[]>([]);
  const { updateUI } = useContext(AppContext);
  
  // eslint-disable-next-line
  const update = useMemo(() => updateUI, []); // prevent unnecessary reference to updateUI

  const { me } = useUser();
  // eslint-disable-next-line
  const user = useMemo(() => me, []);
  const loadXML = useCallback(async (id: string) => {
    const controller = new AbortController();
    if (id) {
      const { signal } = controller;
      const xmlDoc = new DOMParser().parseFromString(
        await fetchComponentXML(id, signal),
        'text/xml',
      );
      setXmlDoc(xmlDoc);
    } else setXmlDoc(new Document());
    return () => {
      controller.abort();
    };
  }, []);

  // This is bad. Disabling eslint rule until proper fix
  const scheduleXmlSave = useCallback(() => {
    setSaveOnExit(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update]);

  const updateDataNodes = useCallback(() => {
    setDataNodes((dataNodes: SmtkDataNode[]) => {
      return [...dataNodes];
    });
    scheduleXmlSave();
  }, [scheduleXmlSave]);

  const updateXMLDoc = useCallback(
    (xmlDoc: Document) => {
      setXmlDoc(xmlDoc);
      scheduleXmlSave();
    },
    [scheduleXmlSave],
  );

  // This causes the whole scene subtree under the TDScene.workspace node to be replaced
  // which causes weird effects, like previously enabled collision objects being hidden etc.
  const updateEditorScene = useCallback(async () => {
    if (dataNodes.length) {
      addTo3DScene(await dataNodeTo3d(dataNodes[0], xsdDoc));
    }
    
    UIEventBus.emit("editor-scene-updated");
  }, [dataNodes, xsdDoc]);

  const xmlDocToTreeNodes = useCallback((document: Document, key: number) => {
    return toTreeNode(getComponentElement(document)!, key, null);
  }, []);

  useEffect(() => {
    if (props.component.id) {
      loadXML(props.component.id);
    }
  }, [props.component.id, loadXML]);

  useEffect(() => {
    if (xmlDoc.hasChildNodes()) {
      setDataNodes([xmlDocToTreeNodes(xmlDoc, 1)]);
    }
  }, [xmlDoc, xmlDocToTreeNodes, updateDataNodes]);

  useEffect(() => {
    updateEditorScene();
  }, [dataNodes, updateEditorScene]);

  // save xml effect
  useEffect(() => {
    const upload = () => {
      if (saveOnExit && canModify(user, props.component)) {
        uploadXMLFile(xmlDoc, props.component.id);
      }
    };
    UIEventBus.on('save-xml', upload);
    return () => {
      UIEventBus.off('save-xml', upload);
    };
  }, [xmlDoc, saveOnExit, props.component.id, user, props.component]);

  return {
    dataNodes,
    loading,
    xmlDoc,
    updateXMLDoc,
    loadXML,
    updateDataNodes,
    updateEditorScene,
  };
};

export default useXmlDoc;
