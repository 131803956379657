import { useRollbar } from '@rollbar/react';
import { message } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { createAsset } from 'services/assets';
import useComponentEditor from './useComponentEditor';

const INITIAL_SCRIPT = new File(
  [
    'if initialize:\n  # Enter your initialization code here\n  # It will be executed after resetting the emulation\n  pass\n\nelse:\n  # Enter your behavior code here\n  # It will be executed depending on the step_time and input variable changes\n  pass',
  ],
  'script.py',
  { type: 'text/x-python' },
);

const useCreateBehaviorScript = () => {
  const { component } = useComponentEditor();
  const rollbar = useRollbar();
  const createScript = useMutation((formData: FormData) => createAsset(component, formData));

  const createNewScriptFile = async () => {
    const formData = new FormData();
    formData.append('file', INITIAL_SCRIPT, 'script.py');
    formData.append('name', 'script.py');

    try {
      const asset = await createScript.mutateAsync(formData);
      return asset.id;
    } catch (error) {
      message.error(error.message);
      rollbar.error('Error creating behavior script', error);
      return '';
    }
  };

  return { createNewScriptFile, isLoading: createScript.isLoading };
};

export default useCreateBehaviorScript;
