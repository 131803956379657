import { useFeature } from '@growthbook/growthbook-react';
import { FeatureFlags } from 'feature-flags';
import { DevToolsButton } from './DevToolsButton';

export function SimumatikDevTools() {
  const { value } = useFeature<boolean>(FeatureFlags.DevTools);

  if (!value) return null;

  return <DevToolsButton />;
}
