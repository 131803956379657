import { AttrSpec } from '@codemirror/lang-xml';

export const publicAttribute: AttrSpec = {
  name: 'public',
  values: ['true', 'false'],
};

export const nameAttribute: AttrSpec = {
  name: 'name',
};
