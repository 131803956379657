import { IAttribInfo, IXMLNodeInfo } from 'features/ComponentPage/ComponentEditor/interfaces';
import { rollbar } from 'lib/rollbar';
import { SmtkElement } from 'services';
import {
  smtk_bool,
  smtk_choice,
  smtk_float,
  smtk_int,
  smtk_link,
  smtk_multi_choice,
  smtk_name,
  smtk_range,
  smtk_rgba,
  smtk_string,
  smtk_transform,
  smtk_vector,
  smtk_vector2,
  smtk_vector3,
  smtk_vector6,
  systemTypes,
} from 'simumatik-commons';

interface TypeRestrictions {
  pattern: RegExp | null;
  min: number | null;
  max: number | null;
  length: number | null;
  enumeration: string[];
}

const getTypeRestrictions = (typeName: string, xsdRoot: Element | Document): TypeRestrictions => {
  const extension = xsdRoot.querySelector(`complexType[name=${typeName}] extension`);
  const list = xsdRoot.querySelector(`simpleType[name=${typeName}] list`);

  if (extension) {
    return getTypeRestrictions(String(extension.getAttribute('base')), xsdRoot);
  }
  if (list && !list.querySelector('simpleType')) {
    // if( list)

    return getTypeRestrictions(String(list.getAttribute('itemType')), xsdRoot);
  }
  // a simple type

  const pattern = xsdRoot.querySelector(`simpleType[name=${typeName}] restriction > pattern`);
  const min = xsdRoot.querySelector(`simpleType[name=${typeName}] restriction > minInclusive`);
  const max = xsdRoot.querySelector(`simpleType[name=${typeName}] restriction > maxInclusive`);
  const length = xsdRoot.querySelector(`simpleType[name=${typeName}] restriction > length`);
  const enumeration = xsdRoot.querySelectorAll(
    `simpleType[name=${typeName}] restriction  enumeration`,
  );

  function getEnumeration() {
    const values = new Array<string>();

    enumeration.forEach((element) => {
      const value = element.getAttribute('value');
      if (value) values.push(value);
    });

    return values;
  }

  return {
    pattern:
      pattern && pattern.getAttribute('value')
        ? new RegExp(String(pattern.getAttribute('value')))
        : null,
    min: min && min.getAttribute('value') ? Number(min.getAttribute('value')) : null,
    max: max && max.getAttribute('value') ? Number(max.getAttribute('value')) : null,
    length: length && length.getAttribute('value') ? Number(length.getAttribute('value')) : null,
    enumeration: getEnumeration(),
  };
};

export const getSMTKTypeOptions = (nodeType: string, dataType: string, xsdRoot: Document) => {
  // escape system types: they don't exist in component xsd: Temp fix
  if (
    !xsdRoot ||
    [
      'emulation_step',
      'global_erp',
      'global_cfm',
      'constraint_iterations',
      'gateway_interval',
      'time_relation',
    ].includes(nodeType)
  ) {
    return undefined;
  }

  const appInfo = xsdRoot.querySelector(
    `element[name=${nodeType}][type=${dataType}] annotation>appinfo`,
  );

  let labels = [];
  try {
    if (appInfo && appInfo.textContent) {
      const labelsString = JSON.parse(appInfo.textContent).labels;
      if (labelsString) labels = labelsString.split(' ');
    }
  } catch (error) {
    rollbar.error(error.message, error, nodeType, dataType);
  }
  return { ...getTypeRestrictions(dataType, xsdRoot), labels };
};

export const nodeInfo = (
  xmlElement: SmtkElement | undefined,
  xsdDoc: Document | undefined,
): IXMLNodeInfo => {
  const nodeInfo = {} as IXMLNodeInfo;
  nodeInfo.attributes = [];

  if (!xmlElement) return nodeInfo;
  if (!xsdDoc) return nodeInfo;

  [...xmlElement.attributes].forEach((attribute) => {
    const attribInfo: IAttribInfo = {
      attribute,
      smtkObject: xsdToSMTK(
        attribute.localName === 'public' ? 'smtk_bool' : 'smtk_name',
        attribute.value,
      ),
      enumeration: undefined,
    };

    // get choices
    if (attribute.ownerElement && attribute.ownerElement.localName === 'variable') {
      const e = new Map<string, string>();
      [
        ...xsdDoc.querySelectorAll(
          `complexType[name="smtk_var"] attribute[name=${attribute.localName}] enumeration`,
        ),
      ].forEach((element) =>
        e.set(`${element.getAttribute('value')}`, `${element.getAttribute('value')}`),
      );

      if (e.size !== 0) attribInfo.enumeration = e;
    }

    nodeInfo.attributes.push(attribInfo);
  });

  nodeInfo.name = xmlElement.tagName;
  nodeInfo.xmlElement = xmlElement;

  const xsdRoot = xsdDoc.firstElementChild;
  if (!xsdRoot) throw new Error('Schema file not initialized!');

  const complexType = xsdRoot.querySelector(`complexType[name=${xmlElement.tagName}]`);
  if (complexType && xmlElement.children.length) {
    // && not a variable (specially added for limits duplicate types)
    // isComplextype

    const description = complexType.querySelector('annotation>documentation');

    nodeInfo.description = description ? description.textContent : null;
    nodeInfo.smtkObject = null;
  } else {
    // isSimpleType
    try {
      const parentName = xmlElement.parentElement ? xmlElement.parentElement.localName : '';
      if (!parentName) return nodeInfo;

      const element = xsdRoot.querySelector(
        `complexType[name=${parentName}] element[name=${xmlElement.localName}]`,
      );
      if (!element) return nodeInfo;

      const description = element.querySelector('annotation>documentation');
      nodeInfo.description = description ? description.textContent : null;

      const appInfo = element.querySelector('annotation>appinfo');
      if (appInfo && appInfo.textContent) {
        try {
          const info = JSON.parse(appInfo.textContent);

          nodeInfo.default = info.default;
          nodeInfo.var_type = info.var_type;
          if (info.labels) nodeInfo.labels = String(info.labels).split(' ');
        } catch (error) {
          rollbar?.error(error.message, error);
        }
      }

      let typeName = String(element.getAttribute('type') || element.getAttribute('name'));
      if (typeName === 'smtk_var') {
        typeName = `${xmlElement.getAttribute('data_type')}`;
      }

      const value =
        xmlElement.textContent !== undefined ? xmlElement.textContent : nodeInfo.default;
      const options = {
        ...getTypeRestrictions(typeName, xsdRoot),
        labels: nodeInfo.labels,
      };

      const smtkObject = xsdToSMTK(typeName, value, options);
      nodeInfo.smtkObject = smtkObject;
      nodeInfo.typeName = typeName;
    } catch (error) {
      rollbar?.error(`${xmlElement.tagName}: ${error.message}`, error);
    }
  }

  return nodeInfo;
};

export const xsdToSMTK = (
  typeName: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  options?: TypeRestrictions,
) => {
  switch (typeName) {
    /** strings */
    case 'smtk_guid':
    case 'smtk_string_array':
    case 'smtk_bytes':
    case 'smtk_string':
      return new smtk_string(value, options?.pattern);
    case 'smtk_name':
      return new smtk_name(value);

    /** int */
    case 'smtk_int':
      return new smtk_int(value);

    /** float */
    case 'smtk_float':
      return new smtk_float(value);

    /** boolean */
    case 'smtk_bool':
      return new smtk_bool(value);

    /** color */
    case 'smtk_rgba':
      return new smtk_rgba(value);

    /** smtk_tree_search */
    case 'smtk_link':
      return new smtk_link(value);

    case 'smtk_vector':
      return new smtk_vector(value, options?.length ?? 0);

    /** vectors */
    case 'smtk_vector2':
      return new smtk_vector2(value);

    case 'smtk_vector3':
      return new smtk_vector3(value);

    case 'smtk_vector6':
      return new smtk_vector6(value);

    case 'smtk_transform':
      return new smtk_transform(value);

    /** choices */
    case 'smtk_mechanical_choice':
    case 'smtk_sensor_choice':
    case 'smtk_shape_choice':
    case 'smtk_comm_driver_choice':
    case 'smtk_camera_format_choice':
    case 'smtk_camera_mode_choice':
    case 'smtk_camera_output_choice':
    case 'smtk_sensor_technology_choice':
    case 'smtk_collision_material_choice':
    case 'smtk_font_choice': {
      if (!options?.enumeration) throw new Error('choice type must specify options');
      return new smtk_choice<string>(options.enumeration, value);
    }

    /** multi choices */
    case 'smtk_collision_material_array':
    case 'smtk_axis_array':
    case 'smtk_sensor_technology_array':
    case 'smtk_multi_choice': {
      if (typeof value !== 'string' && !Array.isArray(value)) {
        return null;
      }
      return new smtk_multi_choice(value, options?.enumeration as string[]);
    }

    /** ranges */
    case 'smtk_coefficient': {
      const parsedValue = Number(value);

      if (isNaN(parsedValue)) {
        return null;
      }

      let min = 0;
      let max = 1;

      if (options && options.min && options.max) {
        min = Number(options.min);
        max = Number(options.max);
      }

      return new smtk_range(parsedValue, min, max);
    }

    // look from system types
    default:
      return systemTypes(typeName, value);
  }
};
