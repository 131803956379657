import { apiClient } from 'lib/axios';
import { ALLOW_INDIVIDUAL_ACCOUNT, BRANCH_ID } from 'services';
import { IOrganization, IOrganizationMember } from 'types/api-interfaces';

export interface IOrganizationData {
  created: string;
  id: string;
  is_blocked: boolean;
  logo_url: string;
  members: IOrganizationMember[];
  modified: string;
  name: string;
  organization_type: string | null;
  seats: number;
  site_url: string;
}

export async function fetchOrganizations() {
  const url = `/users/me/organizations${ALLOW_INDIVIDUAL_ACCOUNT ? `` : `?branch_id=${BRANCH_ID}`}`;
  const { data } = await apiClient.get<IOrganization[]>(url);
  return data;
}

export async function fetchOrganization(organizationID: string) {
  const url = `/organizations/${organizationID}`;
  const { data } = await apiClient.get<IOrganizationData>(url);
  return data;
}

export async function updateOrganization(organizationID: string, payload: FormData) {
  const url = `/organizations/${organizationID}`;
  const { data } = await apiClient.post(url, payload);
  return data;
}

export async function activateOrganization(payload: FormData) {
  const url = `/organizations`;
  const { data } = await apiClient.post<IOrganization>(url, payload);
  return data;
}

export async function fetchOrganizationMembers(organizationID: string) {
  const url = `/organizations/${organizationID}/members`;
  const { data } = await apiClient.get<IOrganizationMember[]>(url);
  return data;
}

interface IInvitationsResponseData {
  already_existing_members: string[];
  invitations: {
    failed: string[];
    successful: string[];
  };
}

export async function inviteUsersToOrganization(
  recipients: string[],
  group: string,
  message: string,
  organizationID: string,
): Promise<IInvitationsResponseData> {
  const url = `/organizations/${organizationID}/invitations`;
  const { data } = await apiClient.post(url, { recipients, group, message });
  return data;
}

export async function addUserToOrganization(
  userEmail: string,
  groupID: number,
  organizationID: string,
) {
  const url = `/organizations/${organizationID}/members`;
  const { data } = await apiClient.post<IOrganizationMember>(url, {
    user_email: userEmail,
    group: groupID,
  });
  return data;
}

type Invitation = {
  id: number;
  sender_id: string;
  sender_name: string;
  recipient_email: string;
  group: string;
  created: string;
  modified: string;
  expiration_timestamp: string;
  has_expired: boolean;
  status: 'pending' | 'sent' | 'failed';
};

export async function fetchOrganizationInvitations(id: string) {
  const { data } = await apiClient.get<Invitation[]>(`organizations/${id}/invitations`);
  return data;
}

export async function deleteOrganizationInvitation(id: number, organizationId: string) {
  const { data } = await apiClient.delete(`organizations/${organizationId}/invitations/${id}`);
  return data;
}
