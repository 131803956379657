import { Auth0Provider } from '@auth0/auth0-react';
import { ReactNode } from 'react';
import { useHistory } from 'react-router';

function Auth0ProviderWithHistory(props: { children: ReactNode }) {
  const { children } = props;

  const clientId = `${import.meta.env.VITE_AUTH0_CLIENT_ID}`;
  const domain = `${import.meta.env.VITE_AUTH0_DOMAIN}`;
  const audience = `${import.meta.env.VITE_AUTH0_AUDIENCE}`;

  const history = useHistory();

  const onRedirectCallback: React.ComponentProps<typeof Auth0Provider>['onRedirectCallback'] = (
    appState,
  ) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      clientId={clientId}
      domain={domain}
      authorizationParams={{
        audience,
        redirect_uri: window.location.origin,
      }}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
      useRefreshTokensFallback
    >
      {children}
    </Auth0Provider>
  );
}

export default Auth0ProviderWithHistory;
