import { WebSocketClient, WebSocketReadyState } from 'oep-web-client';
import { useCallback, useEffect, useRef, useState } from 'react';
import { PING_INTERVAL } from 'services';
import { ServiceStatus } from '../providers/LocalServicesProvider';

export default function useWebSocketServerStatus(props: {
  client: WebSocketClient;
  url: string;
  ping?: number;
}) {
  const { client, url, ping } = props;
  const [status, setStatus] = useState<ServiceStatus>(ServiceStatus.STOPPED);
  const [trigger, setTrigger] = useState<boolean>(false);
  const currentUrl = useRef(url);

  client.onDisconnect((readyState: WebSocketReadyState) => {
    switch (readyState) {
      case WebSocketReadyState.Closed: {
        setTrigger((prevTrigger) => !prevTrigger);
        break;
      }

      default: {
        break;
      }
    }
  });

  useEffect(() => {
    let handle: NodeJS.Timeout;
    (async () => {
      if (!client.isOpen || url !== currentUrl.current) {
        try {
          const opened = await client.open(url);
          if (opened) {
            setStatus(ServiceStatus.RUNNING);
          } else {
            // failed to open try again after a while
            setStatus(ServiceStatus.STOPPED);
            handle = setTimeout(async () => {
              setTrigger((prevTrigger) => !prevTrigger);
            }, ping ?? PING_INTERVAL);
          }
        } catch (error) {
          setStatus(ServiceStatus.STOPPED);
        }
        if (url !== currentUrl.current) currentUrl.current = url;
      } else {
        setStatus(ServiceStatus.RUNNING);
      }
    })();
    return () => clearTimeout(handle);
  }, [client, trigger, url, ping]);

  const forceStatusCheck = useCallback(() => {
    setTrigger((prevTrigger) => !prevTrigger);
  }, []);

  return { status, forceStatusCheck };
}
