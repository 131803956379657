type Props = {
  size?: number;
  inverted?: boolean;
};

function AppLogo({ size = 128, inverted = false }: Props) {
  return (
    <img
      src={inverted ? import.meta.env.VITE_LOGO_DARK : import.meta.env.VITE_LOGO_LIGHT}
      alt={import.meta.env.VITE_NAME}
      height={size}
    />
  );
}

export default AppLogo;
