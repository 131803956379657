import { ElementSpec } from '@codemirror/lang-xml';
import { publicAttribute } from '../../attributes';

const samplesElement: ElementSpec = {
  name: 'samples',
  attributes: [publicAttribute],
  children: [],
};

const limitsElement: ElementSpec = {
  name: 'limits',
  attributes: [publicAttribute],
  children: [],
};

export const verticalElement: ElementSpec = {
  name: 'vertical',
  children: [samplesElement.name, limitsElement.name],
};

export const horizontalElement: ElementSpec = {
  name: 'horizontal',
  children: [samplesElement.name, limitsElement.name],
};

export default [verticalElement, horizontalElement, limitsElement, samplesElement];
