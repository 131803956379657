import { apiClient } from 'lib/axios';
import { IObjectTransfer, ISystem } from 'types/api-interfaces';

export async function fetchSystems(
  scope: string = 'public',
  searchTerm?: string,
): Promise<ISystem[]> {
  const formattedScope = scope === 'public' ? '/public' : '';
  const url = `/systems${formattedScope}${
    searchTerm && searchTerm.length > 0 ? `?name=${searchTerm}` : ''
  }`;
  const { data } = await apiClient.get(url);
  return data;
}

export async function deleteSystem(systemID: string) {
  const url = `/systems/${systemID}`;
  await apiClient.delete(url);
}

export async function transferSystem(
  systemID: string,
  recipient: string,
): Promise<IObjectTransfer> {
  const url = `/systems/${systemID}/transfers`;
  const { data } = await apiClient.post(url, {
    recipient_id: recipient,
  });
  return data;
}

export async function fetchSystem(id: string) {
  const url = `/systems/${id}`;
  const { data } = await apiClient.get<ISystem>(url);
  return data;
}

export async function createSystem(name: string) {
  const { data } = await apiClient.post<ISystem>('/systems', { name });
  return data;
}

export async function patchSystem(system: ISystem, payload: Partial<ISystem>) {
  const { data } = await apiClient.patch(`/systems/${system.id}`, payload);
  return data;
}
